import Spinner from "react-bootstrap/Spinner";

const TabLoader = () => {
	return (
		<div className="tab-loader full flex-column">
			<Spinner className="text-blue" />
		</div>
	);
};

export default TabLoader;
