import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Dropdown from "react-bootstrap/Dropdown";

const PageHeader = ({ user }) => {
	const navigate = useNavigate();
	const [query, setQuery] = useState("");
	const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
		<a
			href=""
			ref={ref}
			onClick={(e) => {
				e.preventDefault();
				onClick(e);
			}}
			className="header-user-dropdown text-sm flex-row-left"
		>
			{children}
			<i className="ri-arrow-drop-down-line header-user-icon" />
		</a>
	));

	const search = (e) => {
		e.preventDefault();
		if (query) {
			navigate(`/search?q=${query}`);
		} else {
			navigate("/dashboard");
		}
	};

	return (
		<div className="page-header bg-white flex-row-left full">
			<Container className="gx-5">
				<Row className="flex-row-left">
					<Col>
						<Form
							onSubmit={search}
							className="header-search-container flex-row-left bg-gray rounded"
						>
							<div className="header-search-icon flex-column full-height fit">
								<i className="ri-search-line text-lg opacity-3" />
							</div>
							<Form.Control
								className="search-input full"
								placeholder="Search"
								value={query}
								onChange={(e) => setQuery(e.target.value)}
							/>
						</Form>
					</Col>
					<Col className="flex-row-right">
						<i className="ri-notification-3-line opacity-3 text-xl" />
						<div className="header-separator opacity-1" />
						<div
							className="header-user-img flex-column"
							style={{
								background: user?.photo ? `url(${user.photo})` : "#3c57cc",
							}}
						>
							{!user?.photo && (
								<a
									href="#"
									className="text-medium-inter text-white text-sm upper"
								>
									{user.username[0]}
								</a>
							)}
						</div>
						<Dropdown>
							<Dropdown.Toggle as={CustomToggle}>
								{user?.username}
							</Dropdown.Toggle>
							<Dropdown.Menu>
								<Dropdown.Item href="/settings" className="text-sm">
									Settings
								</Dropdown.Item>
								<Dropdown.Divider />
								<Dropdown.Item href="/logout" className="text-sm">
									Logout
								</Dropdown.Item>
							</Dropdown.Menu>
						</Dropdown>
					</Col>
				</Row>
			</Container>
		</div>
	);
};

export default PageHeader;
