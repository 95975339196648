import { useState, useEffect, useContext } from "react";
import { UserContext } from "../context/UserContext";
import { KeyContext } from "../context/KeyContext";
import { SaltContext } from "../context/SaltContext";
import { Link, useNavigate } from "react-router-dom";
import { encrypt } from "../components/Utils";
import { useSnackbar } from "react-simple-snackbar";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Logo from "../components/LogoDark";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";

const Signup = () => {
	document.title = "Sign Up | SMSafe";
	const navigate = useNavigate();
	const [user, setUser] = useContext(UserContext);
	const [, setKey] = useContext(KeyContext);
	const [, setSalt] = useContext(SaltContext);
	const [username, setUsername] = useState("");
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [nameMessage, setNameMessage] = useState("");
	const [passwordMessage, setPasswordMessage] = useState("");
	const [emailMessage, setEmailMessage] = useState("");
	const [nameLoading, setNameLoading] = useState(false);
	const [emailLoading, setEmailLoading] = useState(false);
	const [passwordLoading, setPasswordLoading] = useState(false);
	const [loading, setLoading] = useState(false);
	const [ready, setReady] = useState(false);
	const [showNav, setShowNav] = useState(false);
	const [showPassword, setShowPassword] = useState(false);

	const [defaultSnackbar] = useSnackbar();

	const [dangerSnackbar] = useSnackbar({
		style: {
			zIndex: 10,
			backgroundColor: "#d81e5b",
			color: "#fff",
		},
	});

	const saveUser = async (e) => {
		e.preventDefault();
		setLoading(true);
		setEmailMessage("");
		setNameMessage("");
		setPasswordMessage("");
		const requestOptions = {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify({
				email: email,
				username: username,
				password: password,
			}),
		};

		const response = await fetch(
			`${process.env.REACT_APP_API_URI}/users/`,
			requestOptions
		);
		const data = await response.json();

		if (!response.ok) {
			if (data.error == "email") {
				setEmailMessage({ is_valid: false, msg: data.msg });
			} else if (data.error == "username") {
				setNameMessage({ is_valid: false, msg: data.msg });
			} else {
				dangerSnackbar(data.msg);
			}
			validateUsername();
			validateEmail();
			validatePassword();
			setLoading(false);
		} else {
			setKey(encrypt(password, data.user_session.email, data.salt));
			setSalt(data.salt);
			setUser(data.user_session);
		}
	};

	const validateUsername = async () => {
		setNameLoading(true);
		setNameMessage("");
		const requestOptions = {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
			},
		};

		const response = await fetch(
			`${process.env.REACT_APP_API_URI}/users/usernames/validate/${username}`,
			requestOptions
		);
		const data = await response.json();

		if (!response.ok) {
			setNameMessage({ is_valid: false, msg: data.msg });
		} else {
			setNameMessage({ is_valid: true, msg: data.msg });
		}
		setNameLoading(false);
	};

	const validateEmail = async () => {
		setEmailLoading(true);
		setEmailMessage("");
		const requestOptions = {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
			},
		};

		const response = await fetch(
			`${process.env.REACT_APP_API_URI}/users/email/validate/${email}`,
			requestOptions
		);
		const data = await response.json();

		if (!response.ok) {
			setEmailMessage({ is_valid: false, msg: data.msg });
		} else {
			setEmailMessage({ is_valid: true, msg: data.msg });
		}
		setEmailLoading(false);
	};

	const validatePassword = () => {
		setPasswordMessage("");
		const hasNumber = /\d/.test(password);
		const hasCapitalLetter = /[A-Z]/.test(password);
		const hasSpecialCharacter = /[!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]/.test(
			password
		);

		if (hasNumber && hasCapitalLetter && hasSpecialCharacter) {
			setPasswordMessage({
				is_valid: true,
				msg: "Now that's a strong password!",
			});
		} else {
			setPasswordMessage({
				is_valid: false,
				msg: "Must have at least 1 number, 1 uppercase letter, and 1 special character.",
			});
		}
	};

	useEffect(() => {
		const timer = setTimeout(() => {
			if (username) {
				validateUsername();
			} else {
				setNameLoading(false);
				setNameMessage(null);
			}
		}, 500);
		return () => clearTimeout(timer);
	}, [username]);

	useEffect(() => {
		const timer = setTimeout(() => {
			if (email) {
				validateEmail();
			} else {
				setEmailLoading(false);
				setEmailMessage(null);
			}
		}, 500);
		return () => clearTimeout(timer);
	}, [email]);

	useEffect(() => {
		if (password) {
			validatePassword();
		} else {
			setPasswordLoading(false);
			setPasswordMessage(null);
		}
	}, [password]);

	useEffect(() => {
		if (
			username &&
			email &&
			password &&
			nameMessage?.is_valid &&
			emailMessage?.is_valid &&
			passwordMessage?.is_valid
		) {
			setReady(true);
		} else {
			setReady(false);
		}
	}, [username, email, password, nameMessage, passwordMessage, emailMessage]);

	useEffect(() => {
		if (user) {
			navigate("/");
		}
	}, [user]);

	return (
		<>
			<div className="full-page bg-white">
				<div className="login-header full flex-row-left">
					<Container>
						<Row className="flex-row-left">
							<Col>
								<Link to="/">
									<Logo variant="dark" className="nav-logo" />
								</Link>
							</Col>
							<Col className="nav-action-container flex-row-right p-0">
								<div className="nav-lg flex-row-right content-lg">
									<p className="text-light mb-0">
										Already have an account?{" "}
										<Link to="/login" className="text-blue text-bold-inter">
											Log in
										</Link>
									</p>
								</div>
								<div className="flex-row-right content-sm">
									<div
										className="nav-toggle flex-column"
										onClick={() => setShowNav(!showNav)}
									>
										<div className={`fries ${showNav && "active"}`}>
											<div className="top-fry" />
											<div className="mid-fry" />
											<div className="bottom-fry" />
										</div>
									</div>
								</div>
							</Col>
						</Row>
					</Container>
				</div>
				<div
					className={`nav-sm login bg-white border-top border-bottom ${
						showNav && "active"
					}`}
				>
					<Container className="full-height pt-4 pb-4">
						<p className="text-light mb-0">
							Already have an account?{" "}
							<Link to="/login" className="text-blue text-bold-inter">
								Log in
							</Link>
						</p>
					</Container>
				</div>
				<div className="login-form-container flex-column">
					<Container className="flex-column">
						<Form
							onSubmit={saveUser}
							className="login-form col-lg-4 col-md-7 col-12"
						>
							<div className="login-form-header align-center mb-50">
								<h1 className="login-form-title title-md text-regular-lexend">
									Create an account
								</h1>
							</div>
							<Row className="form-block">
								<Col>
									<Form.Label>Username</Form.Label>
									<Form.Control
										required
										type="text"
										className={`login-input rounded ${
											nameMessage && !nameMessage.is_valid && "error"
										}`}
										placeholder="Enter Username"
										value={username}
										onChange={(e) => setUsername(e.target.value)}
									/>
									<div className="login-error-msg full align-left">
										{nameLoading ? (
											<div className="ri-loader-3-line rotating fit mt-1" />
										) : nameMessage ? (
											<div className="full align-left mt-1">
												<span
													className={`label-md opacity-7 ${
														nameMessage.is_valid
															? "text-success"
															: "text-danger"
													}`}
												>
													{nameMessage.msg}
												</span>
											</div>
										) : (
											""
										)}
									</div>
								</Col>
							</Row>
							<Row className="form-block">
								<Col>
									<Form.Label>Email</Form.Label>
									<Form.Control
										required
										type="email"
										className={`login-input rounded ${
											emailMessage && !emailMessage.is_valid && "error"
										}`}
										placeholder="Enter Email"
										value={email}
										onChange={(e) => setEmail(e.target.value)}
									/>
									<div className="login-error-msg full align-left">
										{emailLoading ? (
											<div className="ri-loader-3-line rotating fit mt-1" />
										) : emailMessage ? (
											<div className="full align-left mt-1">
												<span
													className={`label-md opacity-7 ${
														emailMessage.is_valid
															? "text-success"
															: "text-danger"
													}`}
												>
													{emailMessage.msg}
												</span>
											</div>
										) : (
											""
										)}
									</div>
								</Col>
							</Row>
							<Row className="form-block">
								<Col>
									<Form.Label>Password</Form.Label>
									<div className="flex-row-left">
										<Form.Control
											required
											type={showPassword ? "text" : "password"}
											className={`login-input rounded ${
												passwordMessage && !passwordMessage.is_valid && "error"
											}`}
											placeholder="Enter Password"
											value={password}
											onChange={(e) => setPassword(e.target.value)}
										/>
										<i
											className={`show-pw-btn opacity-3 text-xl pointer ${
												showPassword ? "ri-eye-fill" : "ri-eye-off-fill"
											}`}
											onClick={() => setShowPassword(!showPassword)}
										/>
									</div>
									<div className="login-error-msg full align-left">
										{passwordLoading ? (
											<div className="ri-loader-3-line rotating fit mt-1" />
										) : passwordMessage ? (
											<div className="full align-left mt-1">
												<span
													className={`label-md opacity-7 ${
														passwordMessage.is_valid
															? "text-success"
															: "text-danger"
													}`}
												>
													{passwordMessage.msg}
												</span>
											</div>
										) : (
											""
										)}
									</div>
								</Col>
							</Row>
							<Row className="form-block">
								<Col>
									<Button
										type="submit"
										className="btn-default rounded-4 btn-lg full"
										disabled={loading || !ready}
									>
										{loading ? (
											<Spinner className="text-white btn-md-loader mt-1" />
										) : (
											"Sign Up"
										)}
									</Button>
								</Col>
							</Row>
							<p className="text-light label-md align-center">
								By clicking Sign up, you agree to our{" "}
								<Link to="terms" className="text-blue text-bold-inter">
									Terms
								</Link>{" "}
								&{" "}
								<Link to="privacy" className="text-blue text-bold-inter">
									Privacy Policy
								</Link>
							</p>
						</Form>
					</Container>
				</div>
			</div>
		</>
	);
};

export default Signup;
