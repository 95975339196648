import React, { useContext, useState, useEffect, useRef } from "react";
import { useNavigate, Link } from "react-router-dom";
import { UserContext } from "../context/UserContext";
import { ProfileContext } from "../context/ProfileContext";
import { KeyContext } from "../context/KeyContext";
import { SaltContext } from "../context/SaltContext";
import { useSnackbar } from "react-simple-snackbar";
import { encrypt, decrypt } from "../components/Utils";
import { format } from "date-fns";
import QRCode from "qrcode.react";
import AsyncSelect from "react-select/async";
import QRCodeReader from "qrcode-reader";
import jsQR from "jsqr";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import EmptyPage from "../components/EmptyPage";
import Modal from "react-bootstrap/Modal";
import Select, { components } from "react-select";
import PageLoader from "../components/PageLoader";
import Message from "../components/Message";
import MessageLoader from "../components/MessageLoader";
import Spinner from "react-bootstrap/esm/Spinner";
import AssetMember from "../components/AssetMember";
import AssetMFA from "../components/AssetMFA";
import Dropdown from "react-bootstrap/Dropdown";

const Dashboard = () => {
	const fileRef = useRef(null);
	const [user] = useContext(UserContext);
	const [profile] = useContext(ProfileContext);
	const [key] = useContext(KeyContext);
	const [salt] = useContext(SaltContext);
	const navigate = useNavigate();
	const [meta, setMeta] = useState(null);
	const [messages, setMessages] = useState([]);
	const [inboxLoading, setInboxLoading] = useState(false);
	const [userGroups, setUserGroups] = useState([]);
	const [groupMsisdns, setGroupMsisdns] = useState([]);
	const [countryCode, setCountryCode] = useState("");
	const [numberModal, setNumberModal] = useState(false);
	const [loading, setLoading] = useState(false);
	const [actionLoading, setActionLoading] = useState(false);
	const [groupModal, setGroupModal] = useState(false);
	const [safeName, setSafeName] = useState("");
	const [toDelete, setToDelete] = useState(null);
	const [tab, setTab] = useState("assets");
	const [page, setPage] = useState(1);
	const [deleteAssetId, setDeleteAssetId] = useState(null);

	const [assetName, setAssetName] = useState("");
	const [assetType, setAssetType] = useState("Login");
	const [assetUrl, setAssetUrl] = useState("");
	const [assetNotes, setAssetNotes] = useState("");
	const [username, setUsername] = useState("");
	const [password, setPassword] = useState("");
	const [assetCode, setAssetCode] = useState("");
	const [assetMsisdn, setAssetMsisdn] = useState("");
	const [cardNo, setCardNo] = useState("");
	const [cardExpiry, setCardExpiry] = useState("");
	const [cardCode, setCardCode] = useState("");
	const [cardName, setCardName] = useState("");
	const [apiKey, setApiKey] = useState("");
	const [apiSecret, setApiSecret] = useState("");
	const [docs, setDocs] = useState("");
	const [street, setStreet] = useState("");
	const [city, setCity] = useState("");
	const [province, setProvince] = useState("");
	const [country, setCountry] = useState("");
	const [postalCode, setPostalCode] = useState("");
	const [wifiEncryption, setWifiEncryption] = useState("WPA2");
	const [wifiName, setWifiName] = useState("");
	const [wifiPassword, setWifiPassword] = useState("");
	const [wifiIsHidden, setWifiIsHidden] = useState(false);
	const [showWifiPassword, setShowWifiPassword] = useState(false);

	const [assetLogs, setAssetLogs] = useState([]);
	const [activeAssetLog, setActiveAssetLog] = useState(null);

	const [contactName, setContactName] = useState("");
	const [contactEmail, setContactEmail] = useState("");
	const [contactMsisdn, setContactMsisdn] = useState("");

	const [showPassword, setShowPassword] = useState(false);
	const [showAssetCode, setShowAssetCode] = useState(false);
	const [assetInfoModal, setAssetInfoModal] = useState(false);
	const [assetLoading, setAssetLoading] = useState(false);
	const [assetTab, setAssetTab] = useState("details");
	const [canEdit, setCanEdit] = useState(false);
	const [linkLoading, setLinkLoading] = useState(false);
	const [assetLinks, setAssetLinks] = useState([]);

	const [assetInfo, setAssetInfo] = useState(null);
	const [assetErrors, setAssetErrors] = useState([]);
	const [shareInput, setShareInput] = useState("");
	const [shareTo, setShareTo] = useState([]);

	const [shareAllowEdit, setShareAllowEdit] = useState(false);
	const [shareExpiration, setShareExpiration] = useState("15");
	const [assetCopyId, setAssetCopyId] = useState(null);

	const [defaultSnackbar] = useSnackbar();

	const [dangerSnackbar] = useSnackbar({
		style: {
			zIndex: 10,
			backgroundColor: "#d81e5b",
			color: "#fff",
		},
	});

	const options = [
		{
			value: "US",
			label: "(+1) United States - $6/month",
			img: "https://flagcdn.com/h120/us.png",
		},
		{
			value: "AU",
			label: "(+61) Australia - $6/month",
			img: "https://flagcdn.com/h120/au.png",
		},
		{
			value: "CA",
			label: "(+1) Canada - $6/month",
			img: "https://flagcdn.com/h120/ca.png",
		},
		{
			value: "GB",
			label: "(+44) United Kingdom - $6/month",
			img: "https://flagcdn.com/h120/gb.png",
		},
	];

	const requiredFields = {
		Login: [
			{ key: "assetName", value: assetName },
			{ key: "username", value: username },
			{ key: "password", value: password },
		],
		MFA: [
			{ key: "assetName", value: assetName },
			{ key: "assetCode", value: assetCode },
		],
		Card: [
			{ key: "assetName", value: assetName },
			{ key: "cardNo", value: cardNo },
			{ key: "cardExpiry", value: cardExpiry },
			{ key: "cardCode", value: cardCode },
			{ key: "cardName", value: cardName },
		],
		Address: [
			{ key: "assetName", value: assetName },
			{ key: "street", value: street },
			{ key: "city", value: city },
			{ key: "province", value: province },
			{ key: "country", value: country },
			{ key: "postalCode", value: postalCode },
		],
		API: [
			{ key: "assetName", value: assetName },
			{ key: "apiKey", value: apiKey },
		],
		Contact: [{ key: "assetName", value: assetName }],
	};

	const { Option } = components;
	const IconOption = (props) => (
		<Option {...props}>
			<div className="flex-row-left">
				<img
					src={props.data.img}
					alt={props.data.label}
					className="country-icon"
				/>
				<span className="text-sm">{props.data.label}</span>
			</div>
		</Option>
	);

	const assetOptionToggle = React.forwardRef(({ children, onClick }, ref) => (
		<a
			href=""
			ref={ref}
			onClick={(e) => {
				e.preventDefault();
				onClick(e);
			}}
		>
			{children}
		</a>
	));

	const getData = async () => {
		setLoading(true);
		const requestOptions = {
			method: "GET",
			headers: {
				"X-Auth-Token": user ? user.session_token : "",
			},
		};

		const response = await fetch(
			`${process.env.REACT_APP_API_URI}/users/dashboard`,
			requestOptions
		);
		const data = await response.json();

		if (!response.ok) {
			console.log("Something went wrong.");
		} else {
			setMeta(data.meta);
			setUserGroups(data.groups);
			setMessages(data.messages);
			setGroupMsisdns(data.active_msisdns);
		}
		setLoading(false);
	};

	const addGroup = async (e) => {
		e.preventDefault();
		setActionLoading(true);
		const requestOptions = {
			method: "POST",
			headers: {
				"X-Auth-Token": user ? user.session_token : "",
			},
			body: JSON.stringify({
				name: safeName,
			}),
		};

		const response = await fetch(
			`${process.env.REACT_APP_API_URI}/users/groups`,
			requestOptions
		);
		const data = await response.json();

		if (!response.ok) {
			dangerSnackbar(data.msg);
		} else {
			setSafeName("");
			setGroupModal(false);
			defaultSnackbar(data.msg);
			navigate(`/safes/${data.group_id}`);
		}
		setActionLoading(false);
	};

	const getMessages = async () => {
		const requestOptions = {
			method: "GET",
			headers: {
				"X-Auth-Token": user ? user.session_token : "",
			},
		};

		const response = await fetch(
			`${process.env.REACT_APP_API_URI}/users/messages`,
			requestOptions
		);
		const data = await response.json();

		if (!response.ok) {
			console.log("Something went wrong.");
		} else {
			setMessages(data.messages);
		}
	};

	const addNumber = async (e) => {
		e.preventDefault();
		setActionLoading(true);
		const requestOptions = {
			method: "POST",
			headers: {
				"X-Auth-Token": user ? user.session_token : "",
			},
			body: JSON.stringify({
				local_code: countryCode.value,
			}),
		};

		const response = await fetch(
			`${process.env.REACT_APP_API_URI}/users/v-msisdn`,
			requestOptions
		);
		const data = await response.json();

		if (!response.ok) {
			console.log("Something went wrong.");
		} else {
			setCountryCode("");
			setNumberModal(false);
			navigate("/numbers");
		}
		setActionLoading(false);
	};

	const deleteMfa = async () => {
		setActionLoading(true);
		const requestOptions = {
			method: "DELETE",
			headers: {
				"X-Auth-Token": user ? user.session_token : "",
			},
		};

		const response = await fetch(
			`${process.env.REACT_APP_API_URI}/users/mfa/tokens/${toDelete}`,
			requestOptions
		);
		const data = await response.json();

		if (!response.ok) {
			console.log("Something went wrong.");
		} else {
			defaultSnackbar("2FA deleted.");
			getData();
		}
		setToDelete(null);
		setActionLoading(false);
	};

	const nextMessage = async () => {
		const requestOptions = {
			method: "GET",
			headers: {
				"X-Auth-Token": user ? user.session_token : "",
			},
		};

		const response = await fetch(
			`${process.env.REACT_APP_API_URI}/users/dashboard?page=${page + 1}`,
			requestOptions
		);
		const data = await response.json();

		if (!response.ok) {
			console.log("Something went wrong.");
		} else {
			setMessages((prevMessages) => [...prevMessages, ...data.messages]);
		}
		setInboxLoading(false);
	};

	const closeAssetModal = () => {
		setAssetInfoModal(null);
		setAssetInfo(null);
		setAssetName("");
		setAssetType("Login");
		setAssetUrl("");
		setUsername("");
		setPassword("");
		setAssetCode("");
		setCardNo("");
		setCardExpiry("");
		setCardCode("");
		setCardName("");
		setApiKey("");
		setApiSecret("");
		setDocs("");
		setAssetNotes("");
		setStreet("");
		setCity("");
		setProvince("");
		setCountry("");
		setPostalCode("");
		setContactName("");
		setContactEmail("");
		setContactMsisdn("");
		setShowAssetCode(false);
		setShowPassword(false);
		setAssetTab("details");
		setCanEdit(false);
		setAssetErrors([]);
		setShareTo([]);
		setShareInput("");
		setAssetLinks([]);
		setWifiEncryption("WPA2");
		setWifiName("");
		setWifiPassword("");
		setWifiIsHidden(false);
		setShowWifiPassword(false);
		setAssetLogs([]);
	};

	const copyAssetItem = async (asset_id, value) => {
		if (asset_id) {
			navigator.clipboard.writeText(value);
			defaultSnackbar("Copied to clipboard.");
		} else {
			navigator.clipboard.writeText(value);
			defaultSnackbar("Copied to clipboard.");
		}
	};

	const generatePassword = () => {
		const lowercaseLetters = "abcdefghijklmnopqrstuvwxyz";
		const uppercaseLetters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
		const numbers = "0123456789";
		const specialCharacters = '!@#$%&*()_"';

		const allCharacters =
			lowercaseLetters + uppercaseLetters + numbers + specialCharacters;

		let password = "";
		for (let i = 0; i < 16; i++) {
			// generating a 12-character password
			const randomIndex = Math.floor(Math.random() * allCharacters.length);
			password += allCharacters[randomIndex];
		}

		setPassword(password);
		setShowPassword(true);
	};

	const handleFileInputChange = (e) => {
		const file = e.target.files[0];
		if (!file) return;
		const qrCodeReader = new QRCodeReader();

		// Read the image file using FileReader
		const reader = new FileReader();
		reader.onload = () => {
			const img = new Image();
			img.src = reader.result;
			img.onload = () => {
				// Decode the QR code from the image
				const canvas = document.createElement("canvas");
				const context = canvas.getContext("2d");
				canvas.width = img.width;
				canvas.height = img.height;
				context.drawImage(img, 0, 0);

				const imageData = context.getImageData(
					0,
					0,
					canvas.width,
					canvas.height
				);

				// Decode the QR code using jsQR
				const code = jsQR(imageData.data, imageData.width, imageData.height);

				if (code) {
					const params = code.data.split("&");
					let secret = "";
					params.forEach((param) => {
						if (param.includes("secret=")) {
							secret = param.split("=")[1];
						}
					});
					setAssetCode(secret);
				} else {
					dangerSnackbar("QR code not detected.");
				}
			};
		};
		reader.readAsDataURL(file);
	};

	const shareAsset = async (e) => {
		e.preventDefault();
		setActionLoading(true);
		const requestOptions = {
			method: "POST",
			headers: {
				"X-Auth-Token": user ? user.session_token : "",
			},
			body: JSON.stringify({
				user_ids: shareTo,
				allow_edit: shareAllowEdit,
				expiration: shareExpiration,
			}),
		};

		const response = await fetch(
			`${process.env.REACT_APP_API_URI}/users/assets/${assetInfo.id}/access/batch`,
			requestOptions
		);
		const data = await response.json();

		if (!response.ok) {
			dangerSnackbar("Something went wrong.");
		} else {
			defaultSnackbar(data.msg);
			setShareTo([]);
			setShareInput("");
			setAssetInfo(data.asset);
		}
		setActionLoading(false);
	};

	const handleCardNoChange = (e) => {
		let formattedCardNo = e.target.value.replace(/\s/g, ""); // Remove any existing spaces
		formattedCardNo = formattedCardNo.replace(/(\d{4})/g, "$1 ").trim(); // Add space after every 4 characters

		setCardNo(formattedCardNo);
	};

	const handleCardExpiryChange = (e) => {
		let formattedCardExpiry = e.target.value.replace(/\s/g, ""); // Remove any existing spaces
		formattedCardExpiry = formattedCardExpiry
			.replace(/^(\d{2})(\d{1,2})$/, "$1/$2") // Add '/' after first 2 characters if not already added
			.replace(/^(\d{2})\/(\d{2})$/, "$1/$2"); // Add '/' after next 2 characters if not already added

		setCardExpiry(formattedCardExpiry);
	};

	const loadShareOptions = async (inputValue) => {
		const requestOptions = {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				"X-Auth-Token": user ? user.session_token : "",
			},
		};

		const response = await fetch(
			`${process.env.REACT_APP_API_URI}/users/groups/${assetInfo?.group_id}/share-options/${assetInfo?.id}`,
			requestOptions
		);
		const data = await response.json();

		if (!response.ok) {
			console.log("Something went wrong.");
		} else {
			return data.group_members?.map((GroupMember) => ({
				value: GroupMember.user.id,
				label: GroupMember.user.username
					? `${GroupMember.user.username} (${GroupMember.user.email})`
					: GroupMember.user.email,
			}));
		}
	};

	const deleteAsset = async () => {
		setActionLoading(true);
		const requestOptions = {
			method: "DELETE",
			headers: {
				"X-Auth-Token": user ? user.session_token : "",
			},
		};

		const response = await fetch(
			`${process.env.REACT_APP_API_URI}/users/assets/${deleteAssetId}`,
			requestOptions
		);
		const data = await response.json();

		if (!response.ok) {
			dangerSnackbar("Something went wrong.");
		} else {
			defaultSnackbar(data.msg);
			getData();
			closeAssetModal();
		}
		setDeleteAssetId(null);
		setActionLoading(false);
	};

	const editAsset = async (encryptedData) => {
		var errors = [];
		const requestOptions = {
			method: "POST",
			headers: {
				"X-Auth-Token": user ? user.session_token : "",
			},
			body: JSON.stringify({
				hashed: {
					name: assetName,
					type: assetType,
					url: assetUrl,
					notes: encryptedData.assetNotes,
					username: encryptedData.username,
					password: encryptedData.password,
					msisdn: encryptedData.assetMsisdn,
					card_no: encryptedData.cardNo,
					expiration: encryptedData.cardExpiry,
					cvv: encryptedData.cardCode,
					card_name: encryptedData.cardName,
					api_key: encryptedData.apiKey,
					api_secret: encryptedData.apiSecret,
					documentation: encryptedData.docs,
					street: encryptedData.street,
					city: encryptedData.city,
					province: encryptedData.province,
					country: encryptedData.country,
					postal_code: encryptedData.postalCode,
					contact_name: encryptedData.contactName,
					contact_email: encryptedData.contactEmail,
					contact_msisdn: encryptedData.contactMsisdn,
					wifi_name: encryptedData.wifiName,
					wifi_password: encryptedData.wifiPassword,
					wifi_encryption: encryptedData.wifiEncryption,
					wifi_is_hidden: wifiIsHidden,
					token: assetCode,
				},
				raw:
					assetInfo?.shared_to.length !== 0
						? {
								name: assetName,
								type: assetType,
								url: assetUrl,
								notes: assetNotes,
								username: username,
								password: password,
								msisdn: assetMsisdn,
								card_no: cardNo,
								expiration: cardExpiry,
								cvv: cardCode,
								card_name: cardName,
								api_key: apiKey,
								api_secret: apiSecret,
								documentation: docs,
								street: street,
								city: city,
								province: province,
								country: country,
								postal_code: postalCode,
								contact_name: contactName,
								contact_email: contactEmail,
								contact_msisdn: contactMsisdn,
								wifi_name: wifiName,
								wifi_password: wifiPassword,
								wifi_encryption: wifiEncryption,
								wifi_is_hidden: wifiIsHidden,
								token: assetCode,
						  }
						: null,
			}),
		};

		const response = await fetch(
			`${process.env.REACT_APP_API_URI}/users/assets/${assetInfo.id}`,
			requestOptions
		);
		const data = await response.json();

		if (!response.ok) {
			dangerSnackbar(data.msg);
			errors.push(data.error);
			setAssetErrors(errors);
		} else {
			defaultSnackbar(data.msg);
			getData();
			closeAssetModal();
		}
		setActionLoading(false);
	};

	const copyAssetLink = (path) => {
		navigator.clipboard.writeText(path);
		defaultSnackbar("Copied to clipboard.");
	};

	const generateAssetLink = async () => {
		setLinkLoading(true);
		const requestOptions = {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				"X-Auth-Token": user ? user.session_token : "",
			},
		};

		const response = await fetch(
			`${process.env.REACT_APP_API_URI}/users/assets/${assetInfo?.id}/links`,
			requestOptions
		);
		const data = await response.json();

		if (!response.ok) {
			dangerSnackbar(data.msg);
		} else {
			defaultSnackbar(data.msg);
			setAssetLinks(data.links);
		}
		setLinkLoading(false);
	};

	const encryptAssetData = (callback) => {
		const encryptedData = {};
		const assetPassword = decrypt(key, profile.user.email, salt);

		encryptedData.assetNotes = encrypt(assetNotes, assetPassword, salt);
		encryptedData.username = encrypt(username, assetPassword, salt);
		encryptedData.password = encrypt(password, assetPassword, salt);
		encryptedData.assetMsisdn = encrypt(assetMsisdn, assetPassword, salt);
		encryptedData.cardNo = encrypt(cardNo, assetPassword, salt);
		encryptedData.cardExpiry = encrypt(cardExpiry, assetPassword, salt);
		encryptedData.cardCode = encrypt(cardCode, assetPassword, salt);
		encryptedData.cardName = encrypt(cardName, assetPassword, salt);
		encryptedData.apiKey = encrypt(apiKey, assetPassword, salt);
		encryptedData.apiSecret = encrypt(apiSecret, assetPassword, salt);
		encryptedData.docs = encrypt(docs, assetPassword, salt);
		encryptedData.street = encrypt(street, assetPassword, salt);
		encryptedData.city = encrypt(city, assetPassword, salt);
		encryptedData.province = encrypt(province, assetPassword, salt);
		encryptedData.country = encrypt(country, assetPassword, salt);
		encryptedData.postalCode = encrypt(postalCode, assetPassword, salt);
		encryptedData.contactName = encrypt(contactName, assetPassword, salt);
		encryptedData.contactEmail = encrypt(contactEmail, assetPassword, salt);
		encryptedData.contactMsisdn = encrypt(contactMsisdn, assetPassword, salt);
		encryptedData.wifiName = encrypt(wifiName, assetPassword, salt);
		encryptedData.wifiPassword = encrypt(wifiPassword, assetPassword, salt);
		encryptedData.wifiEncryption = encrypt(wifiEncryption, assetPassword, salt);

		callback(encryptedData);
	};

	const decryptAsset = (asset) => {
		const assetPassword = decrypt(key, profile.user.email, salt);

		setUsername(decrypt(asset.username, assetPassword, salt));
		setPassword(decrypt(asset.password, assetPassword, salt));
		setAssetMsisdn(decrypt(asset.msisdn, assetPassword, salt));
		setCardNo(decrypt(asset.card_no, assetPassword, salt));
		setCardExpiry(decrypt(asset.expiration, assetPassword, salt));
		setCardCode(decrypt(asset.cvv, assetPassword, salt));
		setCardName(decrypt(asset.card_name, assetPassword, salt));
		setApiKey(decrypt(asset.api_key, assetPassword, salt));
		setApiSecret(decrypt(asset.api_secret, assetPassword, salt));
		setDocs(decrypt(asset.documentation, assetPassword, salt));
		setStreet(decrypt(asset.street, assetPassword, salt));
		setCity(decrypt(asset.city, assetPassword, salt));
		setProvince(decrypt(asset.province, assetPassword, salt));
		setCountry(decrypt(asset.country, assetPassword, salt));
		setPostalCode(decrypt(asset.postal_code, assetPassword, salt));
		setContactName(decrypt(asset.contact_name, assetPassword, salt));
		setContactEmail(decrypt(asset.contact_email, assetPassword, salt));
		setContactMsisdn(decrypt(asset.contact_msisdn, assetPassword, salt));
		setWifiEncryption(decrypt(asset.wifi_encryption, assetPassword, salt));
		setWifiName(decrypt(asset.wifi_name, assetPassword, salt));
		setWifiPassword(decrypt(asset.wifi_password, assetPassword, salt));
		setAssetNotes(decrypt(asset.notes, assetPassword, salt));
	};

	const unlockAsset = async (encryptedData) => {
		const requestOptions = {
			method: "POST",
			headers: {
				"X-Auth-Token": user ? user.session_token : "",
			},
			body: JSON.stringify({
				notes: encryptedData.assetNotes,
				username: encryptedData.username,
				password: encryptedData.password,
				msisdn: encryptedData.assetMsisdn,
				card_no: encryptedData.cardNo,
				expiration: encryptedData.cardExpiry,
				cvv: encryptedData.cardCode,
				card_name: encryptedData.cardName,
				api_key: encryptedData.apiKey,
				api_secret: encryptedData.apiSecret,
				documentation: encryptedData.docs,
				street: encryptedData.street,
				city: encryptedData.city,
				province: encryptedData.province,
				country: encryptedData.country,
				postal_code: encryptedData.postalCode,
				contact_name: encryptedData.contactName,
				contact_email: encryptedData.contactEmail,
				contact_msisdn: encryptedData.contactMsisdn,
				wifi_name: encryptedData.wifiName,
				wifi_password: encryptedData.wifiPassword,
				wifi_encryption: encryptedData.wifiEncryption,
			}),
		};

		const response = await fetch(
			`${process.env.REACT_APP_API_URI}/users/assets/${assetCopyId}/unlock`,
			requestOptions
		);

		if (!response.ok) {
			console.log("Could not unlock asset.");
		} else {
			setAssetCopyId(null);
		}
	};

	const handleAsset = async (asset) => {
		setAssetLoading(true);
		setAssetInfoModal(true);

		const requestOptions = {
			method: "GET",
			headers: {
				"X-Auth-Token": user ? user.session_token : "",
			},
		};

		const response = await fetch(
			`${process.env.REACT_APP_API_URI}/users/assets/${asset.id}`,
			requestOptions
		);
		const data = await response.json();

		if (!response.ok) {
			closeAssetModal();
		} else {
			setAssetInfo(data.parent_asset);
			setAssetName(data.asset.name);
			setAssetType(data.asset.type_);
			setAssetUrl(data.asset.url);

			if (data.asset.asset_id && !data.asset.unlocked) {
				setUsername(data.asset.username);
				setPassword(data.asset.password);
				setAssetMsisdn(data.asset.msisdn);
				setCardNo(data.asset.card_no);
				setCardExpiry(data.asset.expiration);
				setCardCode(data.asset.cvv);
				setCardName(data.asset.card_name);
				setApiKey(data.asset.api_key);
				setApiSecret(data.asset.api_secret);
				setDocs(data.asset.documentation);
				setStreet(data.asset.street);
				setCity(data.asset.city);
				setProvince(data.asset.province);
				setCountry(data.asset.country);
				setPostalCode(data.asset.postal_code);
				setContactName(data.asset.contact_name);
				setContactEmail(data.asset.contact_email);
				setContactMsisdn(data.asset.contact_msisdn);
				setWifiEncryption(data.asset.wifi_encryption);
				setWifiName(data.asset.wifi_name);
				setWifiPassword(data.asset.wifi_password);

				setAssetCopyId(data.asset.id);
			} else {
				decryptAsset(data.asset);
			}

			setAssetCode(data.asset.mfa_token?.token || "");
			setWifiIsHidden(data.asset.wifi_is_hidden);

			setAssetLinks(data.asset.links || []);
			setAssetLogs(data.asset.logs);
			setCanEdit(data.can_edit);
		}
		setAssetLoading(false);
	};

	const handleAssetEdit = (e) => {
		e.preventDefault();
		setActionLoading(true);
		var newErrors = [];

		requiredFields[assetType].map((field) => {
			if (field.value === "" || field.value.length === 0) {
				newErrors.push(field.key);
			}
		});

		if (newErrors.length !== 0) {
			dangerSnackbar("Please fill out all required fields.");
			setActionLoading(false);
			setAssetErrors(newErrors);
			return;
		}
		encryptAssetData(editAsset);
	};

	useEffect(() => {
		if (assetCopyId) {
			encryptAssetData(unlockAsset);
		}
	}, [assetCopyId]);

	useEffect(() => {
		const handleScroll = () => {
			const scrollPosition = window.innerHeight + window.scrollY + 1;
			if (
				scrollPosition >= document.body.offsetHeight &&
				!inboxLoading &&
				messages.length < meta?.total
			) {
				setInboxLoading(true);
				nextMessage();
				setPage(page + 1);
			} else {
				setInboxLoading(false);
			}
		};

		window.addEventListener("scroll", handleScroll);

		return () => {
			window.removeEventListener("scroll", handleScroll);
		};
	}, [meta, messages, page]);

	useEffect(() => {
		document.title = "Dashboard | SMSafe";
		getData();
	}, []);

	return (
		<>
			<div className="dashboard-content full-page">
				<div className="dashboard-banner g-default pt-4 pb-4 mb-30">
					<Container>
						<h1 className="page-content-title mb-30 title-md text-medium-inter text-white">
							Dashboard
						</h1>
						<Row>
							<Col className="col-4">
								<Link to="/safes">
									<div className="dashboard-stat rounded flex-column">
										<div className="stat-text-container">
											<span className="title-md text-medium-inter text-white">
												{userGroups?.length || 0}
											</span>
										</div>
										<div className="stat-text-container">
											<span className="text-white opacity-7 text-sm">
												{userGroups?.length === 1 ? "Safe" : "Safes"}
											</span>
										</div>
									</div>
								</Link>
							</Col>
							<Col className="col-4">
								<Link
									to={
										userGroups.length === 0
											? "/safes"
											: `/safes/${userGroups[0].group.id}?tab=numbers`
									}
								>
									<div className="dashboard-stat rounded flex-column">
										<div className="stat-text-container">
											<span className="title-md text-medium-inter text-white">
												{userGroups.reduce(
													(total, userGroup) =>
														total + userGroup.group.group_v_msisdns.length,
													0
												)}
											</span>
										</div>
										<div className="stat-text-container">
											<span className="text-white opacity-7 text-sm">
												{userGroups.reduce(
													(total, userGroup) =>
														total + userGroup.group.group_v_msisdns.length,
													0
												) === 1
													? "Number"
													: "Numbers"}
											</span>
										</div>
									</div>
								</Link>
							</Col>
							<Col className="col-4">
								<Link
									to={
										userGroups.length === 0
											? "/safes"
											: `/safes/${userGroups[0].group.id}?tab=assets`
									}
								>
									<div className="dashboard-stat rounded flex-column">
										<div className="stat-text-container">
											<span className="title-md text-medium-inter text-white">
												{userGroups.reduce(
													(total, userGroup) =>
														total + userGroup.group.assets.length,
													0
												)}
											</span>
										</div>
										<div className="stat-text-container">
											<span className="text-white opacity-7 text-sm">
												{userGroups.reduce(
													(total, userGroup) =>
														total + userGroup.group.assets.length,
													0
												) === 1
													? "Asset"
													: "Assets"}
											</span>
										</div>
									</div>
								</Link>
							</Col>
						</Row>
					</Container>
				</div>
				<div className="content-sm">
					<Container>
						<div
							className={`dashboard-tabs flex-row-left mb-15 bg-gray ${tab}`}
						>
							<Row className="full g-0">
								<Col>
									<a
										href="#"
										className={`tab flex-column full ${
											tab === "assets" && "active"
										}`}
										onClick={(e) => {
											e.preventDefault();
											setTab("assets");
										}}
									>
										<span className="text-sm text-medium-inter">Assets</span>
									</a>
								</Col>
								<Col>
									<a
										href="#"
										className={`tab flex-column full ${
											tab === "inbox" && "active"
										}`}
										onClick={(e) => {
											e.preventDefault();
											setTab("inbox");
										}}
									>
										<span className="text-sm text-medium-inter">Inbox</span>
									</a>
								</Col>
							</Row>
						</div>
					</Container>
					<Container>
						{tab === "assets" &&
							(userGroups.reduce(
								(total, userGroup) => total + userGroup.group.assets.length,
								0
							) === 0 ? (
								<EmptyPage label="No assets to display" />
							) : (
								userGroups.map(
									(userGroup) =>
										userGroup.group.assets.length !== 0 && (
											<div
												key={userGroup.id}
												className="bg-white rounded-4 mb-10 p-20"
											>
												<Row className="flex-row-left mb-10">
													<Col className="col-7 ellipsis">
														<span className="text-medium-inter mb-0">
															{userGroup.group.name}
														</span>
													</Col>
													<Col className="col-5 flex-row-right">
														<Link
															to={`/safes/${userGroup.group.id}`}
															className="label-md upper text-medium-inter text-blue"
														>
															Open Safe
														</Link>
													</Col>
												</Row>
												{userGroup.group.assets
													.slice(0, 3)
													.sort((a, b) => a.name.localeCompare(b.name))
													.map((asset) => (
														<div
															key={asset.id}
															className="dashboard-asset bg-white flex-row-left"
														>
															<div
																key={asset.id}
																className="flex-row-left full pointer"
																onClick={() => handleAsset(asset)}
															>
																<div
																	className="dashboard-asset-logo border flex-column"
																	style={{
																		background: asset?.logo
																			? `url(${asset.logo})`
																			: "#3c57cc",
																	}}
																>
																	{!asset?.logo && (
																		<span className="text-medium-inter text-white text-sm upper">
																			{asset.name[0]}
																		</span>
																	)}
																</div>
																<div>
																	<span className="text-sm text-medium-inter block">
																		{asset.name}
																	</span>
																	<span className="label-md opacity-7 block">
																		{asset.type_}
																	</span>
																</div>
															</div>
														</div>
													))}
											</div>
										)
								)
							))}
						{tab === "inbox" && (
							<div className="mb-50 pt-3">
								{messages.length === 0 ? (
									<EmptyPage label="No messages to display" />
								) : (
									messages.map((message) => (
										<Message key={message.id} message={message} user={user} />
									))
								)}
								{inboxLoading && <MessageLoader />}
							</div>
						)}
					</Container>
				</div>
				<Container className="content-lg">
					{loading ? (
						<PageLoader />
					) : (
						<>
							{!loading && userGroups.length === 0 && (
								<div className="group-cta bg-default rounded p-30 flex-row-left mb-30">
									<Row className="full gx-0">
										<Col className="col-md-8 col-12">
											<h2 className="text-lg text-white text-medium-inter">
												Create your first safe
											</h2>
											<p className="mb-0 label-md text-white opacity-7">
												A safe is a secure place for your OTP phone numbers and
												2FA codes.
											</p>
										</Col>
										<Col className="col-md-4 col-12 group-cta-btn-container flex-row-right">
											<Button
												variant="light"
												className="group-banner-btn btn-sm text-sm text-blue text-bold-inter"
												onClick={() => setGroupModal(true)}
											>
												Create Safe
											</Button>
										</Col>
									</Row>
								</div>
							)}
							<Row>
								<Col className="page-content col-lg-8 col-12">
									<div className="page-content-header flex-row-left">
										<span className="opacity-7">Safes & Assets</span>
									</div>
									{userGroups?.length === 0 ? (
										<EmptyPage label="No safes" />
									) : (
										userGroups.map((userGroup) => (
											<div
												key={userGroup.id}
												className="bg-white rounded-4 mb-10 p-20"
											>
												<Row className="flex-row-left mb-1">
													<Col className="col-7 ellipsis">
														<span className="text-medium-inter mb-0">
															{userGroup.group.name}
														</span>
													</Col>
													<Col className="col-5 flex-row-right">
														<Link
															to={`/safes/${userGroup.group.id}`}
															className="label-md upper text-medium-inter text-blue"
														>
															Open Safe
														</Link>
													</Col>
												</Row>
												{userGroup.group.assets.length === 0 && (
													<div className="pt-2 pb-2">
														<span className="text-sm opacity-5">No assets</span>
													</div>
												)}
												{userGroup.group.assets
													.slice(0, 3)
													.sort((a, b) => a.name.localeCompare(b.name))
													.map((asset) => (
														<div
															key={asset.id}
															className="dashboard-asset bg-white flex-row-left"
															onClick={() => handleAsset(asset)}
														>
															<div className="flex-row-left full pointer">
																<div
																	className="dashboard-asset-logo border flex-column"
																	style={{
																		background: asset?.logo
																			? `url(${asset.logo})`
																			: "#3c57cc",
																	}}
																>
																	{!asset?.logo && (
																		<span className="text-medium-inter text-white text-sm upper">
																			{asset.name[0]}
																		</span>
																	)}
																</div>
																<div>
																	<span className="text-sm text-medium-inter block">
																		{asset.name}
																	</span>
																	<span className="label-md opacity-7 block">
																		{asset.type_}
																	</span>
																</div>
															</div>
														</div>
													))}
											</div>
										))
									)}
								</Col>
								<Col className="page-sub-content col-lg-4 col-12">
									<div className="page-content-header flex-row-left">
										<div className="col-6">
											<span className="opacity-7">Inbox</span>
										</div>
									</div>
									<div className="page-content-body mb-50">
										{messages.length === 0 ? (
											<EmptyPage label="No messages to display" />
										) : (
											messages.map((message) => (
												<Message
													key={message.id}
													message={message}
													user={user}
												/>
											))
										)}
										{inboxLoading && <MessageLoader />}
									</div>
								</Col>
							</Row>
						</>
					)}
				</Container>
			</div>
			<Modal
				show={numberModal}
				className="post-modal"
				onHide={() => setNumberModal(false)}
				centered
			>
				<Modal.Header className="text-medium-inter text-lg">
					Add Number
				</Modal.Header>
				<Form onSubmit={addNumber}>
					<Modal.Body>
						<Form.Label>Country Code</Form.Label>
						<Select
							defaultValue={countryCode}
							onChange={setCountryCode}
							options={options}
							components={{ Option: IconOption }}
						/>
					</Modal.Body>
					<Modal.Footer>
						<Button
							variant="light"
							className="modal-btn btn-md dismiss-btn text-medium-inter text-sm"
							onClick={() => setNumberModal(false)}
						>
							Cancel
						</Button>
						<Button
							type="submit"
							className="modal-btn btn-md btn-default text-medium-inter text-sm"
							disabled={actionLoading || !countryCode}
						>
							{actionLoading ? "Adding.." : "Add"}
						</Button>
					</Modal.Footer>
				</Form>
			</Modal>
			<Modal show={toDelete} onHide={() => setToDelete(null)} centered>
				<Modal.Body>
					<h2 className="text-lg text-medium-inter">Delete 2FA Account</h2>
					<p className="text-sm opacity-5 mb-0">
						Are you sure you want to delete this 2FA account? You wouldn’t be
						able to login to this service if 2FA is still enabled.
					</p>
				</Modal.Body>
				<Modal.Footer>
					<Button
						variant="light"
						className="modal-btn btn-sm dismiss-btn text-medium-inter text-sm"
						onClick={() => setToDelete(null)}
					>
						Cancel
					</Button>
					<Button
						className="modal-btn btn-sm btn-default text-medium-inter text-sm"
						disabled={actionLoading}
						onClick={deleteMfa}
					>
						{actionLoading ? "Deleting.." : "Yes, Delete"}
					</Button>
				</Modal.Footer>
			</Modal>
			<Modal
				show={groupModal}
				className="post-modal"
				onHide={() => setGroupModal(false)}
				centered
			>
				<Modal.Header className="text-medium-inter text-lg">
					Create Safe
				</Modal.Header>
				<Form onSubmit={addGroup}>
					<Modal.Body>
						<Row className="form-block-sm">
							<Col>
								<Form.Label>Safe Name</Form.Label>
								<Form.Control
									className="form-input"
									placeholder="Enter safe name"
									value={safeName}
									onChange={(e) => setSafeName(e.target.value)}
								/>
							</Col>
						</Row>
					</Modal.Body>
					<Modal.Footer>
						<Button
							variant="light"
							className="modal-btn btn-md dismiss-btn text-medium-inter text-sm"
							onClick={() => setGroupModal(false)}
						>
							Cancel
						</Button>
						<Button
							type="submit"
							className="modal-btn btn-md btn-default text-medium-inter text-sm"
							disabled={actionLoading}
						>
							{actionLoading ? "Creating.." : "Create Safe"}
						</Button>
					</Modal.Footer>
				</Form>
			</Modal>
			<Modal
				show={assetInfoModal}
				size="lg"
				onHide={closeAssetModal}
				className="asset-modal"
				centered
			>
				<Modal.Header>
					<div>
						<span className="text-medium-inter text-lg">Asset Info</span>
					</div>
					{assetInfo?.user_id === profile?.user.id && (
						<Dropdown align="end">
							<Dropdown.Toggle as={assetOptionToggle}>
								<i className="ri-more-2-fill" />
							</Dropdown.Toggle>
							<Dropdown.Menu className="account-dropdown dropdown-default">
								<Dropdown.Item
									href="#"
									className="text-sm text-danger"
									onClick={(e) => {
										e.preventDefault();
										setDeleteAssetId(assetInfo.id);
									}}
								>
									Delete
								</Dropdown.Item>
							</Dropdown.Menu>
						</Dropdown>
					)}
				</Modal.Header>
				<Form onSubmit={handleAssetEdit}>
					<Modal.Body>
						{assetLoading ? (
							<div className="flex-column full-height">
								<Spinner className="text-blue" />
							</div>
						) : (
							<>
								{assetInfo?.user_id === profile?.user.id && (
									<div className={`asset-tabs flex-row-left mb-30 ${assetTab}`}>
										<div>
											<a
												href="#"
												className={`tab flex-column full ${
													assetTab === "details" && "active"
												}`}
												onClick={(e) => {
													e.preventDefault();
													setAssetTab("details");
												}}
											>
												<span className="text-sm text-medium-inter opacity-7">
													Details
												</span>
											</a>
										</div>
										<div>
											<a
												href="#"
												className={`tab flex-column full ${
													assetTab === "sharing" && "active"
												}`}
												onClick={(e) => {
													e.preventDefault();
													setAssetTab("sharing");
												}}
											>
												<span className="text-sm text-medium-inter opacity-7">
													Sharing
												</span>
											</a>
										</div>
										<div>
											<a
												href="#"
												className={`tab flex-column full ${
													assetTab === "activity" && "active"
												}`}
												onClick={(e) => {
													e.preventDefault();
													setAssetTab("activity");
												}}
											>
												<span className="text-sm text-medium-inter opacity-7">
													Activity
												</span>
											</a>
										</div>
									</div>
								)}
								{assetTab === "details" && (
									<>
										{assetType === "Wifi" && (
											<Row className="form-block-sm pt-4">
												<Col className="flex-column">
													<QRCode
														value={`WIFI:T:${wifiEncryption};S:${wifiName};P:${wifiPassword};H:${wifiIsHidden};;`}
														className="wifi-qr mb-10"
													/>
													<span className="text-sm opacity-7">
														Scan this QR to connect.
													</span>
												</Col>
											</Row>
										)}
										<Row className="form-block-sm">
											<Col>
												<Form.Label>Asset Name*</Form.Label>
												<Form.Control
													type="text"
													className={`form-input input-lg text-sm rounded-4 ${
														assetErrors.includes("assetName") && "border-danger"
													}`}
													placeholder={canEdit ? "Enter asset name." : ""}
													value={assetName}
													onChange={(e) => setAssetName(e.target.value)}
													disabled={!canEdit}
												/>
											</Col>
										</Row>
										{assetType === "Login" && (
											<>
												<Row className="form-block-sm">
													<Col>
														<Form.Label>
															URL{" "}
															<span className="opacity-5 text-sm">
																(Optional)
															</span>
														</Form.Label>
														<Form.Control
															type="text"
															className={`form-input input-lg text-sm rounded-4 ${
																assetErrors.includes("assetUrl") &&
																"border-danger"
															}`}
															placeholder={canEdit ? "Enter website URL" : ""}
															value={assetUrl}
															onChange={(e) => setAssetUrl(e.target.value)}
															disabled={!canEdit}
														/>
													</Col>
												</Row>
												<Row className="form-block-sm">
													<Col>
														<Form.Label>Username*</Form.Label>
														<div className="flex-row-left full">
															<Form.Control
																type="text"
																className={`asset-username form-input input-lg text-sm rounded-4 ${
																	assetErrors.includes("username") &&
																	"border-danger"
																}`}
																placeholder={
																	canEdit ? "Enter username/email" : ""
																}
																value={username}
																onChange={(e) => setUsername(e.target.value)}
																disabled={!canEdit}
															/>
															<div className="asset-pw-actions flex-row-right">
																<Button
																	variant="light flex-column"
																	onClick={() => copyAssetItem(null, username)}
																>
																	<i className="ri-file-copy-line opacity-7" />
																</Button>
															</div>
														</div>
													</Col>
												</Row>
												<Row className="form-block-sm">
													<Col>
														<Form.Label>Password*</Form.Label>
														<div className="flex-row-left full">
															<Form.Control
																type={showPassword ? "text" : "password"}
																className={`asset-password form-input input-lg text-sm rounded-4 ${
																	assetErrors.includes("password") &&
																	"border-danger"
																}`}
																placeholder={canEdit ? "Enter password." : ""}
																value={password}
																onChange={(e) => setPassword(e.target.value)}
																disabled={!canEdit}
															/>
															<div className="asset-pw-actions flex-row-right">
																<Button
																	variant="light flex-column"
																	onClick={() => setShowPassword(!showPassword)}
																>
																	<i
																		className={`opacity-7 ${
																			showPassword
																				? "ri-eye-off-line"
																				: "ri-eye-line"
																		}`}
																	/>
																</Button>
																{canEdit && (
																	<Button
																		variant="light flex-column"
																		onClick={generatePassword}
																	>
																		<i className="ri-refresh-line opacity-7" />
																	</Button>
																)}
																<Button
																	variant="light flex-column"
																	onClick={() => copyAssetItem(null, password)}
																>
																	<i className="ri-file-copy-line opacity-7" />
																</Button>
															</div>
														</div>
													</Col>
												</Row>
												<Row className="form-block-sm">
													<Col>
														<Form.Label>
															Authenticator Key (TOTP){" "}
															<span className="opacity-5 text-sm">
																(Optional)
															</span>
														</Form.Label>
														{assetInfo?.mfa_token && !showAssetCode ? (
															<AssetMFA
																key={assetInfo.mfa_token.id}
																mfa={assetInfo.mfa_token}
																showCode={setShowAssetCode}
																canEdit={canEdit}
															/>
														) : (
															<div className="flex-row-left full">
																<Form.Control
																	type="text"
																	className={`asset-code form-input input-lg text-sm rounded-4 ${
																		assetErrors.includes("assetCode") &&
																		"border-danger"
																	}`}
																	placeholder={
																		canEdit ? "Enter 2FA setup key." : ""
																	}
																	value={assetCode}
																	onChange={(e) => setAssetCode(e.target.value)}
																/>
																<div className="asset-pw-actions flex-row-right">
																	<input
																		ref={fileRef}
																		type="file"
																		accept="image/png, image/jpeg"
																		onChange={handleFileInputChange}
																		className="hidden"
																	/>
																	{assetInfo?.user_id === profile?.user.id && (
																		<Button
																			variant="light flex-column"
																			onClick={() => fileRef.current.click()}
																		>
																			<i className="ri-qr-code-line opacity-7" />
																		</Button>
																	)}
																</div>
															</div>
														)}
													</Col>
												</Row>
												<Row className="form-block-sm">
													<Col>
														<Form.Label>
															Phone Number{" "}
															<span className="opacity-5 text-sm">
																(Optional)
															</span>
														</Form.Label>
														<Form.Select
															className={`form-input input-lg text-sm rounded-4 ${
																assetErrors.includes("assetMsisdn") &&
																"border-danger"
															}`}
															placeholder={
																canEdit ? "Enter virtual phone number." : ""
															}
															value={assetMsisdn}
															onChange={(e) => setAssetMsisdn(e.target.value)}
															disabled={!canEdit}
														>
															<option value=""></option>
															{groupMsisdns?.map((groupMsisdn) => (
																<option
																	key={groupMsisdn.id}
																	value={groupMsisdn.v_msisdn.msisdn}
																>
																	{groupMsisdn.v_msisdn.msisdn}
																</option>
															))}
														</Form.Select>
													</Col>
												</Row>
												<Row className="form-block-sm">
													<Col>
														<Form.Label>
															Notes{" "}
															<span className="opacity-5 text-sm">
																(Optional)
															</span>
														</Form.Label>
														<Form.Control
															as="textarea"
															className="form-input asset-notes text-sm rounded-4"
															placeholder={canEdit ? "Add notes" : ""}
															value={assetNotes}
															onChange={(e) => setAssetNotes(e.target.value)}
															disabled={!canEdit}
														/>
													</Col>
												</Row>
											</>
										)}
										{assetType === "MFA" && (
											<Row className="form-block-sm">
												<Col>
													<Form.Label>Authenticator Key (TOTP)*</Form.Label>
													{assetInfo?.mfa_token && !showAssetCode ? (
														<AssetMFA
															key={assetInfo.mfa_token.id}
															mfa={assetInfo.mfa_token}
															showCode={setShowAssetCode}
															canEdit={canEdit}
														/>
													) : (
														<div className="flex-row-left full">
															<Form.Control
																type="text"
																className={`asset-code form-input input-lg text-sm rounded-4 ${
																	assetErrors.includes("assetCode") &&
																	"border-danger"
																}`}
																placeholder={
																	canEdit ? "Enter 2FA setup key." : ""
																}
																value={assetCode}
																onChange={(e) => setAssetCode(e.target.value)}
															/>
															<div className="asset-pw-actions flex-row-right">
																<input
																	ref={fileRef}
																	type="file"
																	accept="image/png, image/jpeg"
																	onChange={handleFileInputChange}
																	className="hidden"
																/>
																{assetInfo?.user_id === profile?.user.id && (
																	<Button
																		variant="light flex-column"
																		onClick={() => fileRef.current.click()}
																	>
																		<i className="ri-qr-code-line opacity-7" />
																	</Button>
																)}
															</div>
														</div>
													)}
												</Col>
											</Row>
										)}
										{assetType === "Card" && (
											<>
												<Row className="form-block-sm">
													<Col>
														<Form.Label>Card Number*</Form.Label>
														<div className="flex-row-left pull">
															<Form.Control
																type="text"
																className={`asset-card-info form-input input-lg text-sm rounded-4 ${
																	assetErrors.includes("cardNo") &&
																	"border-danger"
																}`}
																placeholder={
																	canEdit ? "Enter card number." : ""
																}
																value={cardNo}
																onChange={handleCardNoChange}
																disabled={!canEdit}
															/>
															<div className="asset-pw-actions flex-row-right">
																<Button
																	variant="light flex-column"
																	onClick={() =>
																		copyAssetItem(
																			null,
																			cardNo.replace(/\s/g, "")
																		)
																	}
																>
																	<i className="ri-file-copy-line opacity-7" />
																</Button>
															</div>
														</div>
													</Col>
												</Row>
												<Row className="form-block-sm">
													<Col>
														<Form.Label>Expiration*</Form.Label>
														<div className="flex-row-left full">
															<Form.Control
																type="text"
																className={`asset-card-info form-input input-lg text-sm rounded-4 ${
																	assetErrors.includes("cardExpiry") &&
																	"border-danger"
																}`}
																placeholder={
																	canEdit ? "Enter card expiration." : ""
																}
																value={cardExpiry}
																onChange={handleCardExpiryChange}
																disabled={!canEdit}
															/>
															<div className="asset-pw-actions flex-row-right">
																<Button
																	variant="light flex-column"
																	onClick={() =>
																		copyAssetItem(null, cardExpiry)
																	}
																>
																	<i className="ri-file-copy-line opacity-7" />
																</Button>
															</div>
														</div>
													</Col>
													<Col>
														<Form.Label>Security Code*</Form.Label>
														<div className="flex-row-left full">
															<Form.Control
																type="password"
																className={`asset-card-info form-input input-lg text-sm rounded-4 ${
																	assetErrors.includes("cardCode") &&
																	"border-danger"
																}`}
																placeholder={
																	canEdit ? "Enter security code." : ""
																}
																value={cardCode}
																onChange={(e) => setCardCode(e.target.value)}
																disabled={!canEdit}
															/>
															<div className="asset-pw-actions flex-row-right">
																<Button
																	variant="light flex-column"
																	onClick={() => copyAssetItem(null, cardCode)}
																>
																	<i className="ri-file-copy-line opacity-7" />
																</Button>
															</div>
														</div>
													</Col>
												</Row>
												<Row className="form-block-sm">
													<Col>
														<Form.Label>Name on Card*</Form.Label>
														<div className="flex-row-left full">
															<Form.Control
																type="text"
																className={`asset-card-info form-input input-lg text-sm rounded-4 ${
																	assetErrors.includes("cardName") &&
																	"border-danger"
																}`}
																placeholder={
																	canEdit ? "Enter name on card." : ""
																}
																value={cardName}
																onChange={(e) => setCardName(e.target.value)}
																disabled={!canEdit}
															/>
															<div className="asset-pw-actions flex-row-right">
																<Button
																	variant="light flex-column"
																	onClick={() => copyAssetItem(null, cardName)}
																>
																	<i className="ri-file-copy-line opacity-7" />
																</Button>
															</div>
														</div>
													</Col>
												</Row>
											</>
										)}
										{assetType === "Address" && (
											<>
												<Row className="form-block-sm">
													<Col>
														<Form.Label>Street Address*</Form.Label>
														<div className="flex-row-left full">
															<Form.Control
																type="text"
																className={`asset-address form-input input-lg text-sm rounded-4 ${
																	assetErrors.includes("street") &&
																	"border-danger"
																}`}
																placeholder={
																	canEdit ? "Enter street address." : ""
																}
																value={street}
																onChange={(e) => setStreet(e.target.value)}
																disabled={!canEdit}
															/>
															<div className="asset-pw-actions flex-row-right">
																<Button
																	variant="light flex-column"
																	onClick={() => copyAssetItem(null, street)}
																>
																	<i className="ri-file-copy-line opacity-7" />
																</Button>
															</div>
														</div>
													</Col>
												</Row>
												<Row className="form-block-sm">
													<Col>
														<Form.Label>City/Town*</Form.Label>
														<div className="flex-row-left full">
															<Form.Control
																type="text"
																className={`asset-address form-input input-lg text-sm rounded-4 ${
																	assetErrors.includes("city") &&
																	"border-danger"
																}`}
																placeholder={canEdit ? "Enter city/town." : ""}
																value={city}
																onChange={(e) => setCity(e.target.value)}
																disabled={!canEdit}
															/>
															<div className="asset-pw-actions flex-row-right">
																<Button
																	variant="light flex-column"
																	onClick={() => copyAssetItem(null, city)}
																>
																	<i className="ri-file-copy-line opacity-7" />
																</Button>
															</div>
														</div>
													</Col>
													<Col>
														<Form.Label>State/Province*</Form.Label>
														<div className="flex-row-left full">
															<Form.Control
																type="text"
																className={`asset-address form-input input-lg text-sm rounded-4 ${
																	assetErrors.includes("province") &&
																	"border-danger"
																}`}
																placeholder={
																	canEdit ? "Enter state/province." : ""
																}
																value={province}
																onChange={(e) => setProvince(e.target.value)}
																disabled={!canEdit}
															/>
															<div className="asset-pw-actions flex-row-right">
																<Button
																	variant="light flex-column"
																	onClick={() => copyAssetItem(null, province)}
																>
																	<i className="ri-file-copy-line opacity-7" />
																</Button>
															</div>
														</div>
													</Col>
												</Row>
												<Row className="form-block-sm">
													<Col>
														<Form.Label>Country*</Form.Label>
														<div className="flex-row-left full">
															<Form.Control
																type="text"
																className={`asset-address form-input input-lg text-sm rounded-4 ${
																	assetErrors.includes("country") &&
																	"border-danger"
																}`}
																placeholder={canEdit ? "Enter country." : ""}
																value={country}
																onChange={(e) => setCountry(e.target.value)}
																disabled={!canEdit}
															/>
															<div className="asset-pw-actions flex-row-right">
																<Button
																	variant="light flex-column"
																	onClick={() => copyAssetItem(null, country)}
																>
																	<i className="ri-file-copy-line opacity-7" />
																</Button>
															</div>
														</div>
													</Col>
													<Col>
														<Form.Label>Postal Code*</Form.Label>
														<div className="flex-row-left full">
															<Form.Control
																type="text"
																className={`asset-address form-input input-lg text-sm rounded-4 ${
																	assetErrors.includes("postalCode") &&
																	"border-danger"
																}`}
																placeholder={
																	canEdit ? "Enter postal code." : ""
																}
																value={postalCode}
																onChange={(e) => setPostalCode(e.target.value)}
																disabled={!canEdit}
															/>
															<div className="asset-pw-actions flex-row-right">
																<Button
																	variant="light flex-column"
																	onClick={() =>
																		copyAssetItem(null, postalCode)
																	}
																>
																	<i className="ri-file-copy-line opacity-7" />
																</Button>
															</div>
														</div>
													</Col>
												</Row>
											</>
										)}
										{assetType === "API" && (
											<>
												<Row className="form-block-sm">
													<Col>
														<Form.Label>API Key*</Form.Label>
														<div className="flex-row-left full">
															<Form.Control
																type="text"
																className={`asset-card-info form-input input-lg text-sm rounded-4 ${
																	assetErrors.includes("apiKey") &&
																	"border-danger"
																}`}
																placeholder={canEdit ? "Enter API key." : ""}
																value={apiKey}
																onChange={(e) => setApiKey(e.target.value)}
																disabled={!canEdit}
															/>
															<div className="asset-pw-actions flex-row-right">
																<Button
																	variant="light flex-column"
																	onClick={() => copyAssetItem(null, apiKey)}
																>
																	<i className="ri-file-copy-line opacity-7" />
																</Button>
															</div>
														</div>
													</Col>
												</Row>
												<Row className="form-block-sm">
													<Col>
														<Form.Label>
															Secret{" "}
															<span className="opacity-5 text-sm">
																(Optional)
															</span>
														</Form.Label>
														<div className="flex-row-left full">
															<Form.Control
																type="text"
																className={`asset-card-info form-input input-lg text-sm rounded-4 ${
																	assetErrors.includes("apiSecret") &&
																	"border-danger"
																}`}
																placeholder={canEdit ? "Enter secret key." : ""}
																value={apiSecret}
																onChange={(e) => setApiSecret(e.target.value)}
																disabled={!canEdit}
															/>
															<div className="asset-pw-actions flex-row-right">
																<Button
																	variant="light flex-column"
																	onClick={() => copyAssetItem(null, apiSecret)}
																>
																	<i className="ri-file-copy-line opacity-7" />
																</Button>
															</div>
														</div>
													</Col>
												</Row>
												<Row className="form-block-sm">
													<Col>
														<Form.Label>
															Documentation{" "}
															<span className="opacity-5 text-sm">
																(Optional)
															</span>
														</Form.Label>
														<div className="flex-row-left full">
															<Form.Control
																type="text"
																className={`form-input input-lg text-sm rounded-4 ${
																	assetErrors.includes("docs") &&
																	"border-danger"
																}`}
																placeholder={
																	canEdit ? "Enter documentation URL." : ""
																}
																value={docs}
																onChange={(e) => setDocs(e.target.value)}
																disabled={!canEdit}
															/>
														</div>
													</Col>
												</Row>
											</>
										)}
										{assetType === "Contact" && (
											<>
												<Row className="form-block-sm">
													<Col>
														<Form.Label>Contact Name*</Form.Label>
														<div className="flex-row-left full">
															<Form.Control
																type="text"
																className={`asset-card-info form-input input-lg text-sm rounded-4 ${
																	assetErrors.includes("contactName") &&
																	"border-danger"
																}`}
																placeholder={
																	canEdit ? "Enter contact name." : ""
																}
																value={contactName}
																onChange={(e) => setContactName(e.target.value)}
																disabled={!canEdit}
															/>
														</div>
													</Col>
												</Row>
												<Row className="form-block-sm">
													<Col>
														<Form.Label>
															Email{" "}
															<span className="opacity-5 text-sm">
																(Optional)
															</span>
														</Form.Label>
														<div className="flex-row-left full">
															<Form.Control
																type="email"
																className={`asset-card-info form-input input-lg text-sm rounded-4 ${
																	assetErrors.includes("contactEmail") &&
																	"border-danger"
																}`}
																placeholder={
																	canEdit ? "Enter contact email." : ""
																}
																value={contactEmail}
																onChange={(e) =>
																	setContactEmail(e.target.value)
																}
																disabled={!canEdit}
															/>
														</div>
													</Col>
													<Col>
														<Form.Label>
															Contact Number{" "}
															<span className="opacity-5 text-sm">
																(Optional)
															</span>
														</Form.Label>
														<div className="flex-row-left full">
															<Form.Control
																type="text"
																className={`asset-card-info form-input input-lg text-sm rounded-4 ${
																	assetErrors.includes("contactMsisdn") &&
																	"border-danger"
																}`}
																placeholder={
																	canEdit ? "Enter contact number." : ""
																}
																value={contactMsisdn}
																onChange={(e) =>
																	setContactMsisdn(e.target.value)
																}
																disabled={!canEdit}
															/>
														</div>
													</Col>
												</Row>
											</>
										)}
										{assetType === "Wifi" && (
											<>
												<Row className="form-block-sm">
													<Col>
														<Form.Label>SSID (Network Name)*</Form.Label>
														<div className="flex-row-left full">
															<Form.Control
																type="text"
																className={`asset-card-info form-input input-lg text-sm rounded-4 ${
																	assetErrors.includes("wifiName") &&
																	"border-danger"
																}`}
																placeholder="Enter contact number."
																value={wifiName}
																onChange={(e) => setWifiName(e.target.value)}
															/>
														</div>
													</Col>
												</Row>
												<Row className="form-block-sm">
													<Col>
														<Form.Label>Password*</Form.Label>
														<div className="flex-row-left full">
															<Form.Control
																type={showWifiPassword ? "text" : "password"}
																className={`asset-password form-input input-lg text-sm rounded-4 ${
																	assetErrors.includes("wifiPassword") &&
																	"border-danger"
																}`}
																placeholder="Enter wifi password."
																value={wifiPassword}
																onChange={(e) =>
																	setWifiPassword(e.target.value)
																}
															/>
															<div className="asset-pw-actions flex-row-right">
																<Button
																	variant="light flex-column"
																	onClick={() =>
																		setShowWifiPassword(!showWifiPassword)
																	}
																>
																	<i
																		className={`opacity-7 ${
																			showWifiPassword
																				? "ri-eye-off-line"
																				: "ri-eye-line"
																		}`}
																	/>
																</Button>
															</div>
														</div>
													</Col>
												</Row>
												<Row className="form-block-sm">
													<Col>
														<Form.Label>Encryption Type*</Form.Label>
														<div className="flex-row-left full">
															<Form.Select
																className={`asset-card-info form-input input-lg text-sm rounded-4 ${
																	assetErrors.includes("wifiEncryption") &&
																	"border-danger"
																}`}
																placeholder="Enter contact number."
																value={wifiEncryption}
																onChange={(e) =>
																	setWifiEncryption(e.target.value)
																}
															>
																<option value="WPA2">WPA2</option>
																<option value="WPA">WPA</option>
																<option value="nopass">No Password</option>
															</Form.Select>
														</div>
													</Col>
												</Row>
												<Row className="form-block-sm">
													<Col>
														<Form.Label>Hidden Network*</Form.Label>
														<div className="flex-row-left full">
															<Form.Select
																className={`asset-card-info form-input input-lg text-sm rounded-4 ${
																	assetErrors.includes("wifiIsHidden") &&
																	"border-danger"
																}`}
																placeholder="Enter contact number."
																value={wifiIsHidden}
																onChange={(e) =>
																	setWifiIsHidden(e.target.value)
																}
															>
																<option value={false}>No</option>
																<option value={true}>Yes</option>
															</Form.Select>
														</div>
													</Col>
												</Row>
											</>
										)}
									</>
								)}
								{assetTab === "sharing" && (
									<>
										<div className="mb-30">
											<AsyncSelect
												isClearable
												isMulti
												inputValue={shareInput}
												onInputChange={setShareInput}
												noOptionsMessage={() =>
													shareInput ? "No results" : "Search safe members"
												}
												loadOptions={loadShareOptions}
												defaultOptions={[]}
												onChange={setShareTo}
												menuShouldScrollIntoView={false}
												maxMenuHeight={200}
												placeholder="Search safe members"
												styles={{
													control: (baseStyles, state) => ({
														...baseStyles,
														boxShadow: "none",
														outline: "none",
														borderRadius: 4,
														fontSize: "0.875rem",
													}),
													option: (baseStyles, state) => ({
														...baseStyles,
														fontSize: ".875rem",
													}),
												}}
											/>
										</div>
										{shareTo?.length !== 0 ? (
											<div className="border p-10 rounded">
												<Row className="mb-20">
													<Col className="col-lg-3 col-6">
														<span className="text-sm text-medium-inter">
															Allow Editing:
														</span>
													</Col>
													<Col className="col-lg-9 col-6">
														<input
															type="checkbox"
															defaultChecked={shareAllowEdit}
															onChange={(e) =>
																setShareAllowEdit(e.target.checked)
															}
															className="cbx asset-cbx hidden"
															id="allowEditing"
														/>
														<label
															htmlFor="allowEditing"
															className="lbl asset-lbl"
														></label>
													</Col>
												</Row>
												<Row className="mb-10">
													<Col className="col-lg-3 col-6">
														<span className="text-sm text-medium-inter">
															Expiration:
														</span>
													</Col>
													<Col className="col-lg-9 col-6">
														<Form.Select
															size="sm"
															value={shareExpiration}
															onChange={(e) =>
																setShareExpiration(e.target.value)
															}
														>
															<option value="">None</option>
															<option value="15">15 Minutes</option>
															<option value="30">30 Minutes</option>
															<option value="60">1 Hour</option>
															<option value="24">1 Day</option>
														</Form.Select>
													</Col>
												</Row>
											</div>
										) : (
											<>
												<span className="text-medium-inter">
													People with access
												</span>
												<div>
													<div className="asset-owner">
														<Row className="flex-row-left">
															<Col className="col-lg-8 col-12 flex-row-left">
																<div
																	className="asset-member-icon flex-column"
																	style={{
																		background: assetInfo?.user.photo
																			? `url(${assetInfo?.user.photo})`
																			: "#3c57cc",
																	}}
																>
																	{!assetInfo?.user.photo && (
																		<span className="text-medium-inter text-white text-sm upper">
																			{assetInfo?.user.username
																				? assetInfo?.user.username[0]
																				: assetInfo?.user.email[0]}
																		</span>
																	)}
																</div>
																{assetInfo?.user.username ? (
																	<div className="member-details-container">
																		<span className="text-sm ellipsis me-1">
																			{assetInfo?.user.username}
																		</span>
																		<span className="label-md opacity-5 block ellipsis">
																			{assetInfo?.user.email}
																		</span>
																	</div>
																) : (
																	<div className="member-details-container">
																		<span className="text-sm ellipsis">
																			{assetInfo?.user.email}
																		</span>
																	</div>
																)}
															</Col>
															<Col className="col-lg-4 col-12 member-action flex-row-right">
																<span className="text-sm opacity-5">Owner</span>
															</Col>
														</Row>
													</div>
													<div>
														{assetInfo?.shared_to?.map((member) => (
															<AssetMember
																key={member.id}
																member={member}
																asset={assetInfo}
																user={user}
															/>
														))}
													</div>
												</div>
												<span className="text-medium-inter mt-4 block mb-10">
													One-time links
												</span>
												{assetLinks?.map((link) => (
													<div
														key={link.id}
														className="asset-link bg-light rounded-4 p-10 flex-row-left mb-15 border"
													>
														<span
															className={`asset-link-status text-white label-md ${link.status}`}
														>
															{link.status}
														</span>
														<span className="text-sm ellipsis">
															{link.path}
														</span>
														<div className="asset-link-action flex-row-right">
															<Button
																className="asset-link-copy flex-row-center text-medium-inter"
																onClick={() => copyAssetLink(link.path)}
															>
																<i className="ri-file-copy-line me-1 text-sm" />
																<span className="label-md">Copy</span>
															</Button>
														</div>
													</div>
												))}
												<Button
													className="btn-default btn-sm flex-row-center rounded-4"
													disabled={linkLoading}
													onClick={generateAssetLink}
												>
													<i className="ri-link me-1 text-sm" />
													<span className="label-md">
														{linkLoading ? "Generating.." : "Generate"}
													</span>
												</Button>
											</>
										)}
									</>
								)}
								{assetTab === "activity" &&
									assetLogs?.map((log) => (
										<div
											key={log.id}
											className="asset-activity-container bg-white border-bottom"
										>
											<div
												className="asset-activity-item flex-row-left p-10 pointer"
												onClick={() =>
													setActiveAssetLog(
														activeAssetLog === log.id ? null : log.id
													)
												}
											>
												<div>
													<p className="activity-log-content text-sm mb-1">
														{log.description}
													</p>
													<div className="flex-row-left">
														<span className="label-md opacity-7">
															{format(
																new Date(log.created_at),
																"MMM dd, yyyy - hh:mm a"
															)}
														</span>
													</div>
												</div>
												<i
													className={`activity-dropdown ri-arrow-down-s-line text-xl ${
														activeAssetLog === log.id && "active"
													}`}
												/>
											</div>
											<div
												className={`activity-body p-10 mb-15 ${
													activeAssetLog === log.id && "active"
												}`}
											>
												<div className="rounded border p-10">
													{log.device_flag && (
														<div className="activity-warning-container rounded-4 flex-row-left mb-15 ">
															<i className="ri-information-line text-lg me-1" />
															<p className="text-sm mb-0">{log.device_flag}</p>
														</div>
													)}
													{log.loc_flag && (
														<div className="activity-warning-container rounded-4 flex-row-left mb-15 ">
															<i className="ri-information-line text-lg me-1" />
															<p className="text-sm mb-0">{log.loc_flag}</p>
														</div>
													)}
													<div className="activity-info mb-1">
														<span className="text-medium-inter text-sm">
															Host:{" "}
														</span>
														<span className="text-light-inter text-sm">
															{log.origin_host}
														</span>
													</div>
													<div className="activity-info mb-1">
														<span className="text-medium-inter text-sm">
															Browser:{" "}
														</span>
														<span className="text-light-inter text-sm">
															{log.browser}
														</span>
													</div>
													<div className="activity-info mb-1">
														<span className="text-medium-inter text-sm">
															Operating System:{" "}
														</span>
														<span className="text-light-inter text-sm">
															{log.operating_system}
														</span>
													</div>
													<div className="activity-info mb-1">
														<span className="text-medium-inter text-sm">
															Device:{" "}
														</span>
														<span className="text-light-inter text-sm">
															{log.device_brand} {log.device_model}
														</span>
													</div>
													<div className="activity-info">
														<span className="text-medium-inter text-sm">
															Location:{" "}
														</span>
														<span className="text-light-inter text-sm">
															{log.origin_city && log.origin_city + ", "}
															{log.origin_country}
														</span>
													</div>
												</div>
											</div>
										</div>
									))}
							</>
						)}
					</Modal.Body>
					<Modal.Footer>
						<Button
							variant="light"
							className="modal-btn btn-md dismiss-btn text-medium-inter text-sm"
							onClick={closeAssetModal}
						>
							Close
						</Button>
						{canEdit &&
							(assetTab === "details" ? (
								<Button
									type="submit"
									className="modal-btn btn-md btn-default text-medium-inter text-sm"
									disabled={actionLoading}
								>
									{actionLoading ? "Saving.." : "Save Changes"}
								</Button>
							) : shareTo?.length !== 0 ? (
								<Button
									type="button"
									className="modal-btn btn-md btn-default text-medium-inter text-sm"
									onClick={shareAsset}
									disabled={actionLoading}
								>
									{actionLoading ? "Sharing.." : "Share"}
								</Button>
							) : (
								""
							))}
					</Modal.Footer>
				</Form>
			</Modal>
			<Modal
				show={deleteAssetId}
				onHide={() => setDeleteAssetId(null)}
				centered
			>
				<Modal.Body>
					<h2 className="text-lg text-medium-inter">Delete Asset</h2>
					<p className="text-sm opacity-5 mb-0">
						Are you sure you want to delete this asset?
					</p>
				</Modal.Body>
				<Modal.Footer>
					<Button
						variant="light"
						className="modal-btn btn-sm dismiss-btn text-medium-inter text-sm"
						onClick={() => setDeleteAssetId(null)}
					>
						Cancel
					</Button>
					<Button
						variant="danger"
						className="modal-btn btn-sm text-medium-inter text-sm"
						disabled={actionLoading}
						onClick={deleteAsset}
					>
						{actionLoading ? "Deleting.." : "Delete"}
					</Button>
				</Modal.Footer>
			</Modal>
		</>
	);
};

export default Dashboard;
