import { useEffect, useState } from "react";

const Order = ({ item, orders, setOrders, refresh, user }) => {
	const [order, setOrder] = useState(item);

	const getData = async () => {
		const requestOptions = {
			method: "GET",
			headers: {
				"X-Auth-Token": user ? user.session_token : "",
			},
		};

		const response = await fetch(
			`${process.env.REACT_APP_API_URI}/users/orders/${order.id}`,
			requestOptions
		);
		const data = await response.json();

		if (!response.ok) {
			console.log("Couldn't fetch order.");
		} else {
			setOrder(data.order);
		}
	};

	const cancelOrder = () => {
		setOrders(orders.filter((order_item) => order_item.id !== order.id));
	};

	useEffect(() => {
		let intervalId;
		if (order) {
			intervalId = setInterval(() => {
				getData().then(() => {
					if (
						order.status !== "Success" &&
						order.status !== "Failed" &&
						order.status !== "Cancelled"
					) {
						getData();
					} else {
						clearInterval(intervalId);
						if (order.status === "Success") {
							refresh();
						}
					}
				});
			}, 1000);

			return () => {
				if (intervalId) {
					clearInterval(intervalId);
				}
			};
		}
	}, [order]);

	return (
		<div
			className={`order rounded border p-20 ${order?.status} ${
				order?.status !== "Failed" && "active"
			}`}
		>
			<span className="text-sm">{order?.display_status}</span>
			<div className={`order-loader ${order?.status}`} />
		</div>
	);
};

export default Order;
