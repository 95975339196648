import React, { useContext } from "react";
import { Navigate } from "react-router-dom";
import { UserContext } from "../context/UserContext";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import PageHeader from "../components/PageHeader";
import VerifiedIcon from "../components/VerifiedIcon";
import defaultUser from "../assets/default_user.png";

// TEMPORARY
import facebook from "../assets/socials/facebook.png";
import google from "../assets/socials/google.png";
import tiktok from "../assets/socials/tiktok.png";
import canada from "../assets/canada.png";
import polynesia from "../assets/polynesia.png";
import czech_republic from "../assets/czech_republic.png";
import australia from "../assets/australia.png";
import belgium from "../assets/belgium.png";

const Archive = () => {
	const [user] = useContext(UserContext);
	return (
		<>
			<div className="content full-page">
				<PageHeader user={user} />
				<Container>
					<h1 className="page-content-title text-lg text-medium-inter">
						Archive <span className="opacity-3 text-regular-inter">(100)</span>
					</h1>
					<Row>
						<Col className="page-content col-lg-8 col-12">
							<div className="page-content-header flex-row-left">
								<Form.Select className="numbers-country-picker border fit text-sm rounded-4">
									<option value="">Country Code</option>
									<option value="PH">PH (+63)</option>
									<option value="PH">PH (+63)</option>
									<option value="PH">PH (+63)</option>
								</Form.Select>
							</div>
							<div className="page-content-body">
								<div className="dashboard-item rounded-4 bg-white full unread">
									<div className="dashboard-item-info flex-row-left">
										<div
											style={{
												background: `url(${facebook})`,
											}}
											className="dashboard-item-icon"
										/>
										<div className="dashboard-item-details">
											<div className="flex-row-left">
												<span className="dashboard-item-sender text-sm">
													Facebook
												</span>
												<VerifiedIcon />
											</div>
											<div className="dashboard-item-content text-sm">
												<p className="m-0">
													Use{" "}
													<span className="text-blue text-bold-inter">
														555555
													</span>{" "}
													as your login code for Facebook.
												</p>
											</div>
										</div>
										{/* <div className="dashboard-item-control flex-row-right">
											<Button className="flex-column">
												<i className="ri-file-copy-line text-sm" />
											</Button>
											<Button className="flex-column">
												<i className="ri-share-forward-line text-sm" />
											</Button>
											<Button className="flex-column">
												<i className="ri-inbox-archive-line text-sm" />
											</Button>
										</div> */}
									</div>
									<div className="separator" />
									<div className="dashboard-item-meta flex-row-left">
										<div className="flex-row-left col-6">
											<span className="label-sm text-bold-inter opacity-5 upper">
												Sent to
											</span>
											<img
												src={polynesia}
												alt="canada"
												className="dashboard-item-country"
											/>
											<span className="label-md text-regular-inter opacity-7">
												+1 561 438 6589
											</span>
										</div>
										<div className="flex-row-right col-6">
											<span className="label-sm text-bold-inter opacity-5">
												Today
											</span>
											<span className="label-sm opacity-5">, 19:32am</span>
										</div>
									</div>
								</div>

								<div className="dashboard-item bg-white full unread">
									<div className="dashboard-item-info flex-row-left">
										<div
											style={{
												background: `url(${google})`,
											}}
											className="dashboard-item-icon"
										/>
										<div className="dashboard-item-details">
											<div className="flex-row-left">
												<span className="dashboard-item-sender text-sm">
													Google
												</span>
												<VerifiedIcon />
											</div>
											<div className="dashboard-item-content text-sm">
												<p className="m-0">
													Use{" "}
													<span className="text-blue text-bold-inter">
														555555
													</span>{" "}
													as your login code for Google.
												</p>
											</div>
										</div>
										{/* <div className="dashboard-item-control flex-row-right">
											<Button className="flex-column">
												<i className="ri-file-copy-line text-sm" />
											</Button>
											<Button className="flex-column">
												<i className="ri-share-forward-line text-sm" />
											</Button>
											<Button className="flex-column">
												<i className="ri-inbox-archive-line text-sm" />
											</Button>
										</div> */}
									</div>
									<div className="separator" />
									<div className="dashboard-item-meta flex-row-left">
										<div className="flex-row-left col-6">
											<span className="label-sm text-bold-inter opacity-5 upper">
												Sent to
											</span>
											<img
												src={polynesia}
												alt="canada"
												className="dashboard-item-country"
											/>
											<span className="label-md text-regular-inter opacity-7">
												+1 561 438 6589
											</span>
										</div>
										<div className="flex-row-right col-6">
											<span className="label-sm text-bold-inter opacity-5">
												Today
											</span>
											<span className="label-sm opacity-5">, 19:32am</span>
										</div>
									</div>
								</div>

								<div className="dashboard-item bg-white full">
									<div className="dashboard-item-info flex-row-left">
										<div
											style={{
												background: `url(${tiktok})`,
											}}
											className="dashboard-item-icon"
										/>
										<div className="dashboard-item-details">
											<div className="flex-row-left">
												<span className="dashboard-item-sender text-sm">
													Tiktok
												</span>
												<VerifiedIcon />
											</div>
											<div className="dashboard-item-content text-sm">
												<p className="m-0">
													Use{" "}
													<span className="text-blue text-bold-inter">
														555555
													</span>{" "}
													as your login code for Tiktok.
												</p>
											</div>
										</div>
										{/* <div className="dashboard-item-control flex-row-right">
											<Button className="flex-column">
												<i className="ri-file-copy-line text-sm" />
											</Button>
											<Button className="flex-column">
												<i className="ri-share-forward-line text-sm" />
											</Button>
											<Button className="flex-column">
												<i className="ri-inbox-archive-line text-sm" />
											</Button>
										</div> */}
									</div>
									<div className="separator" />
									<div className="dashboard-item-meta flex-row-left">
										<div className="flex-row-left col-6">
											<span className="label-sm text-bold-inter opacity-5 upper">
												Sent to
											</span>
											<img
												src={czech_republic}
												alt="canada"
												className="dashboard-item-country"
											/>
											<span className="label-md text-regular-inter opacity-7">
												+420 601 945 911
											</span>
										</div>
										<div className="flex-row-right col-6">
											<span className="label-sm text-bold-inter opacity-5">
												Today
											</span>
											<span className="label-sm opacity-5">, 19:32am</span>
										</div>
									</div>
								</div>
							</div>
						</Col>
						<Col className="page-sub-content col-lg-4 col-12"></Col>
					</Row>
				</Container>
			</div>
		</>
	);
};

export default Archive;
