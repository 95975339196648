import React, { useState, useEffect } from "react";

const AssetTimer = ({ expiresAt, getData }) => {
	const calculateTimeLeft = () => {
		const difference = +new Date(expiresAt) - +new Date();
		let timeLeft = {};

		if (difference > 0) {
			timeLeft = {
				days: Math.floor(difference / (1000 * 60 * 60 * 24)),
				hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
				minutes: Math.floor((difference / 1000 / 60) % 60),
				seconds: Math.floor((difference / 1000) % 60),
			};
		}

		return timeLeft;
	};

	const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

	useEffect(() => {
		const timer = setInterval(() => {
			const newTimeLeft = calculateTimeLeft();
			setTimeLeft(newTimeLeft);

			if (Object.keys(newTimeLeft).length === 0) {
				clearInterval(timer);
				getData();
			}
		}, 1000);

		return () => clearInterval(timer);
	}, [expiresAt]);

	return (
		<div>
			{Object.keys(timeLeft).length > 0 ? (
				<div className="flex-row-left mb-20">
					<span
						className={timeLeft.minutes > 5 ? "text-success" : "text-danger"}
					>
						<span className="text-medium-inter">Time left:</span>{" "}
						{timeLeft.minutes}m {timeLeft.seconds}s
					</span>
				</div>
			) : (
				<div>Access has expired</div>
			)}
		</div>
	);
};

export default AssetTimer;
