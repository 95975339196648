import { useState, useEffect, useContext } from "react";
import { NotifContext } from "../context/NotifContext";
import { UserContext } from "../context/UserContext";
import { useSnackbar } from "react-simple-snackbar";
import { format } from "date-fns";
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import PageLoader from "../components/PageLoader";
import EmptyPage from "../components/EmptyPage";

const Notifications = () => {
	const [user] = useContext(UserContext);
	const [, setNotifCount] = useContext(NotifContext);
	const [notifications, setNotifications] = useState([]);
	const [loading, setLoading] = useState(true);
	const [defaultSnackbar] = useSnackbar();

	const getMessageAge = (created) => {
		let timeString;
		const now = new Date();
		const createdAt = new Date(created);
		const localCreatedAt = new Date(
			createdAt.getTime() - createdAt.getTimezoneOffset() * 60000
		);
		const diff = now - localCreatedAt;
		const diffInSeconds = Math.round(diff / 1000);
		const diffInMinutes = Math.round(diff / (1000 * 60));
		const diffInHours = Math.round(diff / (1000 * 60 * 60));

		if (diff < 86400000) {
			if (diffInSeconds < 60) {
				timeString = `${diffInSeconds} ${
					diffInSeconds === 1 ? "second" : "seconds"
				} ago`;
			} else if (diffInMinutes < 60) {
				timeString = `${diffInMinutes} ${
					diffInMinutes === 1 ? "minute" : "minutes"
				} ago`;
			} else {
				timeString = `${diffInHours} ${
					diffInHours === 1 ? "hour" : "hours"
				} ago`;
			}
		} else if (diff < 172800000) {
			timeString = "Yesterday - " + format(new Date(created), "hh:mm a");
		} else if (diff < 31536000000) {
			timeString = format(new Date(created), "MMM dd - hh:mm a");
		} else {
			timeString = format(new Date(created), "MMM dd, yyyy - hh:mm a");
		}
		return timeString;
	};

	const getData = async () => {
		setLoading(true);
		const requestOptions = {
			method: "GET",
			headers: {
				"X-Auth-Token": user ? user.session_token : "",
			},
		};

		const response = await fetch(
			`${process.env.REACT_APP_API_URI}/users/notifications`,
			requestOptions
		);
		const data = await response.json();

		if (!response.ok) {
			console.log("Couldn't fetch notifications.");
		} else {
			setNotifications(data.data);
			setNotifCount(0);
		}
		setLoading(false);
	};

	const copyMessage = async (message) => {
		const otpPattern = /\b\d{6}\b/;
		const otpMatch = message.match(otpPattern);
		const textToCopy = otpMatch ? otpMatch[0] : message;
		await navigator.clipboard.writeText(textToCopy);
		defaultSnackbar("Copied to clipboard.");
	};

	useEffect(() => {
		document.title = "Notifications | SMSafe";
		getData();
	}, []);

	return (
		<div className="content full-page">
			{loading ? (
				<PageLoader />
			) : (
				<Container>
					<div className="pt-4">
						<h1 className="page-content-title mb-20 title-md text-medium-inter">
							Notifications
						</h1>
					</div>
					{notifications.length === 0 ? (
						<EmptyPage label="You don't have any notifications." />
					) : (
						<div>
							{notifications.map((notif) => (
								<div
									key={notif.id}
									className="dashboard-item bg-white p-20 mb-10 rounded-4"
								>
									<Row className="flex-row-left full">
										<Col className="col-md-8 col-12">
											<span className="text-medium-inter text-sm block mb-1">
												{notif.title}
											</span>
											<p className="text-sm mb-1">{notif.body}</p>
											<span className="label-md">
												{getMessageAge(notif.created_at)}
											</span>
										</Col>
										<Col className="notification-item-control flex-row-right col-md-4 col-12">
											{notif.title.includes("Message from") && (
												<Button
													className="flex-column"
													onClick={() => copyMessage(notif.body)}
												>
													<i className="ri-file-copy-line text-sm" />
												</Button>
											)}
											<Button href={notif.link} className="flex-column">
												View
											</Button>
										</Col>
									</Row>
								</div>
							))}
						</div>
					)}
				</Container>
			)}
		</div>
	);
};

export default Notifications;
