import React, { useState, useContext } from "react";
import { Outlet, NavLink, useNavigate, Link } from "react-router-dom";
import { NotifContext } from "../context/NotifContext";
import { useSnackbar } from "react-simple-snackbar";
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import LogoDark from "../components/LogoDark";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Dropdown from "react-bootstrap/Dropdown";
import Modal from "react-bootstrap/Modal";
import Notification from "../components/Notification";

const Layout = ({ user }) => {
	const navigate = useNavigate();
	const [notifCount] = useContext(NotifContext);
	const [safeName, setSafeName] = useState("");
	const [groupModal, setGroupModal] = useState(false);
	const [actionLoading, setActionLoading] = useState(false);
	const [showNav, setShowNav] = useState(false);
	const [subscribeModal, setSubscribeModal] = useState(false);
	const [subscribeError, setSubscribeError] = useState("");

	const [defaultSnackbar] = useSnackbar();

	const [dangerSnackbar] = useSnackbar({
		style: {
			zIndex: 10,
			backgroundColor: "#d81e5b",
			color: "#fff",
		},
	});

	const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
		<a
			href=""
			ref={ref}
			onClick={(e) => {
				e.preventDefault();
				onClick(e);
			}}
			className="header-user-dropdown text-sm flex-row-left"
		>
			{children}
			<i className="ri-arrow-drop-down-line header-user-icon" />
		</a>
	));

	const notifToggle = React.forwardRef(({ children, onClick }, ref) => (
		<a
			href=""
			ref={ref}
			onClick={(e) => {
				e.preventDefault();
				onClick(e);
			}}
		>
			{children}
		</a>
	));

	const addGroup = async (e) => {
		e.preventDefault();
		setActionLoading(true);
		const requestOptions = {
			method: "POST",
			headers: {
				"X-Auth-Token": user ? user.session_token : "",
			},
			body: JSON.stringify({
				name: safeName,
			}),
		};

		const response = await fetch(
			`${process.env.REACT_APP_API_URI}/users/groups`,
			requestOptions
		);
		const data = await response.json();

		if (!response.ok) {
			if (data.error === "subscription") {
				setSafeName("");
				setGroupModal(false);
				setSubscribeError(data.msg);
				setSubscribeModal(true);
			} else {
				dangerSnackbar(data.msg);
			}
		} else {
			setSafeName("");
			setGroupModal(false);
			defaultSnackbar(data.msg);
			navigate(`/safes/${data.group_id}`);
		}
		setActionLoading(false);
	};

	// const search = (e) => {
	// 	e.preventDefault();
	// 	if (query) {
	// 		navigate(`/search?q=${query}`);
	// 	} else {
	// 		navigate("/dashboard");
	// 	}
	// };

	return (
		<>
			<div className="page-header bg-white flex-row-left full">
				<Notification />
				<Container>
					<Row className="flex-row-left">
						<Col className="flex-row-left">
							<Link to="/" className="flex-row-left">
								<LogoDark className="nav-logo" />
								<span className="text-medium-inter beta label-md">BETA</span>
							</Link>
							<div className="nav-lg content-lg">
								<NavLink to="/dashboard" className="main-nav-item">
									<span className="text-sm">Dashboard</span>
								</NavLink>
								<NavLink to="/safes" className="main-nav-item">
									<span className="text-sm">Safes</span>
								</NavLink>
								{/* <NavLink to="/archive" className="main-nav-item">
									<span className="text-sm">Archive</span>
								</NavLink> */}
							</div>
						</Col>
						<Col className="nav-action-container p-0">
							<div className="nav-lg content-lg flex-row-right">
								<Button
									className="header-safe-btn btn-sm btn-default text-sm"
									onClick={() => setGroupModal(true)}
								>
									Create Safe
								</Button>
								{/* <Dropdown align="end"> */}
								{/* <Dropdown.Toggle as={notifToggle}> */}
								<Link to="/notifications">
									<Button
										variant="light"
										className="notifications-btn flex-column"
									>
										<i className="ri-notification-3-line opacity-7" />
										{notifCount ? (
											<span className="notif-counter bg-danger flex-column">
												{notifCount > 9 ? "9+" : notifCount}
											</span>
										) : (
											""
										)}
									</Button>
								</Link>
								{/* </Dropdown.Toggle>
									<Dropdown.Menu className="notif-dropdown dropdown-default">
										<Dropdown.Item
											href="/settings"
											className="notif-item border-bottom unread"
										>
											<div className="flex-row-left mb-1">
												<div className="notif-unread bg-danger" />
												<span className="text-sm text-medium-inter">
													New Message
												</span>
											</div>
											<span className="text-sm ellipsis mb-10">
												This is a sample notification
											</span>
											<span className="label-md">Dec 29 | 03:31 PM</span>
										</Dropdown.Item>
										<Dropdown.Item
											href="/settings"
											className="notif-item border-bottom"
										>
											<div className="flex-row-left mb-1">
												<span className="text-sm text-medium-inter">
													New Message
												</span>
											</div>
											<span className="text-sm ellipsis mb-10">
												This is a sample notification aslcha cioash
												coiahsoichaoschoashc
											</span>
											<span className="label-md">Dec 27 | 12:27 PM</span>
										</Dropdown.Item>
										<Dropdown.Item
											href="/settings"
											className="notif-item border-bottom"
										>
											<div className="flex-row-left mb-1">
												<span className="text-sm text-medium-inter">
													New Message
												</span>
											</div>
											<span className="text-sm ellipsis mb-10">
												This is a sample notification aslcha cioash
												coiahsoichaoschoashc
											</span>
											<span className="label-md">Dec 27 | 12:27 PM</span>
										</Dropdown.Item>
										<Dropdown.Item
											href="/settings"
											className="notif-item border-bottom"
										>
											<div className="flex-row-left mb-1">
												<span className="text-sm text-medium-inter">
													New Message
												</span>
											</div>
											<span className="text-sm ellipsis mb-10">
												This is a sample notification aslcha cioash
												coiahsoichaoschoashc
											</span>
											<span className="label-md">Dec 27 | 12:27 PM</span>
										</Dropdown.Item>
										<Dropdown.Item
											href="/settings"
											className="notif-item border-bottom"
										>
											<div className="flex-row-left mb-1">
												<span className="text-sm text-medium-inter">
													New Message
												</span>
											</div>
											<span className="text-sm ellipsis mb-10">
												This is a sample notification aslcha cioash
												coiahsoichaoschoashc
											</span>
											<span className="label-md">Dec 27 | 12:27 PM</span>
										</Dropdown.Item>
										<Link
											to="/notifications"
											className="flex-column p-10 text-sm text-blue text-medium-inter"
										>
											See All
										</Link>
									</Dropdown.Menu>
								</Dropdown> */}
								<div className="header-separator opacity-1" />
								<Dropdown align="end">
									<Dropdown.Toggle as={CustomToggle}>
										{user?.username}
										<div
											className="header-user-img flex-column"
											style={{
												background: user?.photo
													? `url(${user.photo})`
													: "#0d163f",
											}}
										>
											{!user?.photo && (
												<span
													href="#"
													className="text-medium-inter text-white text-sm upper"
												>
													{user?.username[0]}
												</span>
											)}
										</div>
									</Dropdown.Toggle>
									<Dropdown.Menu className="account-dropdown dropdown-default">
										<Dropdown.Item href="/settings" className="text-sm">
											Account Settings
										</Dropdown.Item>
										<Dropdown.Divider />
										<Dropdown.Item href="/logout" className="text-sm">
											Logout
										</Dropdown.Item>
									</Dropdown.Menu>
								</Dropdown>
							</div>
							<div className="flex-row-right">
								<Link to="/notifications">
									<Button
										variant="light"
										className="notifications-btn flex-column me-2 content-sm"
									>
										<i className="ri-notification-3-line opacity-7 text-xl" />
										{notifCount ? (
											<span className="notif-counter bg-danger flex-column">
												{notifCount > 9 ? "9+" : notifCount}
											</span>
										) : (
											""
										)}
									</Button>
								</Link>
								<div
									className="nav-toggle flex-column content-sm"
									onClick={() => setShowNav(!showNav)}
								>
									<div className={`fries ${showNav && "active"}`}>
										<div className="top-fry" />
										<div className="mid-fry" />
										<div className="bottom-fry" />
									</div>
								</div>
							</div>
						</Col>
					</Row>
				</Container>
			</div>
			<div className={`nav-sm bg-white border-top ${showNav && "active"}`}>
				<Container className="full-height pt-4">
					<NavLink
						to="/dashboard"
						className="main-nav-item block mb-15"
						onClick={() => setShowNav(false)}
					>
						<span className="text-lg">Dashboard</span>
					</NavLink>
					<NavLink
						to="/safes"
						className="main-nav-item block"
						onClick={() => setShowNav(false)}
					>
						<span className="text-lg">Safes</span>
					</NavLink>
					<div className="nav-sm-action full">
						<Container>
							<Button
								className="btn-md rounded-4 btn-default full mb-15"
								onClick={() => {
									setShowNav(false);
									setGroupModal(true);
								}}
							>
								Create Safe
							</Button>
							<Button
								className="btn-md rounded-4 btn-gray-dark full mb-15"
								onClick={() => {
									navigate("/settings");
									setShowNav(false);
								}}
							>
								Account Settings
							</Button>
							<div className="full border-bottom mb-15" />
							<Button
								className="btn-md rounded-4 btn-gray-dark full"
								onClick={() => navigate("/logout")}
							>
								Logout
							</Button>
						</Container>
					</div>
				</Container>
			</div>
			<Outlet />
			<Modal
				show={groupModal}
				className="post-modal"
				onHide={() => setGroupModal(false)}
				centered
			>
				<Modal.Header className="text-medium-inter text-lg">
					Create Safe
				</Modal.Header>
				<Form onSubmit={addGroup}>
					<Modal.Body>
						<Row className="form-block-sm">
							<Col>
								<Form.Label>Safe Name</Form.Label>
								<Form.Control
									className="form-input"
									placeholder="Enter safe name"
									value={safeName}
									onChange={(e) => setSafeName(e.target.value)}
								/>
							</Col>
						</Row>
					</Modal.Body>
					<Modal.Footer>
						<Button
							variant="light"
							className="modal-btn btn-md dismiss-btn text-medium-inter text-sm"
							onClick={() => setGroupModal(false)}
						>
							Cancel
						</Button>
						<Button
							type="submit"
							className="modal-btn btn-md btn-default text-medium-inter text-sm"
							disabled={actionLoading}
						>
							{actionLoading ? "Creating.." : "Create Safe"}
						</Button>
					</Modal.Footer>
				</Form>
			</Modal>
			<Modal
				show={subscribeModal}
				onHide={() => setSubscribeModal(false)}
				centered
			>
				<Modal.Header className="text-medium-inter text-lg flex-row-left">
					<i class="ri-error-warning-line text-danger text-xl me-2" />
					Upgrade Account
				</Modal.Header>
				<Modal.Body>
					<p className="text-sm opacity-5 mb-0">{subscribeError}</p>
				</Modal.Body>
				<Modal.Footer>
					<Button
						variant="light"
						className="modal-btn btn-md dismiss-btn text-medium-inter text-sm"
						onClick={() => setSubscribeModal(false)}
					>
						Cancel
					</Button>
					<Button
						className="modal-btn btn-md btn-default text-medium-inter text-sm"
						onClick={() => navigate(`/plans?redirect_url=/safes`)}
					>
						Upgrade
					</Button>
				</Modal.Footer>
			</Modal>
		</>
	);
};

export default Layout;
