import { useState, useEffect, useContext } from "react";
import { UserContext } from "../context/UserContext";
import { useSnackbar } from "react-simple-snackbar";
import Button from "react-bootstrap/Button";
import Dropdown from "react-bootstrap/Dropdown";
import MfaLoader from "./MfaLoader";

const MfaToken = ({ mfa, setToDelete }) => {
	const [user] = useContext(UserContext);
	const [token, setToken] = useState(null);

	const [defaultSnackbar] = useSnackbar();

	const getToken = async () => {
		const requestOptions = {
			method: "GET",
		};

		const response = await fetch(
			`${process.env.REACT_APP_API_URI}/users/mfa/tokens/${mfa.id}`,
			requestOptions
		);
		const data = await response.json();

		if (!response.ok) {
			console.log("Something went wrong.");
		} else {
			setToken(data.current);
		}
	};

	const copyToken = async () => {
		navigator.clipboard.writeText(token);
		defaultSnackbar("Copied to clipboard.");
		const requestOptions = {
			method: "POST",
			headers: {
				"X-Auth-Token": user ? user.session_token : "",
			},
		};

		const response = await fetch(
			`${process.env.REACT_APP_API_URI}/users/mfa/tokens/${mfa.id}/copy`,
			requestOptions
		);
	};

	useEffect(() => {
		getToken();
	}, []);

	return (
		<>
			<div className="content-item mfa-item bg-white rounded-4 full">
				<div className="mfa-title-container flex-row-left">
					<div className="col-8">
						{/* <img src={facebook} alt="canada" className="country-icon" /> */}
						<span className="text-medium-inter">{mfa.issuer}</span>
					</div>
					<div className="col-4 mfa-item-control flex-row-right">
						<MfaLoader getToken={getToken} />
					</div>
				</div>
				<div className="flex-row-left">
					<div className="col-8">
						<span className="mfa-username label-md opacity-3 block mb-2">
							{mfa.username}
						</span>
						<div className="mfa-code-row">
							<span className="mfa-code text-medium-inter">
								{token && `${token.slice(0, 3)} ${token.slice(3)}`}
							</span>
						</div>
					</div>
					<div className="col-4 mfa-item-control flex-row-right">
						<Button className="flex-column" onClick={() => copyToken(token)}>
							<i className="ri-file-copy-line text-sm" />
						</Button>
						<Dropdown className="dropdown-default">
							<Dropdown.Toggle className="flex-column no-caret">
								<i className="ri-settings-line text-sm" />
							</Dropdown.Toggle>

							<Dropdown.Menu>
								<Dropdown.Item href="#" className="text-sm">
									Edit
								</Dropdown.Item>
								<Dropdown.Divider />
								<Dropdown.Item
									href="#"
									onClick={(e) => {
										e.preventDefault();
										setToDelete(mfa.id);
									}}
									className="text-sm text-danger"
								>
									Delete
								</Dropdown.Item>
							</Dropdown.Menu>
						</Dropdown>
					</div>
				</div>
			</div>
		</>
	);
};

export default MfaToken;
