import { useContext, useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { UserContext } from "../context/UserContext";
import { ProfileContext } from "../context/ProfileContext";
import { KeyContext } from "../context/KeyContext";
import { useSnackbar } from "react-simple-snackbar";
import { decrypt } from "../components/Utils";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import PageLoader from "../components/PageLoader";
import LogoDark from "../components/LogoDark";

const AssetRequest = () => {
	document.title = "Access Request | SMSafe";
	let { id } = useParams();
	const navigate = useNavigate();
	const [user] = useContext(UserContext);
	const [profile] = useContext(ProfileContext);
	const [key] = useContext(KeyContext);
	const [error, setError] = useState(null);
	const [loading, setLoading] = useState(true);
	const [actionLoading, setActionLoading] = useState(false);
	const [granting, setGranting] = useState(false);
	const [rejecting, setRejecting] = useState(false);
	const [allowEdit, setAllowEdit] = useState(false);
	const [expiration, setExpiration] = useState("15");
	const [assetRequest, setAssetRequest] = useState(null);
	const [group, setGroup] = useState(null);

	const [assetUrl, setAssetUrl] = useState("");
	const [assetNotes, setAssetNotes] = useState("");
	const [username, setUsername] = useState("");
	const [password, setPassword] = useState("");
	const [assetMsisdn, setAssetMsisdn] = useState("");
	const [cardNo, setCardNo] = useState("");
	const [cardExpiry, setCardExpiry] = useState("");
	const [cardCode, setCardCode] = useState("");
	const [cardName, setCardName] = useState("");
	const [apiKey, setApiKey] = useState("");
	const [apiSecret, setApiSecret] = useState("");
	const [docs, setDocs] = useState("");
	const [street, setStreet] = useState("");
	const [city, setCity] = useState("");
	const [province, setProvince] = useState("");
	const [country, setCountry] = useState("");
	const [postalCode, setPostalCode] = useState("");
	const [wifiEncryption, setWifiEncryption] = useState("WPA2");
	const [wifiName, setWifiName] = useState("");
	const [wifiPassword, setWifiPassword] = useState("");
	const [wifiIsHidden, setWifiIsHidden] = useState(false);
	const [contactName, setContactName] = useState("");
	const [contactEmail, setContactEmail] = useState("");
	const [contactMsisdn, setContactMsisdn] = useState("");

	const [defaultSnackbar] = useSnackbar();

	const [dangerSnackbar] = useSnackbar({
		style: {
			zIndex: 10,
			backgroundColor: "#d81e5b",
			color: "#fff",
		},
	});

	const getData = async () => {
		setLoading(true);
		const requestOptions = {
			method: "GET",
			headers: {
				"X-Auth-Token": user ? user.session_token : "",
			},
		};

		const response = await fetch(
			`${process.env.REACT_APP_API_URI}/users/assets/access-requests/${id}`,
			requestOptions
		);
		const data = await response.json();

		if (!response.ok) {
			setError(data.msg);
		} else {
			setAssetRequest(data.access_request);
			setGroup(data.group);

			const assetPassword = decrypt(key, profile.user.email, data.salt);

			setAssetUrl(decrypt(data.asset.url, assetPassword, data.salt));
			setUsername(decrypt(data.asset.username, assetPassword, data.salt));
			setPassword(decrypt(data.asset.password, assetPassword, data.salt));
			setAssetMsisdn(decrypt(data.asset.msisdn, assetPassword, data.salt));
			setCardNo(decrypt(data.asset.card_no, assetPassword, data.salt));
			setCardExpiry(decrypt(data.asset.expiration, assetPassword, data.salt));
			setCardCode(decrypt(data.asset.cvv, assetPassword, data.salt));
			setCardName(decrypt(data.asset.card_name, assetPassword, data.salt));
			setApiKey(decrypt(data.asset.api_key, assetPassword, data.salt));
			setApiSecret(decrypt(data.asset.api_secret, assetPassword, data.salt));
			setDocs(decrypt(data.asset.documentation, assetPassword, data.salt));
			setStreet(decrypt(data.asset.street, assetPassword, data.salt));
			setCity(decrypt(data.asset.city, assetPassword, data.salt));
			setProvince(decrypt(data.asset.province, assetPassword, data.salt));
			setCountry(decrypt(data.asset.country, assetPassword, data.salt));
			setPostalCode(decrypt(data.asset.postal_code, assetPassword, data.salt));
			setContactName(
				decrypt(data.asset.contact_name, assetPassword, data.salt)
			);
			setContactEmail(
				decrypt(data.asset.contact_email, assetPassword, data.salt)
			);
			setContactMsisdn(
				decrypt(data.asset.contact_msisdn, assetPassword, data.salt)
			);
			setWifiEncryption(
				decrypt(data.asset.wifi_encryption, assetPassword, data.salt)
			);
			setWifiName(decrypt(data.asset.wifi_name, assetPassword, data.salt));
			setWifiPassword(
				decrypt(data.asset.wifi_password, assetPassword, data.salt)
			);
			setAssetNotes(decrypt(data.asset.notes, assetPassword, data.salt));
			setWifiIsHidden(data.asset.wifi_is_hidden);
		}
		setLoading(false);
	};

	const respond = async (action) => {
		if (action === "approve") {
			setGranting(true);
		} else {
			setRejecting(true);
		}

		setActionLoading(true);
		const requestOptions = {
			method: "POST",
			headers: {
				"X-Auth-Token": user ? user.session_token : "",
			},
			body: JSON.stringify({
				allow_edit: allowEdit,
				access_expiration: expiration,
				url: assetUrl,
				notes: assetNotes,
				username: username,
				password: password,
				msisdn: assetMsisdn,
				card_no: cardNo,
				expiration: cardExpiry,
				cvv: cardCode,
				card_name: cardName,
				api_key: apiKey,
				api_secret: apiSecret,
				documentation: docs,
				street: street,
				city: city,
				province: province,
				country: country,
				postal_code: postalCode,
				contact_name: contactName,
				contact_email: contactEmail,
				contact_msisdn: contactMsisdn,
				wifi_name: wifiName,
				wifi_password: wifiPassword,
				wifi_encryption: wifiEncryption,
				wifi_is_hidden: wifiIsHidden,
			}),
		};

		const response = await fetch(
			`${process.env.REACT_APP_API_URI}/users/assets/access-requests/${id}/${action}`,
			requestOptions
		);
		const data = await response.json();

		if (!response.ok) {
			setError(data.msg);
		} else {
			getData();
		}
		setActionLoading(false);
		setGranting(false);
		setRejecting(false);
	};

	useEffect(() => {
		if (profile && key) {
			getData();
		}
	}, [profile, key]);

	return (
		<div className="full-page bg-gray flex-column">
			{loading ? (
				<PageLoader className="full-page" />
			) : (
				<Container className="flex-column">
					<div className="col-lg-4 col-md-7 col-12 p-30 bg-white rounded flex-column">
						<LogoDark className="mb-30" />
						{error ? (
							<div className="align-center">
								<div className="flex-row-center mb-10">
									<i className="ri-error-warning-line text-xl text-danger me-1" />
									<h1 className="mb-0 text-lg text-medium-inter">Error</h1>
								</div>
								<p className="opacity-5 text-sm align-center mb-0">{error}</p>
							</div>
						) : assetRequest?.status === "Approved" ? (
							<div className="align-center">
								<div className="flex-row-center mb-10">
									<i className="ri-shield-check-line text-xl text-success me-1" />
									<h1 className="mb-0 text-lg text-medium-inter">
										Access Granted
									</h1>
								</div>
								<p className="opacity-5 text-sm align-center mb-30">
									{assetRequest?.user.username} has been granted access.
								</p>
								<Link to="/">
									<Button className="btn-md rounded-4 btn-default full mb-0 text-medium-inter">
										Back to Dashboard
									</Button>
								</Link>
							</div>
						) : assetRequest?.status === "Rejected" ? (
							<div className="align-center">
								<div className="flex-row-center mb-10">
									<i className="ri-close-circle-line text-xl text-danger me-1" />
									<h1 className="mb-0 text-lg text-medium-inter">
										Request Rejected
									</h1>
								</div>
								<p className="opacity-5 text-sm align-center mb-30">
									The request from {assetRequest?.user.username} has been
									rejected.
								</p>
								<Link to="/">
									<Button className="btn-md rounded-4 btn-default full mb-0 text-medium-inter">
										Back to Dashboard
									</Button>
								</Link>
							</div>
						) : (
							<div>
								<div className="flex-column">
									<div className="flex-row-left mb-0">
										<i className="ri-lock-line text-xl text-success me-1" />
										<h1 className="mb-0 text-lg text-medium-inter">
											Access Request
										</h1>
									</div>
									<p className="opacity-5 align-center text-sm mb-30">
										{assetRequest?.user.username} is requesting access to one of
										your assets.
									</p>
								</div>
								<Row className="mb-20">
									<Col>
										<span className="text-sm text-medium-inter">
											Asset Name:
										</span>
									</Col>
									<Col>
										<span className="text-sm">{assetRequest?.asset.name}</span>
									</Col>
								</Row>
								<Row className="mb-20">
									<Col>
										<span className="text-sm text-medium-inter">Safe:</span>
									</Col>
									<Col>
										<span className="text-sm">{group?.name}</span>
									</Col>
								</Row>
								<Row className="mb-20">
									<Col>
										<span className="text-sm text-medium-inter">
											Allow Editing:
										</span>
									</Col>
									<Col>
										<input
											type="checkbox"
											defaultChecked={allowEdit}
											onChange={(e) => setAllowEdit(e.target.checked)}
											className="cbx asset-cbx hidden"
											id="allowEditing"
										/>
										<label
											htmlFor="allowEditing"
											className="lbl asset-lbl"
										></label>
									</Col>
								</Row>
								<Row className="mb-50">
									<Col>
										<span className="text-sm text-medium-inter">
											Expiration:
										</span>
									</Col>
									<Col>
										<Form.Select
											size="sm"
											value={expiration}
											onChange={(e) => setExpiration(e.target.value)}
										>
											<option value="5">5 Minutes</option>
											<option value="15">15 Minutes</option>
											<option value="30">30 Minutes</option>
											<option value="60">1 Hour</option>
											<option value="720">12 Hours</option>
											<option value="1440">1 Day</option>
											<option value="">None</option>
										</Form.Select>
									</Col>
								</Row>
								<Button
									className="btn-md rounded-4 btn-default full mb-15 text-medium-inter"
									disabled={actionLoading}
									onClick={() => respond("approve")}
								>
									{granting ? "Granting.." : "Grant Access"}
								</Button>
								<Button
									variant="light"
									className="btn-md rounded-4 full text-medium-inter"
									disabled={actionLoading}
									onClick={() => respond("reject")}
								>
									{rejecting ? "Rejecting.." : "Reject"}
								</Button>
							</div>
						)}
					</div>
				</Container>
			)}
		</div>
	);
};

export default AssetRequest;
