import { useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../context/UserContext";
import { KeyContext } from "../context/KeyContext";

const Logout = () => {
	document.title = "SMSafe";
	const navigate = useNavigate();
	const [user, setUser] = useContext(UserContext);
	const [, setKey] = useContext(KeyContext);

	const logout = async () => {
		const requestOptions = {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				"X-Auth-Token": user.session_token,
			},
		};

		const response = await fetch(
			`${process.env.REACT_APP_API_URI}/users/logout`,
			requestOptions
		);
		const data = await response.json();

		if (!response.ok) {
			console.log("Something went wrong.");
		} else {
			setKey(null);
			setUser(null);
		}
	};

	useEffect(() => {
		logout();
	}, []);

	useEffect(() => {
		if (!user) {
			navigate("/login");
		}
	}, [user]);

	return "";
};

export default Logout;
