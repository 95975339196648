import React, { useState, useContext } from "react";
import { useLocation } from "react-router-dom";
import { UserContext } from "../context/UserContext";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from "../components/CheckoutForm";
import LogoDark from "../components/LogoDark";
import Spinner from "react-bootstrap/Spinner";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUB_KEY);

const Payment = () => {
	document.title = "Payment | SMSafe";
	const location = useLocation();
	const clientSecret = location.state.clientSecret;

	const appearance = {
		theme: "stripe",
		variables: {
			colorPrimary: "#0570de",
			colorBackground: "#ffffff",
			colorText: "#30313d",
			colorDanger: "#df1b41",
			fontFamily: "Ideal Sans, system-ui, sans-serif",
			borderRadius: "4px",
		},
	};
	const options = {
		clientSecret,
		appearance,
	};

	return (
		<div className="full-page bg-gray flex-column">
			<LogoDark className="verify-logo" />
			<div className="payment-form-container p-30 bg-white rounded flex-column">
				<div className="align-center mb-20">
					<span className="text-medium-inter text-lg">Add Payment Method</span>
				</div>
				{!clientSecret ? (
					<div className="flex-column p-20">
						<Spinner className="page-loader-icon text-blue" />
					</div>
				) : (
					<Elements options={options} stripe={stripePromise}>
						<CheckoutForm clientSecret={clientSecret} />
					</Elements>
				)}
			</div>
		</div>
	);
};

export default Payment;
