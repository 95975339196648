import { useEffect, useContext } from "react";
import { UserContext } from "../context/UserContext";
import { ProfileContext } from "../context/ProfileContext";
import { useSearchParams, useNavigate } from "react-router-dom";
import { useSnackbar } from "react-simple-snackbar";
import PageLoader from "../components/PageLoader";

const PaymentVerification = () => {
	document.title = "Payment Verification | SMSafe";
	const navigate = useNavigate();
	const [user] = useContext(UserContext);
	const [profile, setProfile] = useContext(ProfileContext);
	const [searchParams] = useSearchParams();
	const subscriptionId = searchParams.get("subscription");
	const tier = searchParams.get("tier");

	const [dangerSnackbar] = useSnackbar({
		style: {
			zIndex: 10,
			backgroundColor: "#d81e5b",
			color: "#fff",
		},
	});

	const verifySubscription = async () => {
		const requestOptions = {
			method: "POST",
			headers: {
				"X-Auth-Token": user ? user.session_token : "",
			},
			body: JSON.stringify({
				subscription_id: subscriptionId,
				tier: tier,
			}),
		};

		const response = await fetch(
			`${process.env.REACT_APP_API_URI}/users/subscriptions/verify`,
			requestOptions
		);
		const data = await response.json();

		if (!response.ok) {
			dangerSnackbar(data.msg);
			navigate("/payment");
		} else {
			setProfile(data.profile);
		}
	};

	useEffect(() => {
		if (profile && profile.user?.subscription?.status !== "active") {
			verifySubscription();
		}
	}, [profile]);

	return (
		<div className="full-page bg-gray flex-column">
			<PageLoader className="full-page" />
		</div>
	);
};

export default PaymentVerification;
