import EmptyBee from "./EmptyBee";

const EmptyPage = ({ children, label, className }) => {
	return (
		<div className={`flex-column p-30 bg-white rounded-4 ${className}`}>
			<EmptyBee />
			<span className="empty-label text-lg">{label}</span>
			{children && children}
		</div>
	);
};

export default EmptyPage;
