import React, { createContext, useEffect, useState } from "react";

export const UserContext = createContext();

export const UserProvider = (props) => {
	const [user, setUser] = useState(
		JSON.parse(localStorage.getItem("smsafeUser")) || null
	);

	const fetchUser = async () => {
		const requestOptions = {
			headers: {
				method: "GET",
				"Content-Type": "application/json",
				"X-Auth-Token": user ? user.session_token : "",
			},
		};

		const response = await fetch(
			`${process.env.REACT_APP_API_URI}/users/me`,
			requestOptions
		);

		if (!response.ok) {
			setUser(null);
		} else {
			localStorage.setItem("smsafeUser", JSON.stringify(user));
		}
	};

	useEffect(() => {
		if (user && user !== null) {
			fetchUser();
		} else {
			localStorage.removeItem("smsafeUser");
		}
	}, [user]);

	return (
		<UserContext.Provider value={[user, setUser]}>
			{props.children}
		</UserContext.Provider>
	);
};
