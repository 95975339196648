import React, { createContext, useEffect, useState } from "react";

export const SaltContext = createContext();

export const SaltProvider = (props) => {
	const [salt, setSalt] = useState(localStorage.getItem("salt") || null);

	useEffect(() => {
		if (salt) {
			localStorage.setItem("salt", salt);
		} else {
			localStorage.removeItem("salt");
		}
	}, [salt]);

	return (
		<SaltContext.Provider value={[salt, setSalt]}>
			{props.children}
		</SaltContext.Provider>
	);
};
