import { useEffect, useState, useContext } from "react";
import { UserContext } from "../context/UserContext";
import { ProfileContext } from "../context/ProfileContext";
import { KeyContext } from "../context/KeyContext";
import { SaltContext } from "../context/SaltContext";
import { useSearchParams, useNavigate } from "react-router-dom";
import { useSnackbar } from "react-simple-snackbar";
import { encrypt, decrypt } from "../components/Utils";
import LogoDark from "../components/LogoDark";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";

const Password = () => {
	document.title = "Reset Password | SMSafe";
	const navigate = useNavigate();
	const [user, setUser] = useContext(UserContext);
	const [profile] = useContext(ProfileContext);
	const [key] = useContext(KeyContext);
	const [salt] = useContext(SaltContext);
	const [loading, setLoading] = useState(false);
	const [password, setPassword] = useState("");
	const [oldPassword, setOldPassword] = useState("");
	const [passwordConfirm, setPasswordConfirm] = useState("");
	const [passwordMessage, setPasswordMessage] = useState("");
	const [oldPasswordMessage, setOldPasswordMessage] = useState("");
	const [passwordConfirmError, setPasswordConfirmError] = useState(false);
	const [passwordLoading, setPasswordLoading] = useState(false);
	const [ready, setReady] = useState(false);
	const [fetching, setFetching] = useState(false);
	const [decrypting, setDecrypting] = useState(false);
	const [encrypting, setEncrypting] = useState(false);

	const [defaultSnackbar] = useSnackbar();

	const [dangerSnackbar] = useSnackbar({
		style: {
			zIndex: 10,
			backgroundColor: "#d81e5b",
			color: "#fff",
		},
	});

	const getAssets = async (e) => {
		e.preventDefault();
		setFetching(true);
		setOldPasswordMessage("");
		const requestOptions = {
			method: "GET",
			headers: { "X-Auth-Token": user ? user.session_token : "" },
		};

		const response = await fetch(
			`${process.env.REACT_APP_API_URI}/users/me/assets`,
			requestOptions
		);
		const data = await response.json();

		if (!response.ok) {
			dangerSnackbar(data.msg);
		} else {
			setDecrypting(true);
			decryptAssets([...data.parent_assets, ...data.asset_copies]);
		}
		setFetching(false);
	};

	const decryptAssets = (hashedAssets) => {
		const assetPassword = decrypt(key, profile.user.email, salt);
		const decryptedAssets = [];

		hashedAssets.map((hashedAsset) => {
			if (hashedAsset.asset_id && !hashedAsset.unlocked) {
				decryptedAssets.push({
					id: hashedAsset.id,
					asset_id: hashedAsset.asset_id || null,
					name: hashedAsset.name,
					type_: hashedAsset.type_,
					code: hashedAsset.mfa_token?.token || "",
					wifi_is_hidden: hashedAsset.wifi_is_hidden,
					url: hashedAsset.url,
					username: hashedAsset.username,
					password: hashedAsset.password,
					msisdn: hashedAsset.msisdn,
					card_no: hashedAsset.card_no,
					expiration: hashedAsset.expiration,
					cvv: hashedAsset.cvv,
					card_name: hashedAsset.card_name,
					api_key: hashedAsset.api_key,
					api_secret: hashedAsset.api_secret,
					documentation: hashedAsset.documentation,
					street: hashedAsset.street,
					city: hashedAsset.city,
					province: hashedAsset.province,
					country: hashedAsset.country,
					postal_code: hashedAsset.postal_code,
					contact_name: hashedAsset.contact_name,
					contact_email: hashedAsset.contact_email,
					contact_msisdn: hashedAsset.contact_msisdn,
					wifi_encryption: hashedAsset.wifi_encryption,
					wifi_name: hashedAsset.wifi_name,
					wifi_password: hashedAsset.wifi_password,
					notes: hashedAsset.notes,
				});
			} else {
				decryptedAssets.push({
					id: hashedAsset.id,
					asset_id: hashedAsset.asset_id || null,
					name: hashedAsset.name,
					type_: hashedAsset.type_,
					code: hashedAsset.mfa_token?.token || "",
					wifi_is_hidden: hashedAsset.wifi_is_hidden,
					url: decrypt(hashedAsset.url, assetPassword, salt),
					username: decrypt(hashedAsset.username, assetPassword, salt),
					password: decrypt(hashedAsset.password, assetPassword, salt),
					msisdn: decrypt(hashedAsset.msisdn, assetPassword, salt),
					card_no: decrypt(hashedAsset.card_no, assetPassword, salt),
					expiration: decrypt(hashedAsset.expiration, assetPassword, salt),
					cvv: decrypt(hashedAsset.cvv, assetPassword, salt),
					card_name: decrypt(hashedAsset.card_name, assetPassword, salt),
					api_key: decrypt(hashedAsset.api_key, assetPassword, salt),
					api_secret: decrypt(hashedAsset.api_secret, assetPassword, salt),
					documentation: decrypt(
						hashedAsset.documentation,
						assetPassword,
						salt
					),
					street: decrypt(hashedAsset.street, assetPassword, salt),
					city: decrypt(hashedAsset.city, assetPassword, salt),
					province: decrypt(hashedAsset.province, assetPassword, salt),
					country: decrypt(hashedAsset.country, assetPassword, salt),
					postal_code: decrypt(hashedAsset.postal_code, assetPassword, salt),
					contact_name: decrypt(hashedAsset.contact_name, assetPassword, salt),
					contact_email: decrypt(
						hashedAsset.contact_email,
						assetPassword,
						salt
					),
					contact_msisdn: decrypt(
						hashedAsset.contact_msisdn,
						assetPassword,
						salt
					),
					wifi_encryption: decrypt(
						hashedAsset.wifi_encryption,
						assetPassword,
						salt
					),
					wifi_name: decrypt(hashedAsset.wifi_name, assetPassword, salt),
					wifi_password: decrypt(
						hashedAsset.wifi_password,
						assetPassword,
						salt
					),
					notes: decrypt(hashedAsset.notes, assetPassword, salt),
				});
			}
		});
		setDecrypting(false);
		setEncrypting(true);
		encryptAssets(decryptedAssets);
	};

	const encryptAssets = (decryptedAssets) => {
		const encryptedAssets = [];

		decryptedAssets.map((decryptedAsset) => {
			encryptedAssets.push({
				id: decryptedAsset.id,
				asset_id: decryptedAsset.asset_id || null,
				name: decryptedAsset.name,
				type_: decryptedAsset.type_,
				code: decryptedAsset.mfa_token?.token || "",
				wifi_is_hidden: decryptedAsset.wifi_is_hidden,
				url: encrypt(decryptedAsset.url, password, salt),
				username: encrypt(decryptedAsset.username, password, salt),
				password: encrypt(decryptedAsset.password, password, salt),
				msisdn: encrypt(decryptedAsset.msisdn, password, salt),
				card_no: encrypt(decryptedAsset.card_no, password, salt),
				expiration: encrypt(decryptedAsset.expiration, password, salt),
				cvv: encrypt(decryptedAsset.cvv, password, salt),
				card_name: encrypt(decryptedAsset.card_name, password, salt),
				api_key: encrypt(decryptedAsset.api_key, password, salt),
				api_secret: encrypt(decryptedAsset.api_secret, password, salt),
				documentation: encrypt(decryptedAsset.documentation, password, salt),
				street: encrypt(decryptedAsset.street, password, salt),
				city: encrypt(decryptedAsset.city, password, salt),
				province: encrypt(decryptedAsset.province, password, salt),
				country: encrypt(decryptedAsset.country, password, salt),
				postal_code: encrypt(decryptedAsset.postal_code, password, salt),
				contact_name: encrypt(decryptedAsset.contact_name, password, salt),
				contact_email: encrypt(decryptedAsset.contact_email, password, salt),
				contact_msisdn: encrypt(decryptedAsset.contact_msisdn, password, salt),
				wifi_encryption: encrypt(
					decryptedAsset.wifi_encryption,
					password,
					salt
				),
				wifi_name: encrypt(decryptedAsset.wifi_name, password, salt),
				wifi_password: encrypt(decryptedAsset.wifi_password, password, salt),
				notes: encrypt(decryptedAsset.notes, password, salt),
			});
		});

		setEncrypting(false);
		setLoading(true);
		changePassword(encryptedAssets);
	};

	const changePassword = async (encryptedAssets) => {
		const requestOptions = {
			method: "POST",
			headers: { "X-Auth-Token": user ? user.session_token : "" },
			body: JSON.stringify({
				password: password,
				assets: encryptedAssets,
				old_password: oldPassword,
			}),
		};

		const response = await fetch(
			`${process.env.REACT_APP_API_URI}/users/password/reset`,
			requestOptions
		);
		const data = await response.json();

		if (!response.ok) {
			dangerSnackbar(data.msg);
			if (data.type === "old_password") {
				setOldPasswordMessage(data.msg);
			}
		} else {
			setUser(null);
			defaultSnackbar(data.msg);
			navigate("/login");
		}
		setLoading(false);
	};

	const validatePassword = () => {
		setPasswordMessage("");
		const hasNumber = /\d/.test(password);
		const hasCapitalLetter = /[A-Z]/.test(password);
		const hasSpecialCharacter = /[!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]/.test(
			password
		);

		if (hasNumber && hasCapitalLetter && hasSpecialCharacter) {
			setPasswordMessage({
				is_valid: true,
				msg: "Now that's a strong password!",
			});
		} else {
			setPasswordMessage({
				is_valid: false,
				msg: "Must have at least 1 number, 1 uppercase letter, and 1 special character.",
			});
		}
	};

	useEffect(() => {
		const timer = setTimeout(() => {
			if (password) {
				validatePassword();
			} else {
				setPasswordLoading(false);
				setPasswordMessage(null);
			}
		}, 500);
		return () => clearTimeout(timer);
	}, [password]);

	useEffect(() => {
		if (
			password &&
			passwordConfirm &&
			passwordMessage?.is_valid &&
			password === passwordConfirm
		) {
			setReady(true);
			setPasswordConfirmError(false);
		} else {
			setReady(false);
			if (passwordConfirm && passwordConfirm !== password) {
				setPasswordConfirmError(true);
			} else {
				setPasswordConfirmError(false);
			}
		}
	}, [password, passwordConfirm, passwordMessage]);

	return (
		<div className="full-page bg-gray flex-column">
			<Container className="flex-column">
				<div className="col-lg-4 col-md-6 col-12 p-30 bg-white rounded flex-column">
					<LogoDark className="verify-logo" />
					<Form onSubmit={getAssets} className="full">
						<h1 className="text-medium-inter text-lg align-center mb-30">
							Reset Password
						</h1>
						<Row className="form-block-sm">
							<Col>
								<Form.Label>Current Password</Form.Label>
								<Form.Control
									type="password"
									className={`form-input ${
										oldPasswordMessage &&
										!oldPasswordMessage.is_valid &&
										"error"
									}`}
									placeholder="Enter current password"
									value={oldPassword}
									onChange={(e) => setOldPassword(e.target.value)}
								/>
								<div className="login-error-msg full align-left">
									{oldPasswordMessage && (
										<div className="full align-left mt-1">
											<span className={`label-md opacity-7 text-danger`}>
												{oldPasswordMessage}
											</span>
										</div>
									)}
								</div>
							</Col>
						</Row>
						<Row className="form-block-sm">
							<Col>
								<Form.Label>New Password</Form.Label>
								<Form.Control
									type="password"
									className={`form-input ${
										passwordMessage && !passwordMessage.is_valid && "error"
									}`}
									placeholder="Enter new password"
									value={password}
									onChange={(e) => setPassword(e.target.value)}
								/>
								<div className="login-error-msg full align-left">
									{passwordLoading ? (
										<div className="ri-loader-3-line rotating fit mt-1" />
									) : passwordMessage ? (
										<div className="full align-left mt-1">
											<span
												className={`label-md opacity-7 ${
													passwordMessage.is_valid
														? "text-success"
														: "text-danger"
												}`}
											>
												{passwordMessage.msg}
											</span>
										</div>
									) : (
										""
									)}
								</div>
							</Col>
						</Row>
						<Row className="form-block">
							<Col>
								<Form.Label>Confirm Password</Form.Label>
								<Form.Control
									type="password"
									className={`form-input ${passwordConfirmError && "error"}`}
									placeholder="Enter new password again"
									value={passwordConfirm}
									onChange={(e) => setPasswordConfirm(e.target.value)}
								/>
							</Col>
						</Row>
						<Row>
							<Col>
								<Button
									type="submit"
									className="btn-md btn-default text-sm full text-medium-inter"
									disabled={
										loading || !ready || fetching || encrypting || decrypting
									}
								>
									{!loading && !fetching && !encrypting && !decrypting
										? "Reset Password"
										: ""}

									{fetching
										? "Fetching assets.."
										: decrypting
										? "Decrypting assets.."
										: encrypting
										? "Re-encrypting assets.."
										: loading
										? "Saving.."
										: ""}
								</Button>
							</Col>
						</Row>
					</Form>
				</div>
			</Container>
		</div>
	);
};

export default Password;
