import { useContext, useState } from "react";
import { Link } from "react-router-dom";
import { UserContext } from "../context/UserContext";
import { ProfileContext } from "../context/ProfileContext";
import { useSnackbar } from "react-simple-snackbar";
import Logo from "../components/LogoDark";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import MailLogo from "../components/MailLogo";

const NewDevice = () => {
	document.title = "Verify Device | SMSafe";
	const [user] = useContext(UserContext);
	const [profile] = useContext(ProfileContext);
	const [resending, setResending] = useState(false);
	const [showNav, setShowNav] = useState(false);

	const [defaultSnackbar] = useSnackbar();

	const [dangerSnackbar] = useSnackbar({
		style: {
			zIndex: 10,
			backgroundColor: "#d81e5b",
			color: "#fff",
		},
	});

	const resendEmail = async () => {
		setResending(true);
		const requestOptions = {
			method: "POST",
			headers: {
				"X-Auth-Token": user ? user.session_token : "",
			},
		};

		const response = await fetch(
			`${process.env.REACT_APP_API_URI}/users/login-verification/resend`,
			requestOptions
		);
		const data = await response.json();

		if (!response.ok) {
			dangerSnackbar("Something went wrong.");
		} else {
			defaultSnackbar(data.msg);
		}
		setResending(false);
	};

	const hideEmail = (email) => {
		if (email) {
			const atIndex = email.indexOf("@");
			const username = email.slice(0, atIndex);
			const hiddenUsername = username.replace(/./g, "*");
			const domain = email.slice(atIndex);
			return hiddenUsername + domain;
		}
	};

	return (
		<div className="full-page bg-white">
			<div className="login-header full flex-row-left bg-white">
				<Container>
					<Row className="flex-row-left">
						<Col>
							<Link to="/">
								<Logo variant="dark" className="nav-logo" />
							</Link>
						</Col>
						<Col className="nav-action-container p-0">
							<div className="nav-lg flex-row-right">
								<p className="text-light mb-0">
									<Link to="/logout" className="text-blue text-medium-inter">
										Log out
									</Link>
								</p>
							</div>
							<div className="flex-row-right">
								<div
									className="nav-toggle flex-column"
									onClick={() => setShowNav(!showNav)}
								>
									<div className={`fries ${showNav && "active"}`}>
										<div className="top-fry" />
										<div className="mid-fry" />
										<div className="bottom-fry" />
									</div>
								</div>
							</div>
						</Col>
					</Row>
				</Container>
			</div>
			<div
				className={`nav-sm login bg-white border-top ${showNav && "active"}`}
			>
				<Container className="full-height pt-4 pb-4">
					<Link to="/logout" className="text-blue text-medium-inter">
						Log out
					</Link>
				</Container>
			</div>
			<Container className="login-form-container flex-column">
				<MailLogo className="verify-logo mb-30" />
				<div className="col-lg-4 col-md-7 col-12 flex-column">
					<div className="align-center mb-30">
						<h1 className="verify-title title-md text-medium-inter">
							Check your mail.
						</h1>
						<span className="opacity-5 align-center text-sm">
							It looks like you're logging in from a device or location you
							don't usually use. Please verify your email by clicking the link
							that we sent to:
						</span>{" "}
						<span className="text-sm">{hideEmail(profile?.user.email)}.</span>
					</div>
					<div className="flex-column full">
						<Button
							className="btn-md rounded-4 btn-default full mb-20 text-medium-inter"
							onClick={resendEmail}
						>
							{resending ? (
								<Spinner className="text-white btn-md-loader mt-1" />
							) : (
								"Resend Email"
							)}
						</Button>
						<Link to="/logout" className="full">
							<Button
								variant="light"
								className="btn-md rounded-4 btn-gray full text-medium-inter"
							>
								Log Out
							</Button>
						</Link>
					</div>
				</div>
			</Container>
		</div>
	);
};

export default NewDevice;
