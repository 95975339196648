import React, { useState, useEffect } from "react";

const MfaLoader = ({ getToken }) => {
	const [currentServerTime, setCurrentServerTime] = useState(new Date());
	const [prevProgress, setPrevProgress] = useState(0);

	useEffect(() => {
		const updateServerTime = () => {
			setCurrentServerTime(new Date());
			requestAnimationFrame(updateServerTime);
		};

		requestAnimationFrame(updateServerTime);
	}, []);

	const progress =
		((currentServerTime.getSeconds() +
			currentServerTime.getMilliseconds() / 1000) %
			30) /
		30;

	const rotation = progress * 360;

	useEffect(() => {
		if (prevProgress > progress) {
			handleFullCircle();
		}
		setPrevProgress(progress);
	}, [progress, prevProgress]);

	const handleFullCircle = () => {
		getToken();
	};

	return (
		<div className="mfa-loader round-loader">
			<div
				className="circle flex-column"
				style={{
					background: `conic-gradient(
                        #3C57CC 0deg ${rotation}deg,
                        #f2f2f2 ${rotation}deg 360deg
                    )`,
				}}
			>
				<div className="circle-filler bg-white" />
			</div>
		</div>
	);
};

export default MfaLoader;
