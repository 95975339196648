import { useState, useContext, useRef, useMemo, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { UserContext } from "../context/UserContext";
import { ProfileContext } from "../context/ProfileContext";
import { WithContext as ReactTags } from "react-tag-input";
import { useSnackbar } from "react-simple-snackbar";
import QRCodeReader from "qrcode-reader";
import jsQR from "jsqr";
import Select, { components } from "react-select";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Accordion from "react-bootstrap/Accordion";
import Button from "react-bootstrap/Button";
import Logo from "../components/LogoDark";

const Onboarding = () => {
	const fileRef = useRef(null);
	const navigate = useNavigate();
	const [user] = useContext(UserContext);
	const [profile] = useContext(ProfileContext);
	const [step, setStep] = useState("0");
	const [actionLoading, setActionLoading] = useState(false);
	const [showNav, setShowNav] = useState(false);

	const [safeName, setSafeName] = useState("");
	const [countryCode, setCountryCode] = useState("");
	const [emails, setEmails] = useState([]);
	const [assetErrors, setAssetErrors] = useState([]);

	const [assetName, setAssetName] = useState("");
	const [assetType, setAssetType] = useState("Login");
	const [assetUrl, setAssetUrl] = useState("");
	const [assetNotes, setAssetNotes] = useState("");
	const [username, setUsername] = useState("");
	const [password, setPassword] = useState("");
	const [assetCode, setAssetCode] = useState("");
	const [assetMsisdn, setAssetMsisdn] = useState("");
	const [cardNo, setCardNo] = useState("");
	const [cardExpiry, setCardExpiry] = useState("");
	const [cardCode, setCardCode] = useState("");
	const [cardName, setCardName] = useState("");
	const [apiKey, setApiKey] = useState("");
	const [apiSecret, setApiSecret] = useState("");
	const [docs, setDocs] = useState("");
	const [street, setStreet] = useState("");
	const [city, setCity] = useState("");
	const [province, setProvince] = useState("");
	const [country, setCountry] = useState("");
	const [postalCode, setPostalCode] = useState("");

	const [contactName, setContactName] = useState("");
	const [contactEmail, setContactEmail] = useState("");
	const [contactMsisdn, setContactMsisdn] = useState("");

	const [showPassword, setShowPassword] = useState(false);
	const [showAssetCode, setShowAssetCode] = useState(false);
	const [assetInfoModal, setAssetInfoModal] = useState(false);
	const [assetLoading, setAssetLoading] = useState(false);
	const [assetTab, setAssetTab] = useState("details");
	const [canEdit, setCanEdit] = useState(false);
	const [deleteAssetId, setDeleteAssetId] = useState(null);

	const requiredFields = {
		Login: [
			{ key: "assetName", value: assetName },
			{ key: "username", value: username },
			{ key: "password", value: password },
		],
		MFA: [
			{ key: "assetName", value: assetName },
			{ key: "assetCode", value: assetCode },
		],
		Card: [
			{ key: "assetName", value: assetName },
			{ key: "cardNo", value: cardNo },
			{ key: "cardExpiry", value: cardExpiry },
			{ key: "cardCode", value: cardCode },
			{ key: "cardName", value: cardName },
		],
		Address: [
			{ key: "assetName", value: assetName },
			{ key: "street", value: street },
			{ key: "city", value: city },
			{ key: "province", value: province },
			{ key: "country", value: country },
			{ key: "postalCode", value: postalCode },
		],
		API: [
			{ key: "assetName", value: assetName },
			{ key: "apiKey", value: apiKey },
		],
		Contact: [{ key: "assetName", value: assetName }],
	};

	const countryOptions = [
		{
			value: "US",
			label: "(+1) United States - $6/month",
			img: "https://flagcdn.com/h120/us.png",
		},
		{
			value: "CA",
			label: "(+1) Canada - $6/month",
			img: "https://flagcdn.com/h120/ca.png",
		},
	];

	const { Option } = components;
	const IconOption = (props) => (
		<Option {...props}>
			<div className="flex-row-left">
				<img
					src={props.data.img}
					alt={props.data.label}
					className="country-icon"
				/>
				<span className="text-sm">{props.data.label}</span>
			</div>
		</Option>
	);

	const [defaultSnackbar] = useSnackbar();

	const [dangerSnackbar] = useSnackbar({
		style: {
			zIndex: 10,
			backgroundColor: "#d81e5b",
			color: "#fff",
		},
	});

	const handleStep = (nextStep) => {
		setStep(nextStep);
	};

	const KeyCodes = {
		comma: 188,
		enter: 13,
		space: 32,
	};

	const emailDelimiters = [KeyCodes.comma, KeyCodes.enter, KeyCodes.space];

	const handleDeleteEmail = (i) => {
		setEmails(emails.filter((tag, index) => index !== i));
	};

	const handleNewEmail = (tag) => {
		const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
		if (emailRegex.test(tag.text)) {
			if (emails.length < profile?.user.max_members - 1) {
				setEmails([...emails, tag]);
			} else {
				dangerSnackbar("Maximum members reached.");
			}
		} else {
			dangerSnackbar("Invalid email format.");
		}
	};

	const handleEmailBlur = (tag) => {
		if (tag) {
			handleNewEmail({ id: tag, text: tag });
		}
	};

	const onboardUser = async () => {
		var errors = [];
		const requestOptions = {
			method: "POST",
			headers: {
				"X-Auth-Token": user ? user.session_token : "",
			},
			body: JSON.stringify({
				name: safeName,
				member_emails: emails,
				local_code: countryCode.value,
				asset: {
					name: assetName,
					type: assetType,
					url: assetUrl,
					notes: assetNotes,
					username: username,
					password: password,
					token: assetCode,
					msisdn: assetMsisdn,
					card_no: cardNo,
					expiration: cardExpiry,
					cvv: cardCode,
					card_name: cardName,
					api_key: apiKey,
					api_secret: apiSecret,
					documentation: docs,
					street: street,
					city: city,
					province: province,
					country: country,
					postal_code: postalCode,
					contact_name: contactName,
					contact_email: contactEmail,
					contact_msisdn: contactMsisdn,
				},
			}),
		};

		const response = await fetch(
			`${process.env.REACT_APP_API_URI}/users/set-up`,
			requestOptions
		);
		const data = await response.json();

		if (!response.ok) {
			dangerSnackbar(data.msg);
			errors.push(data.error);
			setAssetErrors(errors);
		} else {
			navigate("/dashboard");
		}
		setActionLoading(false);
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		setActionLoading(true);
		var newErrors = [];

		if (assetName) {
			requiredFields[assetType].map((field) => {
				if (!field.value || field.value === "" || field.value.length === 0) {
					newErrors.push(field.key);
				}
			});

			if (newErrors.length !== 0) {
				dangerSnackbar("Please fill out all required fields.");
				setActionLoading(false);
				setAssetErrors(newErrors);
				return;
			}
		}
		setAssetErrors(newErrors);
		onboardUser();
	};

	const copyAssetItem = async (asset_id, value) => {
		navigator.clipboard.writeText(value);
		defaultSnackbar("Copied to clipboard.");
	};

	const generatePassword = () => {
		const lowercaseLetters = "abcdefghijklmnopqrstuvwxyz";
		const uppercaseLetters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
		const numbers = "0123456789";
		const specialCharacters = '!@#$%&*()_"';

		const allCharacters =
			lowercaseLetters + uppercaseLetters + numbers + specialCharacters;

		let password = "";
		for (let i = 0; i < 16; i++) {
			// generating a 12-character password
			const randomIndex = Math.floor(Math.random() * allCharacters.length);
			password += allCharacters[randomIndex];
		}

		setPassword(password);
		setShowPassword(true);
	};

	const handleFileInputChange = (e) => {
		const file = e.target.files[0];
		if (!file) return;
		const qrCodeReader = new QRCodeReader();

		// Read the image file using FileReader
		const reader = new FileReader();
		reader.onload = () => {
			const img = new Image();
			img.src = reader.result;
			img.onload = () => {
				// Decode the QR code from the image
				const canvas = document.createElement("canvas");
				const context = canvas.getContext("2d");
				canvas.width = img.width;
				canvas.height = img.height;
				context.drawImage(img, 0, 0);

				const imageData = context.getImageData(
					0,
					0,
					canvas.width,
					canvas.height
				);

				// Decode the QR code using jsQR
				const code = jsQR(imageData.data, imageData.width, imageData.height);

				if (code) {
					const params = code.data.split("&");
					let secret = "";
					params.forEach((param) => {
						if (param.includes("secret=")) {
							secret = param.split("=")[1];
						}
					});
					setAssetCode(secret);
				} else {
					dangerSnackbar("QR code not detected.");
				}
			};
		};
		reader.readAsDataURL(file);
	};

	const handleCardNoChange = (e) => {
		let formattedCardNo = e.target.value.replace(/\s/g, ""); // Remove any existing spaces
		formattedCardNo = formattedCardNo.replace(/(\d{4})/g, "$1 ").trim(); // Add space after every 4 characters

		setCardNo(formattedCardNo);
	};

	const handleCardExpiryChange = (e) => {
		let formattedCardExpiry = e.target.value.replace(/\s/g, ""); // Remove any existing spaces
		formattedCardExpiry = formattedCardExpiry
			.replace(/^(\d{2})(\d{1,2})$/, "$1/$2") // Add '/' after first 2 characters if not already added
			.replace(/^(\d{2})\/(\d{2})$/, "$1/$2"); // Add '/' after next 2 characters if not already added

		setCardExpiry(formattedCardExpiry);
	};

	const handleAssetTypeChange = () => {
		setAssetUrl("");
		setUsername("");
		setPassword("");
		setCardNo("");
		setCardExpiry("");
		setCardCode("");
		setCardName("");
		setApiKey("");
		setApiSecret("");
		setDocs("");
		setAssetNotes("");
		setShowPassword(false);
	};

	useMemo(() => {
		setAssetErrors([]);
		handleAssetTypeChange();
	}, [assetType]);

	useEffect(() => {
		if (profile && !profile.user.email_verified) {
			navigate("/");
		}
	}, [profile]);

	return (
		<div className="onboarding-wizard full-page">
			<div className="login-header full flex-row-left bg-white mb-50">
				<Container>
					<Row className="flex-row-left">
						<Col>
							<Link to="/">
								<Logo variant="dark" className="nav-logo" />
							</Link>
						</Col>
						<Col className="nav-action-container p-0 flex-row-right">
							<div className="nav-lg content-lg flex-row-right">
								<p className="text-light mb-0">
									<Link to="/dashboard" className="text-blue text-medium-inter">
										Go to dashboard
									</Link>
								</p>
							</div>
							<div className="content-sm flex-row-right">
								<div
									className="nav-toggle flex-column"
									onClick={() => setShowNav(!showNav)}
								>
									<div className={`fries ${showNav && "active"}`}>
										<div className="top-fry" />
										<div className="mid-fry" />
										<div className="bottom-fry" />
									</div>
								</div>
							</div>
						</Col>
					</Row>
				</Container>
			</div>
			<div
				className={`nav-sm login bg-white border-top ${showNav && "active"}`}
			>
				<Container className="full-height pt-4 pb-4">
					<Link to="/dashboard" className="text-blue text-medium-inter">
						Go to dashboard
					</Link>
				</Container>
			</div>
			<Container className="flex-column">
				<div className="onboarding-container col-lg-5 col-md-7 col-12">
					<h1 className="title-md text-medium-inter">Account Setup</h1>
					<p className="text-sm mb-30">
						Let's get you started by setting up your first safe.
					</p>
					<Accordion activeKey={step}>
						<Accordion.Item eventKey="0" className="onboarding-item">
							<Accordion.Header
								className="onboarding-step-header"
								onClick={() => handleStep("0")}
							>
								<div className="flex-row-left text-default">
									<div className="onboarding-header-icon flex-column">
										<span className="text-medium-inter text-sm">1</span>
									</div>
									<span className="text-medium-inter">
										Create a brand new safe
									</span>
								</div>
							</Accordion.Header>
							<Accordion.Body className="onboarding-item-body">
								<p className="text-sm opacity-7 mb-20">
									A safe is a secure place where you can store assets like login
									credentials,card details, 2FA codes, and more.
								</p>
								<Form
									onSubmit={(e) => {
										e.preventDefault();
										handleStep("1");
									}}
								>
									<Row className="mb-30">
										<Col>
											<Form.Label>Safe Name</Form.Label>
											<Form.Control
												autoFocus
												className="form-input"
												value={safeName}
												onChange={(e) => setSafeName(e.target.value)}
											/>
										</Col>
									</Row>
									<Button
										type="submit"
										className="btn-default btn-md rounded-4 full flex-column mb-15"
										disabled={!safeName}
									>
										Create Safe
									</Button>
									<Link to="/">
										<Button className="btn-tertiary full btn-md text-sm rounded-4 full flex-column">
											Skip to dashboard
										</Button>
									</Link>
								</Form>
							</Accordion.Body>
						</Accordion.Item>
						<Accordion.Item eventKey="1" className="onboarding-item">
							<Accordion.Header
								className="onboarding-step-header"
								onClick={() => handleStep("1")}
							>
								<div className="flex-row-left text-default">
									<div className="onboarding-header-icon flex-column">
										<span className="text-medium-inter text-sm">2</span>
									</div>
									<span className="text-medium-inter">
										Invite members to your safe
									</span>
								</div>
							</Accordion.Header>
							<Accordion.Body className="onboarding-item-body">
								<p className="text-sm opacity-7 mb-10">
									You can invite team members to your safe so you can securely
									share assets with them.
								</p>
								<Form
									onSubmit={(e) => {
										e.preventDefault();
										handleStep("2");
									}}
								>
									<Row className="mb-30">
										<Col>
											<ReactTags
												tags={emails}
												delimiters={emailDelimiters}
												handleInputBlur={handleEmailBlur}
												handleDelete={handleDeleteEmail}
												handleAddition={handleNewEmail}
												inputFieldPosition="bottom"
												inputProps={{
													disabled: profile && profile?.user.max_members === 1,
												}}
												allowDragDrop={false}
												placeholder={
													profile && profile?.user.max_members !== 1
														? `Add upto ${profile?.user.max_members - 1} emails`
														: "Enter email addresses"
												}
												autofocus={false}
												classNames={{
													tagInput: "full",
													tagInputField: "full form-control form-input text-sm",
													tag: "tag-item text-sm bg-blue text-white rounded-4",
													remove: "tag-remove-btn text-white flex-column",
												}}
											/>
										</Col>
									</Row>
									{profile && profile?.user.max_members === 1 ? (
										<Link to="/plans">
											<Button className="btn-default btn-md rounded-4 full flex-row-center mb-15">
												<i className="ri-lock-2-line" />
												Unlock this feature
											</Button>
										</Link>
									) : (
										<Button
											type="submit"
											className="btn-default btn-md rounded-4 full flex-column mb-15"
										>
											Invite Members
										</Button>
									)}
									<Button
										className="btn-tertiary full btn-md text-sm rounded-4 full flex-column"
										onClick={() => handleStep("2")}
									>
										Skip this step
									</Button>
								</Form>
							</Accordion.Body>
						</Accordion.Item>
						<Accordion.Item eventKey="2" className="onboarding-item">
							<Accordion.Header
								className="onboarding-step-header"
								onClick={() => handleStep("2")}
							>
								<div className="flex-row-left text-default">
									<div className="onboarding-header-icon flex-column">
										<span className="text-medium-inter text-sm">3</span>
									</div>
									<span className="text-medium-inter">
										Get your free virtual number
									</span>
								</div>
							</Accordion.Header>
							<Accordion.Body className="onboarding-item-body">
								<Form
									onSubmit={(e) => {
										e.preventDefault();
										handleStep("3");
									}}
								>
									<Row className="mb-30">
										<Col>
											<Form.Label>Country Code</Form.Label>
											<Select
												isDisabled={profile && profile?.user.max_msisdns === 0}
												defaultValue={countryCode}
												onChange={setCountryCode}
												options={countryOptions}
												components={{ Option: IconOption }}
											/>
										</Col>
									</Row>
									{profile && profile?.user.max_msisdns === 0 ? (
										<Link to="/plans">
											<Button className="btn-default btn-md rounded-4 full flex-row-center mb-15">
												<i className="ri-lock-2-line" />
												Unlock this feature
											</Button>
										</Link>
									) : (
										<Button
											type="submit"
											className="btn-default btn-md rounded-4 full flex-column mb-15"
										>
											Get Number
										</Button>
									)}
									<Button
										className="btn-tertiary full btn-md text-sm rounded-4 full flex-column"
										onClick={() => handleStep("3")}
									>
										Skip this step
									</Button>
								</Form>
							</Accordion.Body>
						</Accordion.Item>
						<Accordion.Item eventKey="3" className="onboarding-item">
							<Accordion.Header
								className="onboarding-step-header"
								onClick={() => handleStep("3")}
							>
								<div className="flex-row-left text-default">
									<div className="onboarding-header-icon flex-column">
										<span className="text-medium-inter text-sm">4</span>
									</div>
									<span className="text-medium-inter">
										Create your first asset
									</span>
								</div>
							</Accordion.Header>
							<Accordion.Body className="onboarding-item-body">
								<Form onSubmit={handleSubmit}>
									<Row className="form-block-sm">
										<Col>
											<Form.Label>Asset Type</Form.Label>
											<div>
												<div
													className={`asset-type-picker border ${
														assetType === "Login" && "active"
													}`}
													onClick={() => setAssetType("Login")}
												>
													<div className="flex-row-left">
														<i className="ri-user-line" />
														<span className="label-md">Login</span>
													</div>
												</div>
												<div
													className={`asset-type-picker flex-row-left border ${
														assetType === "MFA" && "active"
													}`}
													onClick={() => setAssetType("MFA")}
												>
													<div className="flex-row-left">
														<i className="ri-key-2-line" />
														<span className="label-md">2FA Code</span>
													</div>
												</div>
												<div
													className={`asset-type-picker flex-row-left border ${
														assetType === "Card" && "active"
													}`}
													onClick={() => setAssetType("Card")}
												>
													<div className="flex-row-left">
														<i className="ri-bank-card-2-line" />
														<span className="label-md">Card</span>
													</div>
												</div>
												<div
													className={`asset-type-picker flex-row-left border ${
														assetType === "Address" && "active"
													}`}
													onClick={() => setAssetType("Address")}
												>
													<div className="flex-row-left">
														<i className="ri-map-pin-2-line" />
														<span className="label-md">Address</span>
													</div>
												</div>
												<div
													className={`asset-type-picker flex-row-left border ${
														assetType === "Contact" && "active"
													}`}
													onClick={() => setAssetType("Contact")}
												>
													<div className="flex-row-left">
														<i className="ri-user-line" />
														<span className="label-md">Contact</span>
													</div>
												</div>
												<div
													className={`asset-type-picker flex-row-left border ${
														assetType === "API" && "active"
													}`}
													onClick={() => setAssetType("API")}
												>
													<div className="flex-row-left">
														<i className="ri-code-s-slash-line" />
														<span className="label-md">API Credentials</span>
													</div>
												</div>
											</div>
										</Col>
									</Row>
									<Row className="form-block-sm">
										<Col>
											<Form.Label>Name*</Form.Label>
											<Form.Control
												type="text"
												className={`form-input input-lg text-sm rounded-4 ${
													assetErrors.includes("assetName") && "border-danger"
												}`}
												placeholder="Enter asset name."
												value={assetName}
												onChange={(e) => setAssetName(e.target.value)}
											/>
										</Col>
									</Row>
									{assetType === "Login" && (
										<>
											<Row className="form-block-sm">
												<Col>
													<Form.Label>
														URL{" "}
														<span className="opacity-5 text-sm">
															(Optional)
														</span>
													</Form.Label>
													<Form.Control
														type="text"
														className={`form-input input-lg text-sm rounded-4 ${
															assetErrors.includes("assetUrl") &&
															"border-danger"
														}`}
														placeholder="Enter website URL."
														value={assetUrl}
														onChange={(e) => setAssetUrl(e.target.value)}
													/>
												</Col>
											</Row>
											<Row className="form-block-sm">
												<Col>
													<Form.Label>Username*</Form.Label>
													<div className="flex-row-left full">
														<Form.Control
															type="text"
															className={`asset-username form-input input-lg text-sm rounded-4 ${
																assetErrors.includes("username") &&
																"border-danger"
															}`}
															placeholder="Enter username/email."
															value={username}
															onChange={(e) => setUsername(e.target.value)}
														/>
														<div className="asset-pw-actions flex-row-right">
															<Button
																variant="light flex-column"
																onClick={() => copyAssetItem(null, username)}
															>
																<i className="ri-file-copy-line opacity-7" />
															</Button>
														</div>
													</div>
												</Col>
											</Row>
											<Row className="form-block-sm">
												<Col>
													<Form.Label>Password*</Form.Label>
													<div className="flex-row-left full">
														<Form.Control
															type={showPassword ? "text" : "password"}
															className={`asset-password form-input input-lg text-sm rounded-4 ${
																assetErrors.includes("password") &&
																"border-danger"
															}`}
															placeholder="Enter password."
															value={password}
															onChange={(e) => setPassword(e.target.value)}
														/>
														<div className="asset-pw-actions flex-row-right">
															<Button
																variant="light flex-column"
																onClick={() => setShowPassword(!showPassword)}
															>
																<i
																	className={`opacity-7 ${
																		showPassword
																			? "ri-eye-off-line"
																			: "ri-eye-line"
																	}`}
																/>
															</Button>
															<Button
																variant="light flex-column"
																onClick={generatePassword}
															>
																<i className="ri-refresh-line opacity-7" />
															</Button>
															<Button
																variant="light flex-column"
																onClick={() => copyAssetItem(null, password)}
															>
																<i className="ri-file-copy-line opacity-7" />
															</Button>
														</div>
													</div>
												</Col>
											</Row>
											<Row className="form-block-sm">
												<Col>
													<Form.Label>
														Authenticator Key (TOTP){" "}
														<span className="opacity-5 text-sm">
															(Optional)
														</span>
													</Form.Label>
													<div className="flex-row-left full">
														<Form.Control
															type="text"
															className={`asset-code form-input input-lg text-sm rounded-4 ${
																assetErrors.includes("assetCode") &&
																"border-danger"
															}`}
															placeholder="Enter 2FA setup key."
															value={assetCode}
															onChange={(e) => setAssetCode(e.target.value)}
														/>
														<div className="asset-pw-actions flex-row-right">
															<input
																ref={fileRef}
																type="file"
																accept="image/png, image/jpeg"
																onChange={handleFileInputChange}
																className="hidden"
															/>
															<Button
																variant="light flex-column"
																onClick={() => fileRef.current.click()}
															>
																<i className="ri-qr-code-line opacity-7" />
															</Button>
														</div>
													</div>
												</Col>
											</Row>
											<Row className="form-block-sm">
												<Col>
													<Form.Label>
														Notes{" "}
														<span className="opacity-5 text-sm">
															(Optional)
														</span>
													</Form.Label>
													<Form.Control
														as="textarea"
														className={`form-input input-lg text-sm rounded-4 ${
															assetErrors.includes("assetNotes") &&
															"border-danger"
														}`}
														placeholder="Add notes"
														value={assetNotes}
														onChange={(e) => setAssetNotes(e.target.value)}
													/>
												</Col>
											</Row>
										</>
									)}
									{assetType === "MFA" && (
										<Row className="form-block-sm">
											<Col>
												<Form.Label>Authenticator Key (TOTP)*</Form.Label>
												<div className="flex-row-left full">
													<Form.Control
														type="text"
														className={`asset-code form-input input-lg text-sm rounded-4 ${
															assetErrors.includes("assetCode") &&
															"border-danger"
														}`}
														placeholder="Enter 2FA setup key."
														value={assetCode}
														onChange={(e) => setAssetCode(e.target.value)}
													/>
													<div className="asset-pw-actions flex-row-right">
														<input
															ref={fileRef}
															type="file"
															accept="image/png, image/jpeg"
															onChange={handleFileInputChange}
															className="hidden"
														/>
														<Button
															variant="light flex-column"
															onClick={() => fileRef.current.click()}
														>
															<i className="ri-qr-code-line opacity-7" />
														</Button>
													</div>
												</div>
											</Col>
										</Row>
									)}
									{assetType === "Card" && (
										<>
											<Row className="form-block-sm">
												<Col>
													<Form.Label>Card Number*</Form.Label>
													<div className="flex-row-left pull">
														<Form.Control
															type="text"
															className={`asset-card-info form-input input-lg text-sm rounded-4 ${
																assetErrors.includes("cardNo") &&
																"border-danger"
															}`}
															placeholder="Enter card number."
															value={cardNo}
															onChange={handleCardNoChange}
														/>
														<div className="asset-pw-actions flex-row-right">
															<Button
																variant="light flex-column"
																onClick={() =>
																	copyAssetItem(null, cardNo.replace(/\s/g, ""))
																}
															>
																<i className="ri-file-copy-line opacity-7" />
															</Button>
														</div>
													</div>
												</Col>
											</Row>
											<Row className="form-block-sm">
												<Col>
													<Form.Label>Expiration*</Form.Label>
													<div className="flex-row-left full">
														<Form.Control
															type="text"
															className={`asset-card-info form-input input-lg text-sm rounded-4 ${
																assetErrors.includes("cardExpiry") &&
																"border-danger"
															}`}
															placeholder="Enter card expiration."
															value={cardExpiry}
															onChange={handleCardExpiryChange}
														/>
														<div className="asset-pw-actions flex-row-right">
															<Button
																variant="light flex-column"
																onClick={() => copyAssetItem(null, cardExpiry)}
															>
																<i className="ri-file-copy-line opacity-7" />
															</Button>
														</div>
													</div>
												</Col>
												<Col>
													<Form.Label>Security Code*</Form.Label>
													<div className="flex-row-left full">
														<Form.Control
															type="password"
															className={`asset-card-info form-input input-lg text-sm rounded-4 ${
																assetErrors.includes("cardCode") &&
																"border-danger"
															}`}
															placeholder="Enter security code."
															value={cardCode}
															onChange={(e) => setCardCode(e.target.value)}
														/>
														<div className="asset-pw-actions flex-row-right">
															<Button
																variant="light flex-column"
																onClick={() => copyAssetItem(null, cardCode)}
															>
																<i className="ri-file-copy-line opacity-7" />
															</Button>
														</div>
													</div>
												</Col>
											</Row>
											<Row className="form-block-sm">
												<Col>
													<Form.Label>Name on Card*</Form.Label>
													<div className="flex-row-left full">
														<Form.Control
															type="text"
															className={`asset-card-info form-input input-lg text-sm rounded-4 ${
																assetErrors.includes("cardName") &&
																"border-danger"
															}`}
															placeholder="Enter name on card."
															value={cardName}
															onChange={(e) => setCardName(e.target.value)}
														/>
														<div className="asset-pw-actions flex-row-right">
															<Button
																variant="light flex-column"
																onClick={() => copyAssetItem(null, cardName)}
															>
																<i className="ri-file-copy-line opacity-7" />
															</Button>
														</div>
													</div>
												</Col>
											</Row>
										</>
									)}
									{assetType === "Address" && (
										<>
											<Row className="form-block-sm">
												<Col>
													<Form.Label>Street Address*</Form.Label>
													<div className="flex-row-left full">
														<Form.Control
															as="textarea"
															className={`asset-address form-input input-lg text-sm rounded-4 ${
																assetErrors.includes("street") &&
																"border-danger"
															}`}
															placeholder="Enter street address."
															value={street}
															onChange={(e) => setStreet(e.target.value)}
														/>
														<div className="asset-textarea-actions flex-row-right">
															<Button
																variant="light flex-column"
																onClick={() => copyAssetItem(null, street)}
															>
																<i className="ri-file-copy-line opacity-7" />
															</Button>
														</div>
													</div>
												</Col>
											</Row>
											<Row className="form-block-sm">
												<Col>
													<Form.Label>City/Town*</Form.Label>
													<div className="flex-row-left full">
														<Form.Control
															type="text"
															className={`asset-address form-input input-lg text-sm rounded-4 ${
																assetErrors.includes("city") && "border-danger"
															}`}
															placeholder="Enter city/town."
															value={city}
															onChange={(e) => setCity(e.target.value)}
														/>
														<div className="asset-pw-actions flex-row-right">
															<Button
																variant="light flex-column"
																onClick={() => copyAssetItem(null, city)}
															>
																<i className="ri-file-copy-line opacity-7" />
															</Button>
														</div>
													</div>
												</Col>
												<Col>
													<Form.Label>State/Province*</Form.Label>
													<div className="flex-row-left full">
														<Form.Control
															type="text"
															className={`asset-address form-input input-lg text-sm rounded-4 ${
																assetErrors.includes("province") &&
																"border-danger"
															}`}
															placeholder="Enter state/province."
															value={province}
															onChange={(e) => setProvince(e.target.value)}
														/>
														<div className="asset-pw-actions flex-row-right">
															<Button
																variant="light flex-column"
																onClick={() => copyAssetItem(null, province)}
															>
																<i className="ri-file-copy-line opacity-7" />
															</Button>
														</div>
													</div>
												</Col>
											</Row>
											<Row className="form-block-sm">
												<Col>
													<Form.Label>Country*</Form.Label>
													<div className="flex-row-left full">
														<Form.Control
															type="text"
															className={`asset-address form-input input-lg text-sm rounded-4 ${
																assetErrors.includes("country") &&
																"border-danger"
															}`}
															placeholder="Enter country."
															value={country}
															onChange={(e) => setCountry(e.target.value)}
														/>
														<div className="asset-pw-actions flex-row-right">
															<Button
																variant="light flex-column"
																onClick={() => copyAssetItem(null, country)}
															>
																<i className="ri-file-copy-line opacity-7" />
															</Button>
														</div>
													</div>
												</Col>
												<Col>
													<Form.Label>Postal Code*</Form.Label>
													<div className="flex-row-left full">
														<Form.Control
															type="text"
															className={`asset-address form-input input-lg text-sm rounded-4 ${
																assetErrors.includes("postalCode") &&
																"border-danger"
															}`}
															placeholder="Enter postal code."
															value={postalCode}
															onChange={(e) => setPostalCode(e.target.value)}
														/>
														<div className="asset-pw-actions flex-row-right">
															<Button
																variant="light flex-column"
																onClick={() => copyAssetItem(null, postalCode)}
															>
																<i className="ri-file-copy-line opacity-7" />
															</Button>
														</div>
													</div>
												</Col>
											</Row>
										</>
									)}
									{assetType === "API" && (
										<>
											<Row className="form-block-sm">
												<Col>
													<Form.Label>API Key*</Form.Label>
													<div className="flex-row-left full">
														<Form.Control
															type="text"
															className={`asset-card-info form-input input-lg text-sm rounded-4 ${
																assetErrors.includes("apiKey") &&
																"border-danger"
															}`}
															placeholder="Enter API key."
															value={apiKey}
															onChange={(e) => setApiKey(e.target.value)}
														/>
														<div className="asset-pw-actions flex-row-right">
															<Button
																variant="light flex-column"
																onClick={() => copyAssetItem(null, apiKey)}
															>
																<i className="ri-file-copy-line opacity-7" />
															</Button>
														</div>
													</div>
												</Col>
											</Row>
											<Row className="form-block-sm">
												<Col>
													<Form.Label>
														Secret{" "}
														<span className="opacity-5 text-sm">
															(Optional)
														</span>
													</Form.Label>
													<div className="flex-row-left full">
														<Form.Control
															type="text"
															className={`asset-card-info form-input input-lg text-sm rounded-4 ${
																assetErrors.includes("apiSecret") &&
																"border-danger"
															}`}
															placeholder="Enter secret key."
															value={apiSecret}
															onChange={(e) => setApiSecret(e.target.value)}
														/>
														<div className="asset-pw-actions flex-row-right">
															<Button
																variant="light flex-column"
																onClick={() => copyAssetItem(null, apiSecret)}
															>
																<i className="ri-file-copy-line opacity-7" />
															</Button>
														</div>
													</div>
												</Col>
											</Row>
											<Row className="form-block-sm">
												<Col>
													<Form.Label>
														Documentation{" "}
														<span className="opacity-5 text-sm">
															(Optional)
														</span>
													</Form.Label>
													<div className="flex-row-left full">
														<Form.Control
															type="text"
															className={`asset-card-info form-input input-lg text-sm rounded-4 ${
																assetErrors.includes("docs") && "border-danger"
															}`}
															placeholder="Enter documentation URL."
															value={docs}
															onChange={(e) => setDocs(e.target.value)}
														/>
													</div>
												</Col>
											</Row>
										</>
									)}
									{assetType === "Contact" && (
										<>
											<Row className="form-block-sm">
												<Col>
													<Form.Label>Contact Name*</Form.Label>
													<div className="flex-row-left full">
														<Form.Control
															type="text"
															className={`asset-card-info form-input input-lg text-sm rounded-4 ${
																assetErrors.includes("contactName") &&
																"border-danger"
															}`}
															placeholder="Enter contact name."
															value={contactName}
															onChange={(e) => setContactName(e.target.value)}
														/>
													</div>
												</Col>
											</Row>
											<Row className="form-block-sm">
												<Col>
													<Form.Label>
														Email{" "}
														<span className="opacity-5 text-sm">
															(Optional)
														</span>
													</Form.Label>
													<div className="flex-row-left full">
														<Form.Control
															type="email"
															className={`asset-card-info form-input input-lg text-sm rounded-4 ${
																assetErrors.includes("contactEmail") &&
																"border-danger"
															}`}
															placeholder="Enter contact email."
															value={contactEmail}
															onChange={(e) => setContactEmail(e.target.value)}
														/>
													</div>
												</Col>
												<Col>
													<Form.Label>
														Contact Number{" "}
														<span className="opacity-5 text-sm">
															(Optional)
														</span>
													</Form.Label>
													<div className="flex-row-left full">
														<Form.Control
															type="text"
															className={`asset-card-info form-input input-lg text-sm rounded-4 ${
																assetErrors.includes("contactMsisdn") &&
																"border-danger"
															}`}
															placeholder="Enter contact number."
															value={contactMsisdn}
															onChange={(e) => setContactMsisdn(e.target.value)}
														/>
													</div>
												</Col>
											</Row>
										</>
									)}
									<Button
										type="submit"
										className="btn-default btn-md rounded-4 full flex-column mt-5"
										disabled={actionLoading}
									>
										{actionLoading
											? "Setting up your account.."
											: "Finish Setup"}
									</Button>
								</Form>
							</Accordion.Body>
						</Accordion.Item>
					</Accordion>
				</div>
			</Container>
		</div>
	);
};

export default Onboarding;
