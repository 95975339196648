import { useEffect, useState, useContext } from "react";
import { UserContext } from "../context/UserContext";
import { useSearchParams, useNavigate } from "react-router-dom";
import { useSnackbar } from "react-simple-snackbar";
import LogoDark from "../components/LogoDark";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";

const Invite = () => {
	document.title = "Invitation | SMSafe";
	const navigate = useNavigate();
	const [user, setUser] = useContext(UserContext);
	const [searchParams] = useSearchParams();
	const token = searchParams.get("token");
	const [loading, setLoading] = useState(false);
	const [username, setUsername] = useState("");
	const [nameLoading, setNameLoading] = useState(false);
	const [password, setPassword] = useState("");
	const [nameMessage, setNameMessage] = useState("");
	const [passwordMessage, setPasswordMessage] = useState("");
	const [passwordLoading, setPasswordLoading] = useState(false);
	const [ready, setReady] = useState(false);

	const [defaultSnackbar] = useSnackbar();

	const [dangerSnackbar] = useSnackbar({
		style: {
			zIndex: 10,
			backgroundColor: "#d81e5b",
			color: "#fff",
		},
	});

	const acceptInvite = async (e) => {
		e.preventDefault();
		setLoading(true);
		setNameMessage("");
		setPasswordMessage("");
		const requestOptions = {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify({
				username: username,
				password: password,
				token: token,
			}),
		};

		const response = await fetch(
			`${process.env.REACT_APP_API_URI}/users/invite/accept`,
			requestOptions
		);
		const data = await response.json();

		if (!response.ok) {
			if (data.error == "username") {
				setNameMessage({ is_valid: false, msg: data.msg });
			}
			setLoading(false);
		} else {
			setUser(data.user_session);
		}
	};

	const validatePassword = () => {
		setPasswordMessage("");
		const hasNumber = /\d/.test(password);
		const hasCapitalLetter = /[A-Z]/.test(password);
		const hasSpecialCharacter = /[!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]/.test(
			password
		);

		if (hasNumber && hasCapitalLetter && hasSpecialCharacter) {
			setPasswordMessage({
				is_valid: true,
				msg: "Now that's a strong password!",
			});
		} else {
			setPasswordMessage({
				is_valid: false,
				msg: "Must have at least 1 number, 1 uppercase letter, and 1 special character.",
			});
		}
	};

	const validateUsername = async () => {
		setNameLoading(true);
		setNameMessage("");
		const requestOptions = {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
			},
		};

		const response = await fetch(
			`${process.env.REACT_APP_API_URI}/users/usernames/validate/${username}`,
			requestOptions
		);
		const data = await response.json();

		if (!response.ok) {
			setNameMessage({ is_valid: false, msg: data.msg });
		} else {
			setNameMessage({ is_valid: true, msg: data.msg });
		}
		setNameLoading(false);
	};

	useEffect(() => {
		const timer = setTimeout(() => {
			if (password) {
				validatePassword();
			} else {
				setPasswordLoading(false);
				setPasswordMessage(null);
			}
		}, 500);
		return () => clearTimeout(timer);
	}, [password]);

	useEffect(() => {
		if (
			username &&
			password &&
			nameMessage?.is_valid &&
			passwordMessage?.is_valid
		) {
			setReady(true);
		} else {
			setReady(false);
		}
	}, [username, password, nameMessage, passwordMessage]);

	useEffect(() => {
		const timer = setTimeout(() => {
			if (username) {
				validateUsername();
			} else {
				setNameLoading(false);
				setNameMessage(null);
			}
		}, 500);
		return () => clearTimeout(timer);
	}, [username]);

	useEffect(() => {
		if (user) {
			navigate("/");
		}
	}, [user]);

	return (
		<div className="full-page bg-gray flex-column">
			<Container className="flex-column">
				<div className="col-lg-4 col-md-6 col-12 p-30 bg-white rounded flex-column">
					<LogoDark className="verify-logo" />
					<Form onSubmit={acceptInvite} className="full">
						<h1 className="text-medium-inter text-lg align-center mb-30">
							Create Username
						</h1>
						<Row className="form-block">
							<Col>
								<Form.Label>Username</Form.Label>
								<Form.Control
									required
									type="text"
									className={`form-input rounded ${
										nameMessage && !nameMessage.is_valid && "error"
									}`}
									placeholder="Enter Username"
									onChange={(e) => setUsername(e.target.value)}
								/>
								<div className="login-error-msg full align-left">
									{nameLoading ? (
										<div className="ri-loader-3-line rotating fit mt-1" />
									) : nameMessage ? (
										<div className="full align-left mt-1">
											<span
												className={`label-md opacity-7 ${
													nameMessage.is_valid ? "text-success" : "text-danger"
												}`}
											>
												{nameMessage.msg}
											</span>
										</div>
									) : (
										""
									)}
								</div>
							</Col>
						</Row>
						<Row className="form-block">
							<Col>
								<Form.Label>Password</Form.Label>
								<Form.Control
									type="password"
									className={`form-input ${
										passwordMessage && !passwordMessage.is_valid && "error"
									}`}
									placeholder="Enter password"
									value={password}
									onChange={(e) => setPassword(e.target.value)}
								/>
								<div className="login-error-msg full align-left">
									{passwordLoading ? (
										<div className="ri-loader-3-line rotating fit mt-1" />
									) : passwordMessage ? (
										<div className="full align-left mt-1">
											<span
												className={`label-md opacity-7 ${
													passwordMessage.is_valid
														? "text-success"
														: "text-danger"
												}`}
											>
												{passwordMessage.msg}
											</span>
										</div>
									) : (
										""
									)}
								</div>
							</Col>
						</Row>
						<Row>
							<Col>
								<Button
									type="submit"
									className="btn-md btn-default text-sm full text-medium-inter"
									disabled={loading || !ready}
								>
									{loading ? "Saving.." : "Save"}
								</Button>
							</Col>
						</Row>
					</Form>
				</div>
			</Container>
		</div>
	);
};

export default Invite;
