import React, { createContext, useEffect, useState } from "react";

export const KeyContext = createContext();

export const KeyProvider = (props) => {
	const [key, setKey] = useState(localStorage.getItem("key") || null);

	useEffect(() => {
		if (key) {
			localStorage.setItem("key", key);
		} else {
			localStorage.removeItem("key");
		}
	}, [key]);

	return (
		<KeyContext.Provider value={[key, setKey]}>
			{props.children}
		</KeyContext.Provider>
	);
};
