import { useEffect, useState, useContext } from "react";
import { Link } from "react-router-dom";
import { ProfileContext } from "../context/ProfileContext";
import { useSearchParams, useNavigate } from "react-router-dom";
import Container from "react-bootstrap/Container";
import LogoDark from "../components/LogoDark";
import PageLoader from "../components/PageLoader";
import Button from "react-bootstrap/Button";

const LoginVerification = () => {
	document.title = "Verification | SMSafe";
	const navigate = useNavigate();
	const [, setProfile] = useContext(ProfileContext);
	const [searchParams] = useSearchParams();
	const token = searchParams.get("token");
	const [error, setError] = useState(null);
	const [loading, setLoading] = useState(true);

	const verifyLogin = async () => {
		setLoading(true);
		const requestOptions = { method: "POST" };

		const response = await fetch(
			`${process.env.REACT_APP_API_URI}/users/sessions/${token}/approve`,
			requestOptions
		);
		const data = await response.json();

		if (!response.ok) {
			setError(data.msg);
		} else {
			setProfile(data.profile);
		}
		setLoading(false);
	};

	useEffect(() => {
		if (token) {
			verifyLogin();
		}
	}, [token]);

	return (
		<div className="full-page bg-gray flex-column">
			{loading ? (
				<PageLoader className="full-page" />
			) : (
				<Container className="flex-column">
					<div className="col-lg-4 col-md-7 col-12 p-20 bg-white rounded flex-column">
						<LogoDark className="verify-logo" />
						{error ? (
							<div className="align-center">
								<div className="flex-row-center mb-15">
									<i className="ri-error-warning-line text-xl text-danger me-1" />
									<h1 className="mb-0 text-lg text-medium-inter">
										Verification Failed
									</h1>
								</div>
								<p className="opacity-5 align-center">{error}</p>
							</div>
						) : (
							<div className="align-center">
								<div className="flex-row-center mb-15">
									<i className="ri-shield-check-line text-xl text-success me-1" />
									<h1 className="mb-0 text-lg text-medium-inter">
										Log In Approved
									</h1>
								</div>
								<p className="opacity-5 align-center text-sm mb-30">
									Thank you, your log in attempt has been successfully approved.
								</p>
								<Link to="/">
									<Button className="btn-md rounded-4 btn-default full text-medium-inter">
										Go to Dashboard
									</Button>
								</Link>
							</div>
						)}
					</div>
				</Container>
			)}
		</div>
	);
};

export default LoginVerification;
