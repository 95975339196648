import Spinner from "react-bootstrap/Spinner";

const PageLoader = ({ className }) => {
	return (
		<div className={`page-loader flex-column ${className}`}>
			<Spinner className="page-loader-icon text-blue" />
		</div>
	);
};

export default PageLoader;
