const MailLogo = ({ className }) => {
	return (
		<svg
			className={className}
			width="166"
			height="150"
			viewBox="0 0 166 150"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<g clip-path="url(#clip0_46_2171)">
				<path
					opacity="0.1"
					fillRule="evenodd"
					clipRule="evenodd"
					d="M33.5926 130.591C15.0363 114.412 -1.90904 92.2008 2.01182 67.8965C5.81074 44.3481 30.7125 33.0917 51.2725 20.9986C70.7087 9.56652 91.1447 -3.24866 112.903 2.67178C136.237 9.02106 154.293 28.1887 161.971 51.1199C169.529 73.6896 165.074 98.5801 151.583 118.188C138.758 136.828 117.28 146.412 94.7778 148.777C72.5153 151.117 50.4654 145.302 33.5926 130.591Z"
					fill="#3C57CC"
				/>
				<mask
					id="mask0_46_2171"
					style={{ maskType: "alpha" }}
					maskUnits="userSpaceOnUse"
					x="1"
					y="1"
					width="165"
					height="149"
				>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M33.5965 130.591C15.0402 114.412 -1.90513 92.2008 2.01573 67.8965C5.81465 44.3481 30.7164 33.0917 51.2764 20.9986C70.7126 9.56652 91.1486 -3.24866 112.907 2.67178C136.241 9.02106 154.297 28.1887 161.975 51.1199C169.533 73.6896 165.078 98.5801 151.587 118.188C138.762 136.828 117.284 146.412 94.7817 148.777C72.5192 151.117 50.4693 145.302 33.5965 130.591Z"
						fill="#3C57CC"
					/>
				</mask>
				<g mask="url(#mask0_46_2171)">
					<path
						d="M130.57 158.179H41.1085C34.9328 158.179 29.9258 153.172 29.9258 146.997V88.5195C29.9258 84.8683 31.7066 81.452 34.6952 79.3608L85.8393 43.5566L136.983 79.3608C139.972 81.452 141.753 84.8683 141.753 88.5195V146.997C141.753 153.172 136.746 158.179 130.57 158.179Z"
						fill="url(#paint0_linear_46_2171)"
					/>
					<path
						d="M52.291 74.3086H119.387V135.813H52.291V74.3086Z"
						fill="white"
					/>
					<path
						d="M130.57 158.179H41.1085C34.9328 158.179 29.9258 153.172 29.9258 146.996V91.083L85.8393 127.427L141.753 91.083V146.996C141.753 153.172 136.746 158.179 130.57 158.179Z"
						fill="url(#paint1_linear_46_2171)"
					/>
					<path
						d="M60.6797 88.2871H111.002V93.8785H60.6797V88.2871ZM60.6797 99.4698H94.2278V105.061H60.6797V99.4698Z"
						fill="#3C57CC"
					/>
				</g>
			</g>
			<defs>
				<linearGradient
					id="paint0_linear_46_2171"
					x1="85.8393"
					y1="110.653"
					x2="85.8393"
					y2="-1.81481"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#3C57CC" />
					<stop offset="1" stopColor="#4E96F7" />
				</linearGradient>
				<linearGradient
					id="paint1_linear_46_2171"
					x1="85.8393"
					y1="130.359"
					x2="85.8393"
					y2="64.5241"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#4E96F7" />
					<stop offset="1" stopColor="#3C57CC" />
				</linearGradient>
				<clipPath id="clip0_46_2171">
					<rect
						width="165.566"
						height="150"
						fill="white"
						transform="translate(0.216797)"
					/>
				</clipPath>
			</defs>
		</svg>
	);
};

export default MailLogo;
