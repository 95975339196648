import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useSnackbar } from "react-simple-snackbar";
import QRCode from "qrcode.react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import AssetMFA from "../components/AssetMFA";
import PageLoader from "../components/PageLoader";
import LogoDark from "../components/LogoDark";
import AssetTimer from "../components/AssetTimer";

const PublicAsset = () => {
	let { id } = useParams();
	document.title = "Asset Sharing | SMSafe";
	const [loading, setLoading] = useState(false);
	const [assetInfo, setAssetInfo] = useState(null);
	const [assetName, setAssetName] = useState("");
	const [assetType, setAssetType] = useState("Login");
	const [assetUrl, setAssetUrl] = useState("");
	const [assetNotes, setAssetNotes] = useState("");
	const [username, setUsername] = useState("");
	const [password, setPassword] = useState("");
	const [assetCode, setAssetCode] = useState("");
	const [assetMsisdn, setAssetMsisdn] = useState("");
	const [cardNo, setCardNo] = useState("");
	const [cardExpiry, setCardExpiry] = useState("");
	const [cardCode, setCardCode] = useState("");
	const [cardName, setCardName] = useState("");
	const [apiKey, setApiKey] = useState("");
	const [apiSecret, setApiSecret] = useState("");
	const [docs, setDocs] = useState("");
	const [street, setStreet] = useState("");
	const [city, setCity] = useState("");
	const [province, setProvince] = useState("");
	const [country, setCountry] = useState("");
	const [postalCode, setPostalCode] = useState("");
	const [wifiEncyption, setWifiEncryption] = useState("WPA2");
	const [wifiName, setWifiName] = useState("");
	const [wifiPassword, setWifiPassword] = useState("");
	const [wifiIsHidden, setWifiIsHidden] = useState(false);
	const [showWifiPassword, setShowWifiPassword] = useState(false);

	const [contactName, setContactName] = useState("");
	const [contactEmail, setContactEmail] = useState("");
	const [contactMsisdn, setContactMsisdn] = useState("");

	const [showPassword, setShowPassword] = useState(false);
	const [showAssetCode, setShowAssetCode] = useState(false);
	const [error, setError] = useState("");
	const [expiresAt, setExpiresAt] = useState(null);
	const [verificationEmail, setVerificationEmail] = useState(null);
	const [verificationLoading, setVerificationLoading] = useState(false);
	const [verifying, setVerifying] = useState(false);
	const [token, setToken] = useState("");

	const [defaultSnackbar] = useSnackbar();

	const [dangerSnackbar] = useSnackbar({
		style: {
			zIndex: 10,
			backgroundColor: "#d81e5b",
			color: "#fff",
		},
	});

	const getData = async (e) => {
		e.preventDefault();
		setVerifying(true);

		const requestOptions = {
			method: "POST",
			body: JSON.stringify({
				token: token,
			}),
		};

		const response = await fetch(
			`${process.env.REACT_APP_API_URI}/users/assets/sharing/${id}`,
			requestOptions
		);
		const data = await response.json();

		if (!response.ok) {
			if (data.type === "code") {
				dangerSnackbar(data.msg);
			} else {
				setError(data.msg);
			}
		} else {
			document.title = `${data.asset.name} | SMSafe`;
			setAssetInfo(data.asset);
			setAssetName(data.asset.name);
			setAssetType(data.asset.type_);
			setAssetUrl(data.asset.url);
			setUsername(data.asset.username);
			setPassword(data.asset.password);
			setAssetCode(data.asset.mfa_token?.token || "");
			setCardNo(data.asset.card_no);
			setCardExpiry(data.asset.expiration);
			setCardCode(data.asset.cvv);
			setCardName(data.asset.card_name);
			setApiKey(data.asset.api_key);
			setApiSecret(data.asset.api_secret);
			setDocs(data.asset.documentation);
			setStreet(data.asset.street);
			setCity(data.asset.city);
			setProvince(data.asset.province);
			setCountry(data.asset.country);
			setPostalCode(data.asset.postal_code);
			setContactName(data.asset.contact_name);
			setContactEmail(data.asset.contact_email);
			setContactMsisdn(data.asset.contact_msisdn);
			setAssetNotes(data.asset.notes || "");
			setExpiresAt(data.expires_at);
			setWifiEncryption(data.asset.wifi_encryption);
			setWifiName(data.asset.wifi_name);
			setWifiPassword(data.asset.wifi_password);
			setWifiIsHidden(data.asset.wifi_is_hidden);
		}
		setToken("");
		setVerifying(false);
		setLoading(false);
	};

	const copyAssetItem = async (asset_id, value) => {
		if (asset_id) {
			navigator.clipboard.writeText(value);
			defaultSnackbar("Copied to clipboard.");
		} else {
			navigator.clipboard.writeText(value);
			defaultSnackbar("Copied to clipboard.");
		}
	};

	const handleCardNoChange = (e) => {
		let formattedCardNo = e.target.value.replace(/\s/g, ""); // Remove any existing spaces
		formattedCardNo = formattedCardNo.replace(/(\d{4})/g, "$1 ").trim(); // Add space after every 4 characters

		setCardNo(formattedCardNo);
	};

	const handleCardExpiryChange = (e) => {
		let formattedCardExpiry = e.target.value.replace(/\s/g, ""); // Remove any existing spaces
		formattedCardExpiry = formattedCardExpiry
			.replace(/^(\d{2})(\d{1,2})$/, "$1/$2") // Add '/' after first 2 characters if not already added
			.replace(/^(\d{2})\/(\d{2})$/, "$1/$2"); // Add '/' after next 2 characters if not already added

		setCardExpiry(formattedCardExpiry);
	};

	const sendVerification = async () => {
		setVerificationLoading(true);

		const requestOptions = { method: "POST" };

		const response = await fetch(
			`${process.env.REACT_APP_API_URI}/users/assets/sharing/${id}/verify`,
			requestOptions
		);
		const data = await response.json();

		if (!response.ok) {
			setError(data.msg);
		} else {
			setVerificationEmail(data.email);
			defaultSnackbar(data.msg);
		}
		setVerificationLoading(false);
	};

	return (
		<div className="full-page flex-column pt-5 pb-5">
			<Container className="flex-column">
				{loading ? (
					<PageLoader />
				) : error ? (
					<div className="flex-column">
						<LogoDark className="mb-30" />
						<p className="mb-30 align-center">{error}</p>
						<a href={process.env.REACT_APP_HOST}>
							<Button className="btn-default rounded-4 btn-sm">
								Back to Homepage
							</Button>
						</a>
					</div>
				) : assetInfo ? (
					<>
						<div className="bg-white rounded border p-20 col-lg-5 col-md-7 col-12 mb-30">
							<div className="mb-20">
								<LogoDark className="block mb-20" />
								<AssetTimer expiresAt={expiresAt} getData={getData} />
								<div className="access-warning-container rounded-4 p-10 mb-30">
									<span className="text-sm">
										Please do not refresh this page. Doing so will revoke your
										one-time access.
									</span>
								</div>
							</div>
							{assetType === "Wifi" && (
								<Row className="form-block-sm pt-4">
									<Col className="flex-column">
										<QRCode
											value={`WIFI:T:${wifiEncyption};S:${wifiName};P:${wifiPassword};H:${wifiIsHidden};;`}
											className="wifi-qr mb-10"
										/>
										<span className="text-sm opacity-7">
											Scan this QR to connect.
										</span>
									</Col>
								</Row>
							)}
							<Row className="form-block-sm">
								<Col>
									<Form.Label>Name</Form.Label>
									<Form.Control
										type="text"
										className="form-input"
										placeholder={
											assetInfo?.public_allow_edit ? "Enter asset name." : ""
										}
										value={assetName}
										onChange={(e) => setAssetName(e.target.value)}
										disabled={!assetInfo?.public_allow_edit}
									/>
								</Col>
							</Row>
							{assetType === "Login" && (
								<>
									<Row className="form-block-sm">
										<Col>
											<Form.Label>
												URL{" "}
												<span className="opacity-5 text-sm">(Optional)</span>
											</Form.Label>
											<Form.Control
												type="text"
												className="form-input"
												placeholder={
													assetInfo?.public_allow_edit
														? "Enter website URL"
														: ""
												}
												value={assetUrl}
												onChange={(e) => setAssetUrl(e.target.value)}
												disabled={!assetInfo?.public_allow_edit}
											/>
										</Col>
									</Row>
									<Row className="form-block-sm">
										<Col>
											<Form.Label>Username</Form.Label>
											<div className="flex-row-left full">
												<Form.Control
													type="text"
													className="form-input"
													placeholder={
														assetInfo?.public_allow_edit
															? "Enter username/email"
															: ""
													}
													value={username}
													onChange={(e) => setUsername(e.target.value)}
													disabled={!assetInfo?.public_allow_edit}
												/>
												<div className="asset-pw-actions flex-row-right">
													<Button
														variant="light flex-column"
														onClick={() => copyAssetItem(null, username)}
													>
														<i className="ri-file-copy-line opacity-7" />
													</Button>
												</div>
											</div>
										</Col>
									</Row>
									<Row className="form-block-sm">
										<Col>
											<Form.Label>Password</Form.Label>
											<div className="flex-row-left full">
												<Form.Control
													type={showPassword ? "text" : "password"}
													className="form-input"
													placeholder={
														assetInfo?.public_allow_edit
															? "Enter password."
															: ""
													}
													value={password}
													onChange={(e) => setPassword(e.target.value)}
													disabled={!assetInfo?.public_allow_edit}
												/>
												<div className="asset-pw-actions flex-row-right">
													<Button
														variant="light flex-column"
														onClick={() => setShowPassword(!showPassword)}
													>
														<i
															className={`opacity-7 ${
																showPassword ? "ri-eye-off-line" : "ri-eye-line"
															}`}
														/>
													</Button>
													<Button
														variant="light flex-column"
														onClick={() => copyAssetItem(null, password)}
													>
														<i className="ri-file-copy-line opacity-7" />
													</Button>
												</div>
											</div>
										</Col>
									</Row>
									<Row className="form-block-sm">
										<Col>
											<Form.Label>Authenticator Key (TOTP)</Form.Label>
											{assetInfo?.mfa_token && !showAssetCode ? (
												<AssetMFA
													key={assetInfo.mfa_token.id}
													mfa={assetInfo.mfa_token}
													showCode={setShowAssetCode}
													canEdit={!assetInfo?.public_allow_edit}
												/>
											) : (
												<div className="flex-row-left full">
													<Form.Control
														type="text"
														className="form-input"
														placeholder={
															assetInfo?.public_allow_edit
																? "Enter 2FA setup key."
																: ""
														}
														value={assetCode}
														onChange={(e) => setAssetCode(e.target.value)}
														disabled={!assetInfo?.public_allow_edit}
													/>
												</div>
											)}
										</Col>
									</Row>
									<Row className="form-block-sm">
										<Col>
											<Form.Label>Phone Number</Form.Label>
											<Form.Select
												className="form-input"
												placeholder={
													assetInfo?.public_allow_edit
														? "Enter virtual phone number."
														: ""
												}
												value={assetMsisdn}
												onChange={(e) => setAssetMsisdn(e.target.value)}
												disabled={!assetInfo?.public_allow_edit}
											></Form.Select>
										</Col>
									</Row>
									<Row className="form-block-sm">
										<Col>
											<Form.Label>Notes</Form.Label>
											<Form.Control
												as="textarea"
												className="form-input asset-notes text-sm rounded-4"
												placeholder={
													assetInfo?.public_allow_edit ? "Add notes" : ""
												}
												value={assetNotes}
												onChange={(e) => setAssetNotes(e.target.value)}
												disabled={!assetInfo?.public_allow_edit}
											/>
										</Col>
									</Row>
								</>
							)}
							{assetType === "MFA" && (
								<Row className="form-block-sm">
									<Col>
										<Form.Label>Authenticator Key (TOTP)</Form.Label>
										{assetInfo?.mfa_token && !showAssetCode ? (
											<AssetMFA
												key={assetInfo.mfa_token.id}
												mfa={assetInfo.mfa_token}
												showCode={setShowAssetCode}
												canEdit={!assetInfo?.public_allow_edit}
											/>
										) : (
											<div className="flex-row-left full">
												<Form.Control
													type="text"
													className="form-input"
													placeholder={
														assetInfo?.public_allow_edit
															? "Enter 2FA setup key."
															: ""
													}
													value={assetCode}
													onChange={(e) => setAssetCode(e.target.value)}
													disabled={!assetInfo?.public_allow_edit}
												/>
											</div>
										)}
									</Col>
								</Row>
							)}
							{assetType === "Card" && (
								<>
									<Row className="form-block-sm">
										<Col>
											<Form.Label>Card Number</Form.Label>
											<div className="flex-row-left pull">
												<Form.Control
													type="text"
													className="form-input"
													placeholder={
														assetInfo?.public_allow_edit
															? "Enter card number."
															: ""
													}
													value={cardNo}
													onChange={handleCardNoChange}
													disabled={!assetInfo?.public_allow_edit}
												/>
												<div className="asset-pw-actions flex-row-right">
													<Button
														variant="light flex-column"
														onClick={() =>
															copyAssetItem(null, cardNo.replace(/\s/g, ""))
														}
													>
														<i className="ri-file-copy-line opacity-7" />
													</Button>
												</div>
											</div>
										</Col>
									</Row>
									<Row className="form-block-sm">
										<Col>
											<Form.Label>Expiration</Form.Label>
											<div className="flex-row-left full">
												<Form.Control
													type="text"
													className="form-input"
													placeholder={
														assetInfo?.public_allow_edit
															? "Enter card expiration."
															: ""
													}
													value={cardExpiry}
													onChange={handleCardExpiryChange}
													disabled={!assetInfo?.public_allow_edit}
												/>
												<div className="asset-pw-actions flex-row-right">
													<Button
														variant="light flex-column"
														onClick={() => copyAssetItem(null, cardExpiry)}
													>
														<i className="ri-file-copy-line opacity-7" />
													</Button>
												</div>
											</div>
										</Col>
										<Col>
											<Form.Label>Security Code</Form.Label>
											<div className="flex-row-left full">
												<Form.Control
													type="password"
													className="form-input"
													placeholder={
														assetInfo?.public_allow_edit
															? "Enter security code."
															: ""
													}
													value={cardCode}
													onChange={(e) => setCardCode(e.target.value)}
													disabled={!assetInfo?.public_allow_edit}
												/>
												<div className="asset-pw-actions flex-row-right">
													<Button
														variant="light flex-column"
														onClick={() => copyAssetItem(null, cardCode)}
													>
														<i className="ri-file-copy-line opacity-7" />
													</Button>
												</div>
											</div>
										</Col>
									</Row>
									<Row className="form-block-sm">
										<Col>
											<Form.Label>Name on Card</Form.Label>
											<div className="flex-row-left full">
												<Form.Control
													type="text"
													className="form-input"
													placeholder={
														assetInfo?.public_allow_edit
															? "Enter name on card."
															: ""
													}
													value={cardName}
													onChange={(e) => setCardName(e.target.value)}
													disabled={!assetInfo?.public_allow_edit}
												/>
												<div className="asset-pw-actions flex-row-right">
													<Button
														variant="light flex-column"
														onClick={() => copyAssetItem(null, cardName)}
													>
														<i className="ri-file-copy-line opacity-7" />
													</Button>
												</div>
											</div>
										</Col>
									</Row>
								</>
							)}
							{assetType === "Address" && (
								<>
									<Row className="form-block-sm">
										<Col>
											<Form.Label>Street Address</Form.Label>
											<div className="flex-row-left full">
												<Form.Control
													type="text"
													className="form-input"
													placeholder={
														assetInfo?.public_allow_edit
															? "Enter street address."
															: ""
													}
													value={street}
													onChange={(e) => setStreet(e.target.value)}
													disabled={!assetInfo?.public_allow_edit}
												/>
												<div className="asset-pw-actions flex-row-right">
													<Button
														variant="light flex-column"
														onClick={() => copyAssetItem(null, street)}
													>
														<i className="ri-file-copy-line opacity-7" />
													</Button>
												</div>
											</div>
										</Col>
									</Row>
									<Row className="form-block-sm">
										<Col>
											<Form.Label>City/Town</Form.Label>
											<div className="flex-row-left full">
												<Form.Control
													type="text"
													className="form-input"
													placeholder={
														assetInfo?.public_allow_edit
															? "Enter city/town."
															: ""
													}
													value={city}
													onChange={(e) => setCity(e.target.value)}
													disabled={!assetInfo?.public_allow_edit}
												/>
												<div className="asset-pw-actions flex-row-right">
													<Button
														variant="light flex-column"
														onClick={() => copyAssetItem(null, city)}
													>
														<i className="ri-file-copy-line opacity-7" />
													</Button>
												</div>
											</div>
										</Col>
										<Col>
											<Form.Label>State/Province</Form.Label>
											<div className="flex-row-left full">
												<Form.Control
													type="text"
													className="form-input"
													placeholder={
														assetInfo?.public_allow_edit
															? "Enter state/province."
															: ""
													}
													value={province}
													onChange={(e) => setProvince(e.target.value)}
													disabled={!assetInfo?.public_allow_edit}
												/>
												<div className="asset-pw-actions flex-row-right">
													<Button
														variant="light flex-column"
														onClick={() => copyAssetItem(null, province)}
													>
														<i className="ri-file-copy-line opacity-7" />
													</Button>
												</div>
											</div>
										</Col>
									</Row>
									<Row className="form-block-sm">
										<Col>
											<Form.Label>Country</Form.Label>
											<div className="flex-row-left full">
												<Form.Control
													type="text"
													className="form-input"
													placeholder={
														assetInfo?.public_allow_edit ? "Enter country." : ""
													}
													value={country}
													onChange={(e) => setCountry(e.target.value)}
													disabled={!assetInfo?.public_allow_edit}
												/>
												<div className="asset-pw-actions flex-row-right">
													<Button
														variant="light flex-column"
														onClick={() => copyAssetItem(null, country)}
													>
														<i className="ri-file-copy-line opacity-7" />
													</Button>
												</div>
											</div>
										</Col>
										<Col>
											<Form.Label>Postal Code</Form.Label>
											<div className="flex-row-left full">
												<Form.Control
													type="text"
													className="form-input"
													placeholder={
														assetInfo?.public_allow_edit
															? "Enter postal code."
															: ""
													}
													value={postalCode}
													onChange={(e) => setPostalCode(e.target.value)}
													disabled={!assetInfo?.public_allow_edit}
												/>
												<div className="asset-pw-actions flex-row-right">
													<Button
														variant="light flex-column"
														onClick={() => copyAssetItem(null, postalCode)}
													>
														<i className="ri-file-copy-line opacity-7" />
													</Button>
												</div>
											</div>
										</Col>
									</Row>
								</>
							)}
							{assetType === "API" && (
								<>
									<Row className="form-block-sm">
										<Col>
											<Form.Label>API Key</Form.Label>
											<div className="flex-row-left full">
												<Form.Control
													type="text"
													className="form-input"
													placeholder={
														assetInfo?.public_allow_edit ? "Enter API key." : ""
													}
													value={apiKey}
													onChange={(e) => setApiKey(e.target.value)}
													disabled={!assetInfo?.public_allow_edit}
												/>
												<div className="asset-pw-actions flex-row-right">
													<Button
														variant="light flex-column"
														onClick={() => copyAssetItem(null, apiKey)}
													>
														<i className="ri-file-copy-line opacity-7" />
													</Button>
												</div>
											</div>
										</Col>
									</Row>
									<Row className="form-block-sm">
										<Col>
											<Form.Label>Secret</Form.Label>
											<div className="flex-row-left full">
												<Form.Control
													type="text"
													className="form-input"
													placeholder={
														assetInfo?.public_allow_edit
															? "Enter secret key."
															: ""
													}
													value={apiSecret}
													onChange={(e) => setApiSecret(e.target.value)}
													disabled={!assetInfo?.public_allow_edit}
												/>
												<div className="asset-pw-actions flex-row-right">
													<Button
														variant="light flex-column"
														onClick={() => copyAssetItem(null, apiSecret)}
													>
														<i className="ri-file-copy-line opacity-7" />
													</Button>
												</div>
											</div>
										</Col>
									</Row>
									<Row className="form-block-sm">
										<Col>
											<Form.Label>Documentation</Form.Label>
											<div className="flex-row-left full">
												<Form.Control
													type="text"
													className="form-input"
													placeholder={
														assetInfo?.public_allow_edit
															? "Enter documentation URL."
															: ""
													}
													value={docs}
													onChange={(e) => setDocs(e.target.value)}
													disabled={!assetInfo?.public_allow_edit}
												/>
											</div>
										</Col>
									</Row>
								</>
							)}
							{assetType === "Contact" && (
								<>
									<Row className="form-block-sm">
										<Col>
											<Form.Label>Contact Name</Form.Label>
											<div className="flex-row-left full">
												<Form.Control
													type="text"
													className="form-input"
													placeholder={
														assetInfo?.public_allow_edit
															? "Enter contact name."
															: ""
													}
													value={contactName}
													onChange={(e) => setContactName(e.target.value)}
													disabled={!assetInfo?.public_allow_edit}
												/>
											</div>
										</Col>
									</Row>
									<Row className="form-block-sm">
										<Col>
											<Form.Label>Email</Form.Label>
											<div className="flex-row-left full">
												<Form.Control
													type="email"
													className="form-input"
													placeholder={
														assetInfo?.public_allow_edit
															? "Enter contact email."
															: ""
													}
													value={contactEmail}
													onChange={(e) => setContactEmail(e.target.value)}
													disabled={!assetInfo?.public_allow_edit}
												/>
											</div>
										</Col>
										<Col>
											<Form.Label>Contact Number</Form.Label>
											<div className="flex-row-left full">
												<Form.Control
													type="text"
													className="form-input"
													placeholder={
														assetInfo?.public_allow_edit
															? "Enter contact number."
															: ""
													}
													value={contactMsisdn}
													onChange={(e) => setContactMsisdn(e.target.value)}
													disabled={!assetInfo?.public_allow_edit}
												/>
											</div>
										</Col>
									</Row>
								</>
							)}
							{assetType === "Wifi" && (
								<>
									<Row className="form-block-sm">
										<Col>
											<Form.Label>SSID (Network Name)*</Form.Label>
											<div className="flex-row-left full">
												<Form.Control
													type="text"
													className="form-input"
													placeholder="Enter SSID."
													value={wifiName}
													onChange={(e) => setWifiName(e.target.value)}
													disabled={!assetInfo?.public_allow_edit}
												/>
											</div>
										</Col>
									</Row>
									<Row className="form-block-sm">
										<Col>
											<Form.Label>Password*</Form.Label>
											<div className="flex-row-left full">
												<Form.Control
													type={showWifiPassword ? "text" : "password"}
													className="form-input"
													placeholder="Enter wifi password."
													value={wifiPassword}
													onChange={(e) => setWifiPassword(e.target.value)}
													disabled={!assetInfo?.public_allow_edit}
												/>
												<div className="asset-pw-actions flex-row-right">
													<Button
														variant="light flex-column"
														onClick={() =>
															setShowWifiPassword(!showWifiPassword)
														}
													>
														<i
															className={`opacity-7 ${
																showWifiPassword
																	? "ri-eye-off-line"
																	: "ri-eye-line"
															}`}
														/>
													</Button>
												</div>
											</div>
										</Col>
									</Row>
									<Row className="form-block-sm">
										<Col>
											<Form.Label>Encryption Type*</Form.Label>
											<div className="flex-row-left full">
												<Form.Select
													className="form-input"
													placeholder="Enter contact number."
													value={wifiEncyption}
													onChange={(e) => setWifiEncryption(e.target.value)}
													disabled={!assetInfo?.public_allow_edit}
												>
													<option value="WPA2">WPA2</option>
													<option value="WPA">WPA</option>
													<option value="nopass">No Password</option>
												</Form.Select>
											</div>
										</Col>
									</Row>
									<Row className="form-block-sm">
										<Col>
											<Form.Label>Hidden Network*</Form.Label>
											<div className="flex-row-left full">
												<Form.Select
													className="form-input"
													placeholder="Enter contact number."
													value={wifiIsHidden}
													onChange={(e) => setWifiIsHidden(e.target.value)}
													disabled={!assetInfo?.public_allow_edit}
												>
													<option value={false}>No</option>
													<option value={true}>Yes</option>
												</Form.Select>
											</div>
										</Col>
									</Row>
								</>
							)}
							<a href={process.env.REACT_APP_HOST} className="full mt-5 block">
								<Button className="btn-tertiary rounded-4 btn-md text-sm full">
									Back to Homepage
								</Button>
							</a>
						</div>
					</>
				) : verificationEmail ? (
					<div className="bg-white p-20 rounded col-lg-5 col-md-7 col-12">
						<LogoDark className="mb-30" />
						<h1 className="text-medium-inter text-lg mb-10">
							Verify your email
						</h1>
						<p className="mb-30 text-sm align-left">
							Please enter the one-time passcode we sent to {verificationEmail}{" "}
							below.
						</p>
						<Form onSubmit={getData} className="full">
							<Row className="mb-30">
								<Col>
									<Form.Control
										placeholder="Enter OTP"
										className="form-input"
										value={token}
										onChange={(e) => setToken(e.target.value)}
									/>
								</Col>
							</Row>
							<Button
								type="submit"
								className="full btn-default rounded-4 btn-md text-sm mb-15"
								disabled={verifying}
							>
								{verifying ? "Verifying.." : "Verify"}
							</Button>
							<Button
								className="full btn-tertiary rounded-4 btn-md text-sm"
								onClick={sendVerification}
								disabled={verificationLoading}
							>
								{verificationLoading ? "Resending.." : "Resend"}
							</Button>
						</Form>
					</div>
				) : (
					<div className="bg-white p-20 rounded col-lg-5 col-md-7 col-12">
						<LogoDark className="mb-30" />
						<h1 className="text-medium-inter text-lg mb-10">
							One-time asset access
						</h1>
						<p className="mb-30 text-sm align-left">
							You can only access this asset once. Would you like to proceed?
						</p>
						<Button
							className="full btn-default rounded-4 btn-md text-sm mb-15"
							onClick={sendVerification}
							disabled={verificationLoading}
						>
							{verificationLoading ? "Sending OTP.." : "View Asset"}
						</Button>
						<a href={process.env.REACT_APP_HOST} className="full">
							<Button className="full btn-tertiary rounded-4 btn-md text-sm">
								Back to Homepage
							</Button>
						</a>
					</div>
				)}
			</Container>
		</div>
	);
};

export default PublicAsset;
