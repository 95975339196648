import { useState, useEffect, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import { UserContext } from "../context/UserContext";
import { KeyContext } from "../context/KeyContext";
import { SaltContext } from "../context/SaltContext";
import { useSnackbar } from "react-simple-snackbar";
import { encrypt } from "../components/Utils";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Logo from "../components/LogoDark";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import Modal from "react-bootstrap/Modal";

const Login = () => {
	document.title = "Log In | SMSafe";
	const navigate = useNavigate();
	const [, setUser] = useContext(UserContext);
	const [, setKey] = useContext(KeyContext);
	const [, setSalt] = useContext(SaltContext);
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [code, setCode] = useState("");
	const [userId, setUserId] = useState(null);
	const [userEmail, setUserEmail] = useState("");
	const [mfaType, setMfaType] = useState("");
	const [emailError, setEmailError] = useState("");
	const [passwordError, setPasswordError] = useState("");
	const [codeError, setCodeError] = useState("");
	const [resetEmail, setResetEmail] = useState("");
	const [loading, setLoading] = useState(false);
	const [ready, setReady] = useState(false);
	const [resetModal, setResetModal] = useState(false);
	const [actionLoading, setActionLoading] = useState(false);
	const [showNav, setShowNav] = useState(false);
	const [showPassword, setShowPassword] = useState(false);

	const [defaultSnackbar] = useSnackbar();

	const [dangerSnackbar] = useSnackbar({
		style: {
			zIndex: 10,
			backgroundColor: "#d81e5b",
			color: "#fff",
		},
	});

	const authUser = async (e) => {
		e.preventDefault();
		setLoading(true);
		setEmailError("");
		setPasswordError("");
		const requestOptions = {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify({
				email: email,
				password: password,
			}),
		};

		const response = await fetch(
			`${process.env.REACT_APP_API_URI}/users/login`,
			requestOptions
		);
		const data = await response.json();

		if (!response.ok) {
			if (data.error == "email") {
				setEmailError(data.msg);
			} else {
				setPasswordError(data.msg);
			}
			setPassword("");
		} else {
			if (data.user_id) {
				setUserId(data.user_id);
				setUserEmail(data.email);
				setMfaType(data.mfa_type);
			} else {
				setKey(encrypt(password, data.user_session.email, data.salt));
				setSalt(data.salt);
				setUser(data.user_session);
			}
		}
		setLoading(false);
	};

	const validateMfa = async (e) => {
		e.preventDefault();
		setLoading(true);
		setCodeError("");
		const requestOptions = {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify({
				user_id: userId,
				code: code,
				type: mfaType,
			}),
		};

		const response = await fetch(
			`${process.env.REACT_APP_API_URI}/users/login/mfa`,
			requestOptions
		);
		const data = await response.json();

		if (!response.ok) {
			if (data.error == "code") {
				setCodeError(data.msg);
			}
			setPassword("");
		} else {
			setKey(encrypt(password, data.user_session.email, data.salt));
			setSalt(data.salt);
			setUser(data.user_session);
		}
		setLoading(false);
	};

	const resetPassword = async (e) => {
		e.preventDefault();
		setActionLoading(true);
		const requestOptions = {
			method: "POST",
			body: JSON.stringify({
				email: resetEmail,
			}),
		};

		const response = await fetch(
			`${process.env.REACT_APP_API_URI}/users/password/generate`,
			requestOptions
		);
		const data = await response.json();

		if (!response.ok) {
			dangerSnackbar(data.msg);
		} else {
			setResetEmail("");
			setResetModal(false);
			defaultSnackbar(data.msg);
		}
		setActionLoading(false);
	};

	useEffect(() => {
		if (email && password) {
			setReady(true);
		} else {
			setReady(false);
		}
	}, [email, password]);

	return (
		<>
			<div className="full-page bg-white">
				<div className="login-header full flex-row-left bg-white">
					<Container>
						<Row className="flex-row-left">
							<Col>
								<Link to="/">
									<Logo variant="dark" className="nav-logo" />
								</Link>
							</Col>
							<Col className="nav-action-container p-0 flex-row-right">
								<div className="nav-lg flex-row-right content-lg">
									<p className="text-light mb-0">
										Don't have an account?{" "}
										<Link to="/signup" className="text-blue text-bold-inter">
											Sign up
										</Link>
									</p>
								</div>
								<div className="flex-row-right content-sm">
									<div
										className="nav-toggle flex-column"
										onClick={() => setShowNav(!showNav)}
									>
										<div className={`fries ${showNav && "active"}`}>
											<div className="top-fry" />
											<div className="mid-fry" />
											<div className="bottom-fry" />
										</div>
									</div>
								</div>
							</Col>
						</Row>
					</Container>
				</div>
				<div
					className={`nav-sm login bg-white border-top ${showNav && "active"}`}
				>
					<Container className="full-height pt-4 pb-4">
						<p className="text-light mb-0">
							Don't have an account?{" "}
							<Link to="/signup" className="text-blue text-bold-inter">
								Sign up
							</Link>
						</p>
					</Container>
				</div>
				<Container className="login-form-container flex-column">
					{!userId ? (
						<Form
							onSubmit={authUser}
							className="login-form col-lg-4 col-md-7 col-12"
						>
							<div className="login-form-header align-center mb-50">
								<h1 className="login-form-title title-md text-regular-lexend">
									Log in to your account
								</h1>
							</div>
							<Row className="form-block">
								<Col>
									<Form.Label>Username/Email</Form.Label>
									<Form.Control
										required
										type="text"
										className={`login-input rounded ${emailError && "error"}`}
										placeholder="Enter Username or Email"
										onChange={(e) => setEmail(e.target.value)}
									/>
									<div className="login-error-msg full align-left">
										<span className="text-red label-md">
											{emailError && emailError}
										</span>
									</div>
								</Col>
							</Row>
							<Row className="form-block">
								<Col>
									<Form.Label>Password</Form.Label>
									<div className="flex-row-left">
										<Form.Control
											required
											type={showPassword ? "text" : "password"}
											className={`login-input rounded ${
												passwordError && "error"
											}`}
											placeholder="Enter Password"
											value={password}
											onChange={(e) => setPassword(e.target.value)}
										/>
										<i
											className={`show-pw-btn opacity-3 text-xl pointer ${
												showPassword ? "ri-eye-fill" : "ri-eye-off-fill"
											}`}
											onClick={() => setShowPassword(!showPassword)}
										/>
									</div>
									<div className="login-error-msg full align-left">
										<span className="text-red label-md">
											{passwordError && passwordError}
										</span>
									</div>
								</Col>
							</Row>
							<Row className="form-block">
								<Col>
									<Button
										type="submit"
										className="btn-default rounded-4 btn-lg full"
										disabled={loading || !ready}
									>
										{loading ? (
											<Spinner className="text-white btn-md-loader mt-1" />
										) : (
											"Log in"
										)}
									</Button>
								</Col>
							</Row>
							{/* <div className="forgot-pw-container align-center">
								<a
									href="#"
									className="opacity-5 text-sm"
									onClick={(e) => {
										e.preventDefault();
										setResetModal(true);
									}}
								>
									Forgot your password?
								</a>
							</div> */}
							{/* <p className="text-light text-sm align-center">
								By clicking Log in, you agree to our{" "}
								<Link to="terms" className="text-blue text-bold-inter">
									Terms
								</Link>{" "}
								&{" "}
								<Link to="privacy" className="text-blue text-bold-inter">
									Privacy Policy
								</Link>
							</p> */}
						</Form>
					) : (
						<Form
							onSubmit={validateMfa}
							className="login-form col-lg-7 col-md-8 col-12"
						>
							<div className="login-form-header">
								<h1 className="login-form-title title-lg text-regular-lexend">
									Two-Factor Authentication
								</h1>
								{mfaType === "email" ? (
									<p className="form-block text-light text-lg">
										To finish signing in, please enter the code we sent to{" "}
										{userEmail}.
									</p>
								) : (
									<p className="form-block text-light text-lg">
										To finish signing in, open your authenticator app and enter
										the code below.
									</p>
								)}
							</div>
							<Row className="form-block">
								<Col>
									<Form.Label>2FA Code</Form.Label>
									<Form.Control
										required
										type="text"
										className={`login-input rounded ${codeError && "error"}`}
										placeholder="Enter 2FA code"
										value={code}
										onChange={(e) => setCode(e.target.value)}
									/>
									<div className="login-error-msg full align-left">
										<span className="text-red label-md">
											{codeError && codeError}
										</span>
									</div>
								</Col>
							</Row>
							<Row className="form-block">
								<Col>
									<Button
										type="submit"
										className="btn-default btn-rounded btn-lg full"
										disabled={loading}
									>
										{loading ? (
											<Spinner className="text-white btn-md-loader mt-1" />
										) : (
											"Submit"
										)}
									</Button>
								</Col>
							</Row>
						</Form>
					)}
				</Container>
			</div>
			<Modal
				show={resetModal}
				className="post-modal"
				onHide={() => {
					setResetModal(false);
					setResetEmail("");
				}}
				centered
			>
				<Modal.Header>
					<div>
						<span className="text-medium-inter text-lg">Change Password</span>
						<p className="m-0 text-sm opacity-5">
							We'll send a password reset link to your email.
						</p>
					</div>
				</Modal.Header>
				<Form onSubmit={resetPassword}>
					<Modal.Body>
						<Row className="form-block-sm">
							<Col>
								<Form.Label>Email</Form.Label>
								<Form.Control
									type="email"
									className="form-input"
									placeholder="Enter your email"
									value={resetEmail}
									onChange={(e) => setResetEmail(e.target.value)}
								/>
							</Col>
						</Row>
					</Modal.Body>
					<Modal.Footer>
						<Button
							variant="light"
							className="modal-btn btn-md dismiss-btn text-medium-inter text-sm"
							onClick={() => {
								setResetModal(false);
								setResetEmail("");
							}}
						>
							Cancel
						</Button>
						<Button
							type="submit"
							className="modal-btn btn-md btn-default text-medium-inter text-sm"
							disabled={actionLoading}
						>
							{actionLoading ? "Sending.." : "Send Reset Link"}
						</Button>
					</Modal.Footer>
				</Form>
			</Modal>
		</>
	);
};

export default Login;
