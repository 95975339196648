import React, { useContext, useEffect, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import { UserContext } from "../context/UserContext";
import { useSnackbar } from "react-simple-snackbar";
import { format } from "date-fns";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import PageHeader from "../components/PageHeader";
import Select, { components } from "react-select";
import EmptyBee from "../components/EmptyBee";
import PageLoader from "../components/PageLoader";
import MfaToken from "../components/MfaToken";
import defaultUser from "../assets/default_user.png";

// TEMPORARY
import facebook from "../assets/socials/facebook.png";
import google from "../assets/socials/google.png";
import tiktok from "../assets/socials/tiktok.png";
import canada from "../assets/canada.png";
import polynesia from "../assets/polynesia.png";
import czech_republic from "../assets/czech_republic.png";
import australia from "../assets/australia.png";
import belgium from "../assets/belgium.png";

const Search = () => {
	const [user] = useContext(UserContext);
	const [searchParams] = useSearchParams();
	const query = searchParams.get("q");
	const [numbers, setNumbers] = useState([]);
	const [userMfas, setUserMfas] = useState([]);
	const [messages, setMessages] = useState([]);
	const [userGroups, setUserGroups] = useState([]);
	const [loading, setLoading] = useState(false);
	const [actionLoading, setActionLoading] = useState(false);

	const [defaultSnackbar] = useSnackbar();

	const getData = async () => {
		setLoading(true);
		const requestOptions = {
			method: "GET",
			headers: {
				"X-Auth-Token": user ? user.session_token : "",
			},
		};

		const response = await fetch(
			`${process.env.REACT_APP_API_URI}/users/search${window.location.search}`,
			requestOptions
		);
		const data = await response.json();

		if (!response.ok) {
			console.log("Something went wrong.");
		} else {
			setNumbers(data.numbers);
			setUserMfas(data.mfas);
			setMessages(data.messages);
			setUserGroups(data.groups);
		}
		setLoading(false);
	};

	const copyMsisdn = (e, msisdn) => {
		e.preventDefault();
		navigator.clipboard.writeText(msisdn);
		defaultSnackbar("Copied to clipboard.");
	};

	const getMessageAge = (created) => {
		let timeString;
		const now = new Date().getTime();
		const createdAt = new Date(created).getTime();
		const diff = now - createdAt;
		const diffInSeconds = Math.round(diff / 1000);
		const diffInMinutes = Math.round(diff / (1000 * 60));
		const diffInHours = Math.round(diff / (1000 * 60 * 60));

		if (diff < 86400000) {
			if (diffInSeconds < 60) {
				timeString = `${diffInSeconds} seconds ago`;
			} else if (diffInMinutes < 60) {
				timeString = `${diffInMinutes} minutes ago`;
			} else {
				timeString = `${diffInHours} hours ago`;
			}
		} else if (diff < 172800000) {
			timeString = "Yesterday - " + format(new Date(created), "hh:mm a");
		} else if (diff < 31536000000) {
			timeString = format(new Date(created), "MMM dd - hh:mm a");
		} else {
			timeString = format(new Date(created), "MMM dd, yyyy - hh:mm a");
		}
		return timeString;
	};

	useEffect(() => {
		getData();
	}, [query]);

	return (
		<>
			<div className="content full-page">
				<PageHeader user={user} />
				<Container>
					<h1 className="page-content-title text-lg text-medium-inter">
						<span className="opacity-3">Search results for</span>{" "}
						<span className="text-medium-inter">'{query}'</span>
					</h1>
					{loading ? (
						<PageLoader />
					) : (
						<>
							{numbers.length !== 0 && (
								<Row className="mb-30">
									<Col className="page-content col-lg-8 col-12">
										<div className="page-content-header flex-row-left">
											<div className="col-6">
												<span className="text-sm opacity-7 text-medium-inter">
													Phone Numbers
												</span>
											</div>
											<div className="col-6 flex-row-right">
												<a
													href="#"
													className="page-content-header-action opacity-3 text-medium-inter flex-row-right"
												>
													<span className="text-sm">View All Results</span>
													<i className="ri-arrow-right-line" />
												</a>
											</div>
										</div>
										<div className="page-content-body">
											{numbers.map((number) => (
												<Link
													key={number.id}
													to={`/inbox/${number.v_msisdn.id}`}
												>
													<div className="content-item bg-white rounded-4 full flex-row-left">
														<div className="col-8">
															<img
																src={number.v_msisdn.flag_uri}
																alt={number.v_msisdn.country}
																className="country-icon"
															/>
															<span className="text-medium-inter">
																{number.v_msisdn.msisdn}
															</span>
														</div>
														<div className="col-4 number-item-control flex-row-right">
															<Button
																className="flex-column"
																onClick={(e) =>
																	copyMsisdn(e, number.v_msisdn.msisdn)
																}
															>
																<i className="ri-file-copy-line text-sm" />
															</Button>
															{/* <Button className="flex-column">
														<i className="ri-share-forward-line text-sm" />
													</Button> */}
															<Button className="flex-column">
																<i className="ri-settings-line text-sm" />
															</Button>
														</div>
													</div>
												</Link>
											))}
										</div>
									</Col>
									<Col className="page-sub-content col-lg-4 col-12"></Col>
								</Row>
							)}
							{userMfas.length !== 0 && (
								<Row className="mb-30">
									<Col className="page-content col-lg-8 col-12">
										<div className="page-content-header flex-row-left">
											<div className="col-6">
												<span className="text-sm opacity-7 text-medium-inter">
													2FA Storage
												</span>
											</div>
											<div className="col-6 flex-row-right">
												<a
													href="#"
													className="page-content-header-action opacity-3 text-medium-inter flex-row-right"
												>
													<span className="text-sm">View All Results</span>
													<i className="ri-arrow-right-line" />
												</a>
											</div>
										</div>
										<div className="page-content-body">
											{userMfas.map((userMfa) => (
												<MfaToken key={userMfa.id} mfa={userMfa.mfa_token} />
											))}
										</div>
									</Col>
									<Col className="page-sub-content col-lg-4 col-12"></Col>
								</Row>
							)}
							{messages.length !== 0 && (
								<Row className="mb-30">
									<Col className="page-content col-lg-8 col-12">
										<div className="page-content-header flex-row-left">
											<div className="col-6">
												<span className="text-sm opacity-7 text-medium-inter">
													Inbox
												</span>
											</div>
											<div className="col-6 flex-row-right">
												<a
													href="#"
													className="page-content-header-action opacity-3 text-medium-inter flex-row-right"
												>
													<span className="text-sm">View All Results</span>
													<i className="ri-arrow-right-line" />
												</a>
											</div>
										</div>
										<div className="page-content-body">
											{messages.map((message) => (
												<div
													key={message.id}
													className="dashboard-item rounded-4 bg-white full unread"
												>
													<div className="dashboard-item-info flex-row-left">
														<div
															style={{
																background: `url(${
																	message.sender_img
																		? message.sender_img
																		: defaultUser
																})`,
															}}
															className="dashboard-item-icon"
														/>
														<div className="dashboard-item-details">
															<div className="flex-row-left">
																<span className="dashboard-item-sender text-sm">
																	{message.sender}
																</span>
																{/* <VerifiedIcon /> */}
															</div>
															<div className="dashboard-item-content text-sm">
																<p className="m-0">{message.content}</p>
															</div>
														</div>
														<div className="dashboard-item-control flex-row-right">
															<Button className="flex-column">
																<i className="ri-file-copy-line text-sm" />
															</Button>
															{/* <Button className="flex-column">
														<i className="ri-share-forward-line text-sm" />
													</Button>
													<Button className="flex-column">
														<i className="ri-inbox-archive-line text-sm" />
													</Button> */}
														</div>
													</div>
													<div className="separator" />
													<div className="dashboard-item-meta flex-row-left">
														<div className="flex-row-left col-6">
															<span className="label-sm text-bold-inter opacity-5 upper">
																Sent to
															</span>
															<img
																src={message.v_msisdn.flag_uri}
																alt="canada"
																className="dashboard-item-country"
															/>
															<span className="label-md text-regular-inter opacity-7">
																{message.v_msisdn.msisdn}
															</span>
														</div>
														<div className="flex-row-right col-6">
															<span className="label-sm text-bold-inter opacity-5">
																{getMessageAge(message.created_at)}
															</span>
															{/* <span className="label-sm opacity-5">, 19:32am</span> */}
														</div>
													</div>
												</div>
											))}
										</div>
									</Col>
									<Col className="page-sub-content col-lg-4 col-12"></Col>
								</Row>
							)}
							{userGroups.length !== 0 && (
								<Row className="mb-30">
									<Col className="page-content col-lg-8 col-12">
										<div className="page-content-header flex-row-left">
											<div className="col-6">
												<span className="text-sm opacity-7 text-medium-inter">
													Groups
												</span>
											</div>
											<div className="col-6 flex-row-right">
												<a
													href="#"
													className="page-content-header-action opacity-3 text-medium-inter flex-row-right"
												>
													<span className="text-sm">View All Results</span>
													<i className="ri-arrow-right-line" />
												</a>
											</div>
										</div>
										<div className="page-content-body">
											{userGroups.map((group) => (
												<Link to={`/safes/${group.id}`}>
													<div className="content-item bg-white rounded-4 full flex-row-left">
														<div className="group-icon bg-gray flex-column">
															<span className="text-xl">{group.name[0]}</span>
														</div>
														<div>
															<span className="text-sm text-medium-inter block">
																{group.name}
															</span>
															<span className="label-md opacity-3 block">
																{group.total_members}{" "}
																{group.total_members === 1
																	? "member"
																	: "members"}
															</span>
														</div>
														<div className="col-4 group-item-control flex-row-right"></div>
													</div>
												</Link>
											))}
										</div>
									</Col>
									<Col className="page-sub-content col-lg-4 col-12"></Col>
								</Row>
							)}
						</>
					)}
				</Container>
			</div>
		</>
	);
};

export default Search;
