import { useState } from "react";
import { format } from "date-fns";
import { useSnackbar } from "react-simple-snackbar";
import defaultUser from "../assets/default_user.png";
import Button from "react-bootstrap/Button";
const Message = ({ message, user }) => {
	const [read, setRead] = useState(message.read);
	const [defaultSnackbar] = useSnackbar();

	const getMessageAge = (created) => {
		let timeString;
		const now = new Date();
		const createdAt = new Date(created);
		const localCreatedAt = new Date(
			createdAt.getTime() - createdAt.getTimezoneOffset() * 60000
		);
		const diff = now - localCreatedAt;
		const diffInSeconds = Math.round(diff / 1000);
		const diffInMinutes = Math.round(diff / (1000 * 60));
		const diffInHours = Math.round(diff / (1000 * 60 * 60));

		if (diff < 86400000) {
			if (diffInSeconds < 60) {
				timeString = `${diffInSeconds} ${
					diffInSeconds === 1 ? "second" : "seconds"
				} ago`;
			} else if (diffInMinutes < 60) {
				timeString = `${diffInMinutes} ${
					diffInMinutes === 1 ? "minute" : "minutes"
				} ago`;
			} else {
				timeString = `${diffInHours} ${
					diffInHours === 1 ? "hour" : "hours"
				} ago`;
			}
		} else if (diff < 172800000) {
			timeString = "Yesterday - " + format(new Date(created), "hh:mm a");
		} else if (diff < 31536000000) {
			timeString = format(new Date(created), "MMM dd - hh:mm a");
		} else {
			timeString = format(new Date(created), "MMM dd, yyyy - hh:mm a");
		}
		return timeString;
	};

	const updateStatus = async () => {
		if (read === false) {
			const requestOptions = {
				method: "POST",
				headers: {
					"X-Auth-Token": user ? user.session_token : "",
				},
			};

			const response = await fetch(
				`${process.env.REACT_APP_API_URI}/users/sms/${message.id}/read`,
				requestOptions
			);
			const data = await response.json();

			if (!response.ok) {
				setRead(false);
			} else {
				setRead(true);
			}
		}
	};

	const copyMessage = async () => {
		const otpPattern = /\b\d{6}\b/;
		const otpMatch = message.content.match(otpPattern);
		const textToCopy = otpMatch ? otpMatch[0] : message.content;
		await navigator.clipboard.writeText(textToCopy);
		defaultSnackbar("Copied to clipboard.");
	};

	return (
		message && (
			<div
				key={message.id}
				className="dashboard-item rounded-4 bg-white full"
				onMouseEnter={updateStatus}
			>
				{read === false && <div className="unread-icon" />}
				<div className="dashboard-item-info flex-row-left full">
					<div
						style={{
							background: `url(${
								message.sender_img ? message.sender_img : defaultUser
							})`,
						}}
						className="dashboard-item-icon"
					/>
					<div className="dashboard-item-details">
						<div className="flex-row-left">
							<span className="dashboard-item-sender text-sm text-medium-inter">
								{message.sender}
							</span>
						</div>
						<div className="dashboard-item-content text-sm">
							<p className="m-0">{message.content}</p>
						</div>
					</div>
					<div className="dashboard-item-control flex-row-right">
						<Button className="flex-column" onClick={copyMessage}>
							<i className="ri-file-copy-line text-sm" />
						</Button>
					</div>
				</div>
				<div className="separator" />
				<div className="dashboard-item-meta flex-row-left">
					<div className="flex-row-left col-6">
						<span className="label-sm text-bold-inter opacity-5 upper">
							Sent to
						</span>
						<img
							src={message.v_msisdn.flag_uri}
							alt="canada"
							className="dashboard-item-country"
						/>
						<span className="label-md text-regular-inter opacity-7">
							{message.v_msisdn.msisdn}
						</span>
					</div>
					<div className="flex-row-right col-6">
						<span className="label-sm text-bold-inter opacity-5">
							{getMessageAge(message.created_at)}
						</span>
					</div>
				</div>
			</div>
		)
	);
};

export default Message;
