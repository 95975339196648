import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
	PaymentElement,
	useStripe,
	useElements,
} from "@stripe/react-stripe-js";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Spinner from "react-bootstrap/Spinner";

const CheckoutForm = () => {
	const stripe = useStripe();
	const elements = useElements();

	const [message, setMessage] = useState(null);
	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		if (!stripe) {
			return;
		}

		const clientSecret = new URLSearchParams(window.location.search).get(
			"payment_intent_client_secret"
		);

		if (!clientSecret) {
			return;
		}

		stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
			switch (paymentIntent.status) {
				case "succeeded":
					setMessage("Payment succeeded!");
					break;
				case "processing":
					setMessage("Your payment is processing.");
					break;
				case "requires_payment_method":
					setMessage("Your payment was not successful, please try again.");
					break;
				default:
					setMessage("Something went wrong.");
					break;
			}
		});
	}, [stripe]);

	const handleSubmit = async (e) => {
		e.preventDefault();

		if (!stripe || !elements) {
			// Stripe.js hasn't yet loaded.
			// Make sure to disable form submission until Stripe.js has loaded.
			return;
		}

		setIsLoading(true);

		const { error } = await stripe.confirmPayment({
			elements,
			confirmParams: {
				return_url: `${process.env.REACT_APP_HOST}/payment/verify${window.location.search}`,
			},
		});

		if (error.type === "card_error" || error.type === "validation_error") {
			setMessage(error.message);
		} else {
			console.log(error);
			setMessage("An unexpected error occurred.");
		}

		setIsLoading(false);
	};

	const paymentElementOptions = {
		layout: "tabs",
		business: { name: "SMSafe" },
	};

	return (
		<Form id="payment-form" onSubmit={handleSubmit}>
			<PaymentElement
				id="payment-element"
				options={paymentElementOptions}
				className="mb-30 full"
			/>
			<Button
				type="submit"
				disabled={isLoading || !stripe || !elements}
				id="submit"
				className="btn-md btn-default rounded-4 full mb-20 flex-column"
			>
				<span id="button-text">
					{isLoading ? (
						<Spinner className="text-white btn-md-loader mt-1" />
					) : (
						"Pay Now"
					)}
				</span>
			</Button>
			<Link to="/dashboard" className="full">
				<Button
					variant="light"
					className="btn-md rounded-4 btn-gray full text-medium-inter"
				>
					Cancel
				</Button>
			</Link>
			{message && (
				<div id="payment-message" className="text-danger">
					{message}
				</div>
			)}
		</Form>
	);
};

export default CheckoutForm;
