import { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import { useSnackbar } from "react-simple-snackbar";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import PageLoader from "../components/PageLoader";

const Unlocked = ({ user }) => {
	const navigate = useNavigate();
	const [userGroups, setUserGroups] = useState([]);
	const [safeName, setSafeName] = useState("");
	const [loading, setLoading] = useState(false);
	const [groupModal, setGroupModal] = useState(false);
	const [actionLoading, setActionLoading] = useState(false);
	const [subscribeModal, setSubscribeModal] = useState(false);
	const [subscribeError, setSubscribeError] = useState("");

	const [defaultSnackbar] = useSnackbar();

	const [dangerSnackbar] = useSnackbar({
		style: {
			zIndex: 10,
			backgroundColor: "#d81e5b",
			color: "#fff",
		},
	});

	const getData = async () => {
		setLoading(true);
		const requestOptions = {
			method: "GET",
			headers: {
				"X-Auth-Token": user ? user.session_token : "",
			},
		};

		const response = await fetch(
			`${process.env.REACT_APP_API_URI}/users/groups`,
			requestOptions
		);
		const data = await response.json();

		if (!response.ok) {
			console.log("Something went wrong.");
		} else {
			setUserGroups(data.data);
		}
		setLoading(false);
	};

	const addGroup = async (e) => {
		e.preventDefault();
		setActionLoading(true);
		const requestOptions = {
			method: "POST",
			headers: {
				"X-Auth-Token": user ? user.session_token : "",
			},
			body: JSON.stringify({
				name: safeName,
			}),
		};

		const response = await fetch(
			`${process.env.REACT_APP_API_URI}/users/groups`,
			requestOptions
		);
		const data = await response.json();

		if (!response.ok) {
			if (data.error === "subscription") {
				setSafeName("");
				setGroupModal(false);
				setSubscribeError(data.msg);
				setSubscribeModal(true);
			} else {
				dangerSnackbar(data.msg);
			}
		} else {
			setSafeName("");
			setGroupModal(false);
			defaultSnackbar(data.msg);
			navigate(`/safes/${data.group_id}`);
		}
		setActionLoading(false);
	};

	useEffect(() => {
		if (user) {
			getData();
		}
	}, [user]);

	return (
		<>
			{loading ? (
				<PageLoader className="full-page" />
			) : (
				<Container className="full-page flex-column">
					<div className="unlocked-container p-30 bg-white border col-lg-5 col-md-7 col-12">
						<h1 className="title-md text-medium-inter">Congratulations!</h1>
						<p className="text-sm opacity-7 mb-30">
							You have successfully subscribed to{" "}
							<span className="text-bold-inter">SMSafe Basic.</span>
							{userGroups.length === 0
								? " Let's kick things off by creating a brand new safe."
								: " Let's give your safe a virtual phone number."}
						</p>
						{userGroups.length === 0 ? (
							<Button
								className="full btn-default btn-md text-medium-inter mb-15"
								onClick={() => setGroupModal(true)}
							>
								Create Safe
							</Button>
						) : (
							<Link to={`/safes/${userGroups[0].id}?tab=numbers`}>
								<Button
									className="full btn-default btn-md text-medium-inter mb-15"
									onClick={() => setGroupModal(true)}
								>
									Add Phone Number
								</Button>
							</Link>
						)}
						<Link to="/">
							<Button variant="outline-secondary" className="full btn-md">
								Back to Dashboard
							</Button>
						</Link>
					</div>
				</Container>
			)}
			<Modal
				show={groupModal}
				className="post-modal"
				onHide={() => setGroupModal(false)}
				centered
			>
				<Modal.Header className="text-medium-inter text-lg">
					Create Safe
				</Modal.Header>
				<Form onSubmit={addGroup}>
					<Modal.Body>
						<Row className="form-block-sm">
							<Col>
								<Form.Label>Safe Name</Form.Label>
								<Form.Control
									className="form-input"
									placeholder="Enter safe name"
									value={safeName}
									onChange={(e) => setSafeName(e.target.value)}
								/>
							</Col>
						</Row>
					</Modal.Body>
					<Modal.Footer>
						<Button
							variant="light"
							className="modal-btn btn-md dismiss-btn text-medium-inter text-sm"
							onClick={() => setGroupModal(false)}
						>
							Cancel
						</Button>
						<Button
							type="submit"
							className="modal-btn btn-md btn-default text-medium-inter text-sm"
							disabled={actionLoading}
						>
							{actionLoading ? "Creating.." : "Create Safe"}
						</Button>
					</Modal.Footer>
				</Form>
			</Modal>
			<Modal
				show={subscribeModal}
				onHide={() => setSubscribeModal(false)}
				centered
			>
				<Modal.Header className="text-medium-inter text-lg flex-row-left">
					<i class="ri-error-warning-line text-danger text-xl me-2" />
					Upgrade Account
				</Modal.Header>
				<Modal.Body>
					<p className="text-sm opacity-5 mb-0">{subscribeError}</p>
				</Modal.Body>
				<Modal.Footer>
					<Button
						variant="light"
						className="modal-btn btn-md dismiss-btn text-medium-inter text-sm"
						onClick={() => setSubscribeModal(false)}
					>
						Cancel
					</Button>
					<Button
						className="modal-btn btn-md btn-default text-medium-inter text-sm"
						onClick={() => navigate(`/plans?redirect_url=/safes`)}
					>
						Upgrade
					</Button>
				</Modal.Footer>
			</Modal>
		</>
	);
};

export default Unlocked;
