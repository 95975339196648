const VerifiedEmail = () => {
	return (
		<svg width="24" height="25" viewBox="0 0 24 25" fill="none">
			<g clipPath="url(#clip0_170_9026)">
				<path
					d="M12 22.6899C6.477 22.6899 2 18.2129 2 12.6899C2 7.16694 6.477 2.68994 12 2.68994C17.523 2.68994 22 7.16694 22 12.6899C22 18.2129 17.523 22.6899 12 22.6899ZM11.003 16.6899L18.073 9.61894L16.659 8.20494L11.003 13.8619L8.174 11.0329L6.76 12.4469L11.003 16.6899Z"
					fill="#38C976"
				/>
			</g>
			<defs>
				<clipPath id="clip0_170_9026">
					<rect
						width="24"
						height="24"
						fill="white"
						transform="translate(0 0.689941)"
					/>
				</clipPath>
			</defs>
		</svg>
	);
};

export default VerifiedEmail;
