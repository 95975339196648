import React, { useContext, useState, useEffect, useRef } from "react";
import { Navigate } from "react-router-dom";
import { UserContext } from "../context/UserContext";
import { useSnackbar } from "react-simple-snackbar";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import PageHeader from "../components/PageHeader";
import MfaToken from "../components/MfaToken";
import QRCodeReader from "qrcode-reader";
import EmptyBee from "../components/EmptyBee";
import PageLoader from "../components/PageLoader";
import jsQR from "jsqr";

// TEMPORARY
import facebook from "../assets/socials/facebook.png";
import google from "../assets/socials/google.png";
import tiktok from "../assets/socials/tiktok.png";
import canada from "../assets/canada.png";
import polynesia from "../assets/polynesia.png";
import czech_republic from "../assets/czech_republic.png";
import australia from "../assets/australia.png";
import belgium from "../assets/belgium.png";

const Mfa = () => {
	const [user] = useContext(UserContext);
	const fileRef = useRef(null);
	const [userMfas, setUserMfas] = useState([]);
	const [meta, setMeta] = useState(null);
	const [mfaModal, setMfaModal] = useState(false);
	const [tokenModal, setTokenModal] = useState(false);
	const [loading, setLoading] = useState(false);
	const [actionLoading, setActionLoading] = useState(false);
	const [toDelete, setToDelete] = useState(null);

	const [service, setService] = useState("");
	const [account, setAccount] = useState("");
	const [token, setToken] = useState("");

	const [defaultSnackbar] = useSnackbar();

	const [dangerSnackbar] = useSnackbar({
		style: {
			zIndex: 10,
			backgroundColor: "#d81e5b",
			color: "#fff",
		},
	});

	const getData = async () => {
		setLoading(true);
		const requestOptions = {
			method: "GET",
			headers: {
				"X-Auth-Token": user ? user.session_token : "",
			},
		};

		const response = await fetch(
			`${process.env.REACT_APP_API_URI}/users/mfa/tokens`,
			requestOptions
		);
		const data = await response.json();

		if (!response.ok) {
			console.log("Something went wrong.");
		} else {
			setUserMfas(data.mfa_tokens);
			setMeta(data.meta);
		}
		setLoading(false);
	};

	const handleFileInputChange = (e) => {
		const file = e.target.files[0];
		if (!file) return;
		const qrCodeReader = new QRCodeReader();

		// Read the image file using FileReader
		const reader = new FileReader();
		reader.onload = () => {
			const img = new Image();
			img.src = reader.result;
			img.onload = () => {
				// Decode the QR code from the image
				const canvas = document.createElement("canvas");
				const context = canvas.getContext("2d");
				canvas.width = img.width;
				canvas.height = img.height;
				context.drawImage(img, 0, 0);

				const imageData = context.getImageData(
					0,
					0,
					canvas.width,
					canvas.height
				);

				// Decode the QR code using jsQR
				const code = jsQR(imageData.data, imageData.width, imageData.height);

				if (code) {
					addMfa(code.data);
				} else {
					dangerSnackbar("QR code not detected.");
				}
			};
		};
		reader.readAsDataURL(file);
	};

	const addMfa = async (code) => {
		const requestOptions = {
			method: "POST",
			headers: {
				"X-Auth-Token": user ? user.session_token : "",
			},
			body: JSON.stringify({
				token_uri: code,
			}),
		};

		const response = await fetch(
			`${process.env.REACT_APP_API_URI}/users/mfa/tokens`,
			requestOptions
		);
		const data = await response.json();

		if (!response.ok) {
			dangerSnackbar("Cannot decode QR code.");
		} else {
			defaultSnackbar("2FA added.");
			getData();
		}
		setMfaModal(false);
	};

	const addToken = async (e) => {
		e.preventDefault();
		const requestOptions = {
			method: "POST",
			headers: {
				"X-Auth-Token": user ? user.session_token : "",
			},
			body: JSON.stringify({
				issuer: service,
				username: account,
				token: token,
			}),
		};

		const response = await fetch(
			`${process.env.REACT_APP_API_URI}/users/mfa/setup-code`,
			requestOptions
		);
		const data = await response.json();

		if (!response.ok) {
			dangerSnackbar("Something went wrong.");
		} else {
			defaultSnackbar("2FA added.");
			getData();
		}
		setService("");
		setAccount("");
		setToken("");
		setTokenModal(false);
	};

	const deleteMfa = async () => {
		setActionLoading(true);
		const requestOptions = {
			method: "DELETE",
			headers: {
				"X-Auth-Token": user ? user.session_token : "",
			},
		};

		const response = await fetch(
			`${process.env.REACT_APP_API_URI}/users/mfa/tokens/${toDelete}`,
			requestOptions
		);
		const data = await response.json();

		if (!response.ok) {
			console.log("Something went wrong.");
		} else {
			defaultSnackbar("2FA deleted.");
			getData();
		}
		setToDelete(null);
		setActionLoading(false);
	};

	useEffect(() => {
		getData();
	}, []);

	return (
		<>
			<div className="content full-page">
				<PageHeader user={user} />
				<Container>
					<h1 className="page-content-title text-lg text-medium-inter">
						2FA Storage{" "}
						<span className="opacity-3 text-regular-inter">
							({meta?.total})
						</span>
					</h1>
					{loading ? (
						<PageLoader />
					) : userMfas.length === 0 ? (
						<div className="flex-column">
							<EmptyBee />
							<span className="empty-label text-lg">No 2FA accounts yet</span>
							<Button
								className="init-btn btn-default btn-md flex-row-center rounded-4 text-sm"
								onClick={() => setMfaModal(true)}
							>
								<i className="ri-add-line" />
								Add New 2FA
							</Button>
						</div>
					) : (
						<Row>
							<Col className="page-content col-lg-8 col-12">
								<div className="page-content-header flex-row-left">
									<Form.Select className="numbers-country-picker border fit text-sm rounded-4">
										<option value="">Time</option>
									</Form.Select>
								</div>
								<div className="page-content-body">
									{userMfas.map((userMfa) => (
										<MfaToken
											key={userMfa.id}
											mfa={userMfa.mfa_token}
											setToDelete={setToDelete}
										/>
									))}
								</div>
							</Col>
							<Col className="page-sub-content col-lg-4 col-12">
								<div className="page-content-header flex-row-right">
									<Button
										className="btn-default btn-sm flex-row-center rounded-4 text-sm"
										onClick={() => setMfaModal(true)}
									>
										<i className="ri-add-line" />
										Add New 2FA
									</Button>
								</div>
								<div className="page-content-body">
									<span className="text-sm opacity-7">Most Used 2FA</span>
									<div className="mfa-recent-container bg-white rounded-4 full">
										<div className="mfa-recent-item">
											<div className="mfa-title-container flex-row-left">
												<div className="col-8">
													<img
														src={facebook}
														alt="canada"
														className="country-icon"
													/>
													<span className="text-medium-inter text-sm">
														Facebook
													</span>
												</div>
												<div className="col-4 mfa-item-control flex-row-right">
													Loader
												</div>
											</div>
											<div className="flex-row-left">
												<div className="col-8">
													<span className="mfa-username label-md opacity-3 block">
														barcelona.jasperoliver@gmail.com
													</span>
													<span className="mfa-code text-medium-inter">
														212 445
													</span>
												</div>
												<div className="col-4 mfa-item-control flex-row-right">
													<Button className="flex-column">
														<i className="ri-file-copy-line text-sm" />
													</Button>
												</div>
											</div>
										</div>
										<div className="mfa-recent-item">
											<div className="mfa-title-container flex-row-left">
												<div className="col-8">
													<img
														src={google}
														alt="canada"
														className="country-icon"
													/>
													<span className="text-medium-inter text-sm">
														Google
													</span>
												</div>
												<div className="col-4 mfa-item-control flex-row-right">
													Loader
												</div>
											</div>
											<div className="flex-row-left">
												<div className="col-8">
													<span className="mfa-username label-md opacity-3 block">
														barcelona.jasperoliver@gmail.com
													</span>
													<span className="mfa-code text-medium-inter">
														212 445
													</span>
												</div>
												<div className="col-4 mfa-item-control flex-row-right">
													<Button className="flex-column">
														<i className="ri-file-copy-line text-sm" />
													</Button>
												</div>
											</div>
										</div>
										<div className="mfa-recent-item">
											<div className="mfa-title-container flex-row-left">
												<div className="col-8">
													<img
														src={tiktok}
														alt="canada"
														className="country-icon"
													/>
													<span className="text-medium-inter text-sm">
														Tiktok
													</span>
												</div>
												<div className="col-4 mfa-item-control flex-row-right">
													Loader
												</div>
											</div>
											<div className="flex-row-left">
												<div className="col-8">
													<span className="mfa-username label-md opacity-3 block">
														barcelona.jasperoliver@gmail.com
													</span>
													<span className="mfa-code text-medium-inter">
														212 445
													</span>
												</div>
												<div className="col-4 mfa-item-control flex-row-right">
													<Button className="flex-column">
														<i className="ri-file-copy-line text-sm" />
													</Button>
												</div>
											</div>
										</div>
									</div>
								</div>
							</Col>
						</Row>
					)}
				</Container>
			</div>
			<Modal
				show={mfaModal}
				className="post-modal"
				onHide={() => setMfaModal(false)}
				centered
			>
				<Modal.Header className="text-medium-inter text-lg">
					Add New 2FA
				</Modal.Header>
				<Form>
					<Modal.Body>
						<input
							ref={fileRef}
							type="file"
							accept="image/png, image/jpeg"
							onChange={handleFileInputChange}
							className="hidden"
						/>
						<Button
							className="mfa-modal-btn btn-md text-sm text-medium-inter full btn-gray text-blue flex-row-left rounded-4"
							onClick={() => fileRef.current.click()}
						>
							<i className="ri-qr-code-line" />
							Upload QR Code
						</Button>
						<Button
							className="mfa-modal-btn btn-md text-sm text-medium-inter full btn-gray text-blue flex-row-left rounded-4"
							onClick={() => {
								setMfaModal(false);
								setTokenModal(true);
							}}
						>
							<i className="ri-key-2-line" />
							Enter Setup Key
						</Button>
					</Modal.Body>
				</Form>
			</Modal>
			<Modal show={toDelete} onHide={() => setToDelete(null)} centered>
				<Modal.Body>
					<h2 className="text-lg text-medium-inter">Delete 2FA Account</h2>
					<p className="text-sm opacity-5 mb-0">
						Are you sure you want to delete this 2FA account? You wouldn’t be
						able to login to this service if 2FA is still enabled.
					</p>
				</Modal.Body>
				<Modal.Footer>
					<Button
						variant="light"
						className="modal-btn btn-sm dismiss-btn text-medium-inter text-sm"
						onClick={() => setToDelete(null)}
					>
						Cancel
					</Button>
					<Button
						className="modal-btn btn-sm btn-default text-medium-inter text-sm"
						disabled={actionLoading}
						onClick={deleteMfa}
					>
						{actionLoading ? "Deleting.." : "Yes, Delete"}
					</Button>
				</Modal.Footer>
			</Modal>
			<Modal
				show={tokenModal}
				className="post-modal"
				onHide={() => setTokenModal(false)}
				centered
			>
				<Modal.Header className="text-medium-inter text-lg">
					Account Details
				</Modal.Header>
				<Form onSubmit={addToken}>
					<Modal.Body>
						<Row className="form-block-sm">
							<Col>
								<Form.Label className="text-sm">Service</Form.Label>
								<Form.Control
									type="text"
									placeholder="Enter service name"
									className="rounded-4 form-input"
									value={service}
									onChange={(e) => setService(e.target.value)}
								/>
							</Col>
						</Row>
						<Row className="form-block-sm">
							<Col>
								<Form.Label className="text-sm">Account</Form.Label>
								<Form.Control
									type="text"
									placeholder="Enter account username"
									className="rounded-4 form-input"
									value={account}
									onChange={(e) => setAccount(e.target.value)}
								/>
							</Col>
						</Row>
						<Row className="form-block-sm">
							<Col>
								<Form.Label className="text-sm">Secret Key</Form.Label>
								<Form.Control
									type="password"
									placeholder="Enter secret key"
									className="rounded-4 form-input"
									value={token}
									onChange={(e) => setToken(e.target.value)}
								/>
							</Col>
						</Row>
					</Modal.Body>
					<Modal.Footer>
						<Button
							variant="light"
							className="modal-btn btn-sm dismiss-btn text-medium-inter text-sm"
							onClick={() => setTokenModal(false)}
						>
							Cancel
						</Button>
						<Button
							type="submit"
							className="modal-btn btn-sm btn-default text-medium-inter text-sm"
							disabled={actionLoading}
						>
							{actionLoading ? "Adding.." : "Add 2FA"}
						</Button>
					</Modal.Footer>
				</Form>
			</Modal>
		</>
	);
};

export default Mfa;
