const VerifiedIcon = () => {
	return (
		<svg width="15" height="16" viewBox="0 0 15 16" fill="none">
			<path
				d="M13.2682 7.84498L13.6556 6.64381C13.9453 5.74537 13.5417 4.76881 12.7018 4.33912L11.5788 3.76621L11.0059 2.64316C10.5762 1.80332 9.59961 1.39967 8.70117 1.68938L7.5 2.07675L6.29883 1.68938C5.40039 1.39967 4.42383 1.80332 3.99414 2.64316L3.42122 3.76621L2.29817 4.33912C1.45833 4.76881 1.05468 5.74537 1.3444 6.64381L1.73177 7.84498L1.3444 9.04616C1.05468 9.94459 1.45833 10.9212 2.29817 11.3508L3.42122 11.9238L3.99414 13.0468C4.42383 13.8867 5.40039 14.2903 6.29883 14.0006L7.5 13.6132L8.70117 14.0006C9.59961 14.2903 10.5762 13.8867 11.0059 13.0468L11.5788 11.9238L12.7018 11.3508C13.5417 10.9212 13.9453 9.94459 13.6556 9.04616L13.2682 7.84498ZM6.43229 10.1627L5 8.72714C4.75586 8.48626 4.75586 8.08912 5 7.84498C5.24414 7.60084 5.64127 7.60084 5.88216 7.84498L6.875 8.83782L9.74284 5.96998C9.98372 5.72584 10.3809 5.72584 10.625 5.96998C10.8691 6.21412 10.8691 6.61126 10.625 6.85214L7.31771 10.1627C7.07356 10.4068 6.67643 10.4068 6.43229 10.1627Z"
				fill="#27B3E8"
			/>
		</svg>
	);
};

export default VerifiedIcon;
