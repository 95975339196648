import Spinner from "react-bootstrap/Spinner";

const MessageLoader = () => {
	return (
		<div className="message-loader full flex-column mt-5">
			<Spinner className="text-blue" />
		</div>
	);
};

export default MessageLoader;
