import { useState, useEffect, useContext } from "react";
import { UserContext } from "../context/UserContext";
import { useSnackbar } from "react-simple-snackbar";
import Button from "react-bootstrap/Button";
import MfaLoader from "./MfaLoader";

const AssetMFA = ({ mfa, showCode, canEdit }) => {
	const [user] = useContext(UserContext);
	const [token, setToken] = useState(null);

	const [defaultSnackbar] = useSnackbar();

	const getToken = async () => {
		const requestOptions = {
			method: "GET",
		};

		const response = await fetch(
			`${process.env.REACT_APP_API_URI}/users/mfa/tokens/${mfa.id}`,
			requestOptions
		);
		const data = await response.json();

		if (!response.ok) {
			console.log("Something went wrong.");
		} else {
			setToken(data.current);
		}
	};

	const copyToken = async () => {
		navigator.clipboard.writeText(token);
		defaultSnackbar("Copied to clipboard.");
		const requestOptions = {
			method: "POST",
			headers: {
				"X-Auth-Token": user ? user.session_token : "",
			},
		};

		const response = await fetch(
			`${process.env.REACT_APP_API_URI}/users/mfa/tokens/${mfa.id}/copy`,
			requestOptions
		);
	};

	useEffect(() => {
		getToken();
	}, []);

	return (
		<>
			<div className="asset-live-code rounded-4 bg-white border full flex-row-left">
				<div className="mfa-title-container flex-row-left">
					<MfaLoader getToken={getToken} />
					<div className="mfa-code-row">
						<span className="mfa-code text-medium-inter">
							{token && `${token.slice(0, 3)} ${token.slice(3)}`}
						</span>
					</div>
				</div>
				<div className="asset-pw-actions flex-row-right">
					{canEdit && (
						<Button variant="light flex-column" onClick={() => showCode(true)}>
							<i className="ri-pencil-line opacity-7" />
						</Button>
					)}
					<Button variant="light flex-column" onClick={() => copyToken(token)}>
						<i className="ri-file-copy-line opacity-7" />
					</Button>
				</div>
				{/* <div className="flex-row-right">
					<div className="col-4 mfa-item-control flex-row-right">
						<Button className="flex-column" onClick={() => copyToken(token)}>
							<i className="ri-file-copy-line text-sm" />
						</Button>
					</div>
				</div> */}
			</div>
		</>
	);
};

export default AssetMFA;
