import { useState } from "react";
import { format } from "date-fns";
import { useSnackbar } from "react-simple-snackbar";
import Button from "react-bootstrap/Button";

const DeletedNumber = ({
	groupMsisdn,
	user,
	refresh,
	groupID,
	setNumberModal,
	setCountryCode,
	setMsisdnTab,
	setDeletedMsisdns,
}) => {
	const [loading, setLoading] = useState(false);
	const [defaultSnackbar] = useSnackbar();

	const [dangerSnackbar] = useSnackbar({
		style: {
			zIndex: 10,
			backgroundColor: "#d81e5b",
			color: "#fff",
		},
	});

	const restoreNumber = async () => {
		setLoading(true);
		const requestOptions = {
			method: "POST",
			headers: {
				"X-Auth-Token": user ? user.session_token : "",
			},
			body: JSON.stringify({
				group_id: groupID,
			}),
		};

		const response = await fetch(
			`${process.env.REACT_APP_API_URI}/users/v-msisdn/${groupMsisdn.v_msisdn.id}/restore`,
			requestOptions
		);
		const data = await response.json();

		if (!response.ok) {
			dangerSnackbar(data.msg);
		} else {
			refresh();
			defaultSnackbar(data.msg);
			setNumberModal(false);
			setCountryCode("");
			setMsisdnTab("new");
			setDeletedMsisdns([]);
		}
		setLoading(false);
	};

	const formatMsisdn = (msisdn) => {
		const cleaned = ("" + msisdn).replace(/\D/g, "");
		const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
		if (match === null) {
			return msisdn;
		}
		return match[1] + " " + match[2] + " " + match[3];
	};

	return (
		<div
			key={groupMsisdn.id}
			className="deleted-msisdn mb-30 rounded-4 bg-white mb-10 flex-row-left"
		>
			<div
				className="deleted-msisdn-country bg-cover"
				style={{
					background: `url(${groupMsisdn.v_msisdn.flag_uri})`,
				}}
			/>
			<div>
				<span className="text-medium-inter text-sm">
					{formatMsisdn(groupMsisdn.v_msisdn.msisdn)}
				</span>
				<span className="label-md block">
					Expires{" "}
					{format(new Date(groupMsisdn.v_msisdn.expires_at), "MMM dd, yyyy")}
				</span>
			</div>
			<div className="deleted-msisdn-control flex-row-right">
				<Button
					className="flex-column text-sm"
					onClick={restoreNumber}
					disabled={loading}
				>
					{loading ? "Restoring.." : "Restore"}
				</Button>
			</div>
		</div>
	);
};

export default DeletedNumber;
