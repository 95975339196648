import React, { createContext, useEffect, useState } from "react";

export const NotifContext = createContext();

export const NotifProvider = (props) => {
	const [notifCount, setNotifCount] = useState(0);

	return (
		<NotifContext.Provider value={[notifCount, setNotifCount]}>
			{props.children}
		</NotifContext.Provider>
	);
};
