import { React, useContext, useEffect } from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { UserProvider, UserContext } from "./context/UserContext";
import { ProfileProvider, ProfileContext } from "./context/ProfileContext";
import { NotifProvider, NotifContext } from "./context/NotifContext";
import { KeyProvider } from "./context/KeyContext";
import { SaltProvider } from "./context/SaltContext";
import SnackbarProvider from "react-simple-snackbar";

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import "remixicon/fonts/remixicon.css";
import "./index.css";

import ScrollToTop from "./components/ScrollToTop";
import Layout from "./pages/Layout";
import Dashboard from "./pages/Dashboard";
import Mfa from "./pages/Mfa";
import Archive from "./pages/Archive";
import Groups from "./pages/Groups";
import Group from "./pages/Group";
import Settings from "./pages/Settings";
import Search from "./pages/Search";
import Login from "./pages/Login";
import Signup from "./pages/Signup";
import Logout from "./pages/Logout";
import Verify from "./pages/Verify";
import Password from "./pages/Password";
import Invite from "./pages/Invite";
import Welcome from "./pages/Welcome";
import Payment from "./pages/Payment";
import PaymentVerification from "./pages/PaymentVerification";
import PageLoader from "./components/PageLoader";
import PlanPicker from "./pages/PlanPicker";
import NewDevice from "./pages/NewDevice";
import LoginVerification from "./pages/LoginVerification";
import Notifications from "./pages/Notifications";
import MessagePage from "./pages/MessagePage";
import AssetRequest from "./pages/AssetRequest";
import Unlocked from "./pages/Unlocked";
import PublicAsset from "./pages/PublicAsset";
import Onboarding from "./pages/Onboarding";

const root = createRoot(document.getElementById("root"));

export default function App() {
	const [user] = useContext(UserContext);
	const [profile, setProfile] = useContext(ProfileContext);
	const [, setNotifCount] = useContext(NotifContext);

	const getProfile = async () => {
		const requestOptions = {
			method: "GET",
			headers: {
				"X-Auth-Token": user ? user.session_token : "",
			},
		};

		const response = await fetch(
			`${process.env.REACT_APP_API_URI}/users/me`,
			requestOptions
		);
		const data = await response.json();

		if (!response.ok) {
			console.log("Something went wrong.");
		} else {
			setProfile(data.profile);
			setNotifCount(data.notif_count);
		}
	};

	useEffect(() => {
		if (user) {
			getProfile();
		}
	}, [, user]);

	return (
		<BrowserRouter>
			<ScrollToTop />
			<Routes>
				<Route
					path="/*"
					element={
						!user ? (
							<Navigate to="/login" />
						) : !profile ? (
							<div className="full-page bg-gray flex-column">
								<PageLoader />
							</div>
						) : !profile.user.email_verified ? (
							<Navigate to="welcome" />
						) : !profile.session_verified ? (
							<Navigate to="new-device" />
						) : (
							<Layout user={user} />
						)
					}
				>
					<Route index element={<Navigate to="/dashboard" />} />
					<Route path="dashboard" element={<Dashboard />} />
					<Route path="2fa" element={<Mfa />} />
					<Route path="archive" element={<Archive />} />
					<Route path="safes" element={<Groups />} />
					<Route path="safes/:id" element={<Group />} />
					<Route path="settings" element={<Settings />} />
					<Route path="search" element={<Search />} />
					<Route path="notifications" element={<Notifications />} />
					<Route path="messages/:id" element={<MessagePage />} />
					<Route path="unlocked" element={<Unlocked user={user} />} />
				</Route>
				<Route
					path="login"
					element={user ? <Navigate to="/dashboard" /> : <Login />}
				/>
				<Route path="signup" element={<Signup />} />
				<Route path="logout" element={<Logout />} />
				<Route
					path="account-setup"
					element={!user ? <Navigate to="/" /> : <Onboarding />}
				/>
				<Route path="assets/sharing/:id" element={<PublicAsset />} />
				<Route
					path="welcome"
					element={
						!user || profile?.user.email_verified ? (
							<Navigate to="/" />
						) : (
							<Welcome />
						)
					}
				/>
				<Route
					path="assets/requests/:id"
					element={!user ? <Navigate to="/" /> : <AssetRequest />}
				/>
				<Route path="verify" element={<Verify />} />
				<Route path="users/sessions/approve" element={<LoginVerification />} />
				<Route
					path="new-device"
					element={
						!user || profile?.session_verified ? (
							<Navigate to="/" />
						) : (
							<NewDevice />
						)
					}
				/>
				<Route
					path="plans"
					element={!user ? <Navigate to="/" /> : <PlanPicker />}
				/>
				<Route
					path="payment"
					element={
						!user || profile?.user.subscription?.status === "active" ? (
							<Navigate to="/" />
						) : (
							<Payment />
						)
					}
				/>
				<Route
					path="payment/verify"
					element={
						profile && profile.user.subscription?.status === "active" ? (
							<Navigate
								to={
									new URLSearchParams(window.location.search).get(
										"redirect_url"
									)
										? new URLSearchParams(window.location.search).get(
												"redirect_url"
										  )
										: "/account-setup"
								}
							/>
						) : (
							<PaymentVerification />
						)
					}
				/>
				<Route
					path="password/reset"
					element={!user ? <Navigate to="/" /> : <Password />}
				/>
				<Route path="invite/accept" element={<Invite />} />
			</Routes>
		</BrowserRouter>
	);
}

root.render(
	<UserProvider>
		<ProfileProvider>
			<NotifProvider>
				<KeyProvider>
					<SaltProvider>
						<SnackbarProvider>
							<App />
						</SnackbarProvider>
					</SaltProvider>
				</KeyProvider>
			</NotifProvider>
		</ProfileProvider>
	</UserProvider>
);
