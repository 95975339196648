import { useState, useEffect, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { UserContext } from "../context/UserContext";
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import Message from "../components/Message";
import PageLoader from "../components/PageLoader";

const MessagePage = () => {
	let { id } = useParams();
	const navigate = useNavigate();
	const [user] = useContext(UserContext);
	const [message, setMessage] = useState(null);
	const [loading, setLoading] = useState(true);

	const getData = async () => {
		setLoading(true);
		const requestOptions = {
			method: "GET",
			headers: {
				"X-Auth-Token": user ? user.session_token : "",
			},
		};

		const response = await fetch(
			`${process.env.REACT_APP_API_URI}/users/messages/${id}`,
			requestOptions
		);
		const data = await response.json();

		if (!response.ok) {
			console.log("Something went wrong.");
		} else {
			setMessage(data.message);
		}
		setLoading(false);
	};

	useEffect(() => {
		document.title = "Message | SMSafe";
		getData();
	}, [id]);

	return (
		<div className="content full-page">
			{loading ? (
				<PageLoader />
			) : (
				<Container>
					<div className="mb-10 mt-1">
						<a
							href="#"
							onClick={(e) => {
								e.preventDefault();
								navigate(-1);
							}}
						>
							<i className="ri-arrow-left-line text-xl" />
						</a>
					</div>
					<Message key={message.id} message={message} user={user} />
				</Container>
			)}
		</div>
	);
};

export default MessagePage;
