import React, { useContext, useEffect, useState, useMemo } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { UserContext } from "../context/UserContext";
import { useSnackbar } from "react-simple-snackbar";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Dropdown from "react-bootstrap/Dropdown";
import EmptyBee from "../components/EmptyBee";
import { components } from "react-select";
import PageLoader from "../components/PageLoader";
import VerifiedIcon from "../components/VerifiedIcon";
import defaultUser from "../assets/default_user.png";

const Groups = () => {
	const navigate = useNavigate();
	const [user] = useContext(UserContext);
	const [searchParams, setSearchParams] = useSearchParams({});
	const [userGroups, setUserGroups] = useState([]);
	const [name, setName] = useState("");
	const [meta, setMeta] = useState(null);
	const [groupModal, setGroupModal] = useState(false);
	const [loading, setLoading] = useState(false);
	const [actionLoading, setActionLoading] = useState(false);
	const [toEdit, setToEdit] = useState(null);
	const [toDelete, setToDelete] = useState(null);
	const [subscribeModal, setSubscribeModal] = useState(false);
	const [subscribeError, setSubscribeError] = useState("");
	const [search, setSearch] = useState(searchParams.get("q") || "");
	const [ownership, setOwnership] = useState(
		searchParams.get("owned_by_me") || null
	);

	const [defaultSnackbar] = useSnackbar();

	const [dangerSnackbar] = useSnackbar({
		style: {
			zIndex: 10,
			backgroundColor: "#d81e5b",
			color: "#fff",
		},
	});

	const searchFields = [
		{ key: "q", value: search },
		{ key: "owned_by_me", value: ownership },
	];

	const getData = async () => {
		setLoading(true);
		const requestOptions = {
			method: "GET",
			headers: {
				"X-Auth-Token": user ? user.session_token : "",
			},
		};

		const response = await fetch(
			`${process.env.REACT_APP_API_URI}/users/groups${window.location.search}`,
			requestOptions
		);
		const data = await response.json();

		if (!response.ok) {
			console.log("Something went wrong.");
		} else {
			setUserGroups(data.data);
			setMeta(data.meta);
		}
		setLoading(false);
	};

	const { Option } = components;
	const IconOption = (props) => (
		<Option {...props}>
			<div className="flex-row-left">
				<img
					src={props.data.img}
					alt={props.data.label}
					className="country-icon"
				/>
				<span className="text-sm">{props.data.label}</span>
			</div>
		</Option>
	);

	const addGroup = async (e) => {
		e.preventDefault();
		setActionLoading(true);
		const requestOptions = {
			method: "POST",
			headers: {
				"X-Auth-Token": user ? user.session_token : "",
			},
			body: JSON.stringify({
				name: name,
			}),
		};

		const response = await fetch(
			`${process.env.REACT_APP_API_URI}/users/groups`,
			requestOptions
		);
		const data = await response.json();

		if (!response.ok) {
			if (data.error === "subscription") {
				setName("");
				setGroupModal(false);
				setSubscribeError(data.msg);
				setSubscribeModal(true);
			} else {
				dangerSnackbar(data.msg);
			}
		} else {
			setName("");
			setGroupModal(false);
			defaultSnackbar(data.msg);
			navigate(`/safes/${data.group_id}`);
		}
		setActionLoading(false);
	};

	const renameGroup = async (e) => {
		e.preventDefault();
		setActionLoading(true);
		const requestOptions = {
			method: "POST",
			headers: {
				"X-Auth-Token": user ? user.session_token : "",
			},
			body: JSON.stringify({
				name: name,
			}),
		};

		const response = await fetch(
			`${process.env.REACT_APP_API_URI}/users/groups/${toEdit.id}`,
			requestOptions
		);
		const data = await response.json();

		if (!response.ok) {
			dangerSnackbar(data.msg);
		} else {
			getData();
			setName("");
			setToEdit(null);
			defaultSnackbar(data.msg);
		}
		setActionLoading(false);
	};

	const deleteGroup = async () => {
		setActionLoading(true);
		const requestOptions = {
			method: "DELETE",
			headers: {
				"X-Auth-Token": user ? user.session_token : "",
			},
		};

		const response = await fetch(
			`${process.env.REACT_APP_API_URI}/users/groups/${toDelete.id}`,
			requestOptions
		);
		const data = await response.json();

		if (!response.ok) {
			dangerSnackbar(data.msg);
		} else {
			defaultSnackbar(data.msg);
			getData();
		}
		setToDelete(null);
		setActionLoading(false);
	};

	const updateSearch = () => {
		var newSearch = { ...searchParams };

		searchFields.map((field) => {
			if (field.value !== null && field.value !== "") {
				newSearch[field.key] = field.value;
			} else {
				delete newSearch[field.key];
			}
		});
		setSearchParams(newSearch);
	};

	const handleEditGroup = (group) => {
		setToEdit(group);
		setName(group.name);
	};

	const closeEditModal = () => {
		setToEdit(null);
		setName("");
	};

	useEffect(() => {
		updateSearch();
	}, [ownership]);

	useEffect(() => {
		const timer = setTimeout(() => {
			updateSearch();
		}, 500);
		return () => clearTimeout(timer);
	}, [search]);

	useEffect(() => {
		document.title = "Safes | SMSafe";
		getData();
	}, [searchParams]);

	return (
		<>
			<div className="group-content full-page">
				<div className="safes-banner bg-default pt-4">
					<Container>
						<div className="mb-20">
							<h1 className="page-content-title title-md text-medium-inter mb-0 text-white flex-row-left">
								Safes
							</h1>
						</div>
						<div className="group-owner-picker full flex-row-left">
							<div className="flex-row-left">
								<a
									href="#"
									className={`group-owner-item flex-column text-white text-sm ${
										ownership === null && "active"
									}`}
									onClick={(e) => {
										e.preventDefault();
										setOwnership(null);
									}}
								>
									All Safes
								</a>
								<a
									href="#"
									className={`group-owner-item flex-column text-white text-sm ${
										ownership === true && "active"
									}`}
									onClick={(e) => {
										e.preventDefault();
										setOwnership(true);
									}}
								>
									My Safes
								</a>
								<a
									href="#"
									className={`group-owner-item flex-column text-white text-sm ${
										ownership === false && "active"
									}`}
									onClick={(e) => {
										e.preventDefault();
										setOwnership(false);
									}}
								>
									Shared with Me
								</a>
							</div>
						</div>
					</Container>
				</div>
				<Container>
					{loading ? (
						<PageLoader className="groups-page-loader" />
					) : (
						<Row>
							<Col className="page-content pt-4">
								<div className={meta?.total !== 0 ? "mb-20" : "mb-50"}>
									<div className="col-lg-6 col-12">
										<Form.Control
											className="search-text rounded-4 text-md"
											placeholder={
												ownership === null
													? "Search all safes"
													: ownership === true
													? "Search my safes"
													: "Search shared safes"
											}
											value={search}
											onChange={(e) => setSearch(e.target.value)}
										/>
									</div>
								</div>
								{meta?.total !== 0 && (
									<div className="mb-10 full">
										<span className="opacity-7 text-sm">
											{meta?.total} {meta?.total === 1 ? "result" : "results"}
										</span>
									</div>
								)}
								{userGroups.length === 0 ? (
									<div className="flex-column">
										<EmptyBee />
										<span className="empty-label text-lg">
											No safes to display
										</span>
									</div>
								) : (
									<>
										<div className="page-content-body">
											<Row className="gy-2">
												{userGroups.map((group) => (
													<Col
														key={group.id}
														className="col-lg-4 col-md-6 col-12"
													>
														<div className="mb-10 bg-white rounded-4 full flex-row-left">
															<Link
																to={`/safes/${group.id}`}
																className="flex-row-left full p-20"
															>
																<div className="group-icon bg-gray flex-column">
																	<span className="text-xl">
																		{group.name[0]}
																	</span>
																</div>
																<div className="group-item-details">
																	<div className="flex-row-left full">
																		<span className="text-sm text-medium-inter ellipsis">
																			{toEdit?.id === group.id
																				? toEdit.name
																				: group.name}
																		</span>
																		{group.is_owner && (
																			<i className="group-owner-icon label-md ri-vip-crown-fill" />
																		)}
																	</div>
																	<span className="label-md opacity-3 block">
																		{group.total_members}{" "}
																		{group.total_members === 1
																			? "member"
																			: "members"}
																	</span>
																</div>
															</Link>
															{group.is_owner && (
																<div className="group-item-control flex-row-right full-height">
																	{group.expires_at ? (
																		<div className="group-item-lock">
																			<i className="ri-lock-2-fill text-blue text-lg me-1" />
																			<span className="tooltip">
																				This safe is locked
																			</span>
																		</div>
																	) : (
																		<Dropdown className="dropdown-default">
																			<Dropdown.Toggle className="flex-column no-caret">
																				<i className="ri-settings-line text-sm" />
																			</Dropdown.Toggle>
																			<Dropdown.Menu>
																				<Dropdown.Item
																					href="#"
																					onClick={(e) => {
																						e.preventDefault();
																						handleEditGroup(group);
																					}}
																					className="text-sm"
																				>
																					Rename
																				</Dropdown.Item>
																				<Dropdown.Item
																					href="#"
																					onClick={(e) => {
																						e.preventDefault();
																						setToDelete(group);
																					}}
																					className="text-sm text-danger"
																				>
																					Delete
																				</Dropdown.Item>
																			</Dropdown.Menu>
																		</Dropdown>
																	)}
																</div>
															)}
														</div>
													</Col>
												))}
											</Row>
										</div>
									</>
								)}
							</Col>
						</Row>
					)}
				</Container>
			</div>
			<Modal
				show={groupModal}
				className="post-modal"
				onHide={() => setGroupModal(false)}
				centered
			>
				<Modal.Header className="text-medium-inter text-lg">
					Add New Safe
				</Modal.Header>
				<Form onSubmit={addGroup}>
					<Modal.Body>
						<Row className="form-block-sm">
							<Col>
								<Form.Label>Safe Name</Form.Label>
								<Form.Control
									className="form-input"
									placeholder="Enter group name"
									value={name}
									onChange={(e) => setName(e.target.value)}
								/>
							</Col>
						</Row>
					</Modal.Body>
					<Modal.Footer>
						<Button
							variant="light"
							className="modal-btn btn-md dismiss-btn text-medium-inter text-sm"
							onClick={() => setGroupModal(false)}
						>
							Cancel
						</Button>
						<Button
							type="submit"
							className="modal-btn btn-md btn-default text-medium-inter text-sm"
							disabled={actionLoading}
						>
							{actionLoading ? "Creating.." : "Create Group"}
						</Button>
					</Modal.Footer>
				</Form>
			</Modal>
			<Modal show={toEdit} onHide={closeEditModal} centered>
				<Modal.Header className="text-medium-inter text-lg">
					Rename Safe
				</Modal.Header>
				<Form onSubmit={renameGroup}>
					<Modal.Body>
						<Row className="form-block-sm">
							<Col>
								<Form.Label>Safe Name</Form.Label>
								<Form.Control
									className="form-input"
									placeholder="Enter safe name"
									value={name}
									onChange={(e) => setName(e.target.value)}
								/>
							</Col>
						</Row>
					</Modal.Body>
					<Modal.Footer>
						<Button
							variant="light"
							className="modal-btn btn-md dismiss-btn text-medium-inter text-sm"
							onClick={closeEditModal}
						>
							Cancel
						</Button>
						<Button
							type="submit"
							className="modal-btn btn-md btn-default text-medium-inter text-sm"
							disabled={actionLoading || toEdit?.name === name}
						>
							{actionLoading ? "Saving.." : "Save Changes"}
						</Button>
					</Modal.Footer>
				</Form>
			</Modal>
			<Modal show={toDelete} onHide={() => setToDelete(null)} centered>
				<Modal.Body>
					<h2 className="text-lg text-medium-inter">Delete Safe</h2>
					<p className="text-sm opacity-5 mb-0">
						All mobile numbers and 2FAs in this safe will be lost and cannot be
						recovered, would you like to proceed?
					</p>
				</Modal.Body>
				<Modal.Footer>
					<Button
						variant="light"
						className="modal-btn btn-sm dismiss-btn text-medium-inter text-sm"
						onClick={() => setToDelete(null)}
					>
						Cancel
					</Button>
					<Button
						className="modal-btn btn-sm btn-danger text-medium-inter text-sm"
						disabled={actionLoading}
						onClick={deleteGroup}
					>
						{actionLoading ? "Deleting.." : "Yes, Delete"}
					</Button>
				</Modal.Footer>
			</Modal>
			<Modal
				show={subscribeModal}
				onHide={() => setSubscribeModal(false)}
				centered
			>
				<Modal.Header className="text-medium-inter text-lg flex-row-left">
					<i class="ri-error-warning-line text-danger text-xl me-2" />
					Upgrade Account
				</Modal.Header>
				<Modal.Body>
					<p className="text-sm opacity-5 mb-0">{subscribeError}</p>
				</Modal.Body>
				<Modal.Footer>
					<Button
						variant="light"
						className="modal-btn btn-md dismiss-btn text-medium-inter text-sm"
						onClick={() => setSubscribeModal(false)}
					>
						Cancel
					</Button>
					<Button
						className="modal-btn btn-md btn-default text-medium-inter text-sm"
						onClick={() => navigate(`/plans?redirect_url=/safes`)}
					>
						Upgrade
					</Button>
				</Modal.Footer>
			</Modal>
		</>
	);
};

export default Groups;
