import { useEffect, useState, useContext } from "react";
import { ProfileContext } from "../context/ProfileContext";
import { useSearchParams, useNavigate } from "react-router-dom";
import LogoDark from "../components/LogoDark";
import PageLoader from "../components/PageLoader";
import Button from "react-bootstrap/Button";

const Verify = () => {
	document.title = "Email Verification | SMSafe";
	const navigate = useNavigate();
	const [profile, setProfile] = useContext(ProfileContext);
	const [searchParams] = useSearchParams();
	const token = searchParams.get("token");
	const [error, setError] = useState(false);
	const [loading, setLoading] = useState(true);

	const verifyEmail = async () => {
		setLoading(true);
		const requestOptions = {
			method: "POST",
			body: JSON.stringify({
				token: token,
			}),
		};

		const response = await fetch(
			`${process.env.REACT_APP_API_URI}/users/email/verify`,
			requestOptions
		);
		const data = await response.json();

		if (!response.ok) {
			setError(true);
		} else {
			setProfile(data.profile);
		}
		setLoading(false);
	};

	useEffect(() => {
		if (token) {
			verifyEmail();
		}
	}, [token]);

	return (
		<div className="full-page bg-gray flex-column">
			{loading ? (
				<PageLoader className="full-page" />
			) : (
				<div className="col-4 p-30 bg-white rounded flex-column">
					<LogoDark className="verify-logo" />
					{error ? (
						<div className="align-center">
							<div className="flex-row-center mb-15">
								<i className="ri-error-warning-line text-xl text-danger me-1" />
								<h1 className="mb-0 text-lg text-medium-inter">
									Verification Failed
								</h1>
							</div>
							<p className="opacity-5 align-center">
								Something went wrong, please try again.
							</p>
						</div>
					) : (
						<div className="align-center">
							<div className="flex-row-center mb-15">
								<i className="ri-shield-check-line text-xl text-success me-1" />
								<h1 className="mb-0 text-lg text-medium-inter">
									Email Verified
								</h1>
							</div>
							<p className="opacity-5 align-center text-sm mb-30">
								Thank you, your email has been successfully verified.
							</p>
							<a href="/account-setup">
								<Button className="btn-md rounded-4 btn-default full text-medium-inter">
									Get Started
								</Button>
							</a>
						</div>
					)}
				</div>
			)}
		</div>
	);
};

export default Verify;
