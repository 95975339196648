import { useEffect, useState } from "react";
import { useSnackbar } from "react-simple-snackbar";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";

const AssetMember = ({ asset, setSharedTo, member, user }) => {
	const [access, setAccess] = useState(null);
	const [actionLoading, setActionLoading] = useState(false);
	const [shareModal, setShareModal] = useState(false);
	const [allowEdit, setAllowEdit] = useState(false);
	const [expiration, setExpiration] = useState("");

	const [defaultSnackbar] = useSnackbar();

	const [dangerSnackbar] = useSnackbar({
		style: {
			zIndex: 10,
			backgroundColor: "#d81e5b",
			color: "#fff",
		},
	});

	const addAccess = async (e) => {
		e.preventDefault();
		setActionLoading(true);
		const requestOptions = {
			method: "POST",
			headers: {
				"X-Auth-Token": user ? user.session_token : "",
			},
			body: JSON.stringify({
				user_id: member.user_id,
				allow_edit: allowEdit,
				expiration: expiration,
			}),
		};

		const response = await fetch(
			`${process.env.REACT_APP_API_URI}/users/assets/${asset.id}/access`,
			requestOptions
		);
		const data = await response.json();

		if (!response.ok) {
			dangerSnackbar("Something went wrong.");
		} else {
			defaultSnackbar(data.msg);
			setAccess(data.access);
		}
		closeShareModal();
		setActionLoading(false);
	};

	const removeAccess = async () => {
		setActionLoading(true);
		const requestOptions = {
			method: "DELETE",
			headers: {
				"X-Auth-Token": user ? user.session_token : "",
			},
			body: JSON.stringify({
				user_id: member.user_id,
			}),
		};

		const response = await fetch(
			`${process.env.REACT_APP_API_URI}/users/assets/${asset.id}/access`,
			requestOptions
		);
		const data = await response.json();

		if (!response.ok) {
			dangerSnackbar("Something went wrong.");
		} else {
			defaultSnackbar(data.msg);
			setAccess(null);
			setSharedTo(data.asset.share_to);
		}
		setActionLoading(false);
	};

	const closeShareModal = () => {
		setShareModal(false);
		setAllowEdit(false);
		setExpiration("");
	};

	return (
		member.user.username !== user.username && (
			<>
				<div className="asset-member">
					<Row key={member.id} className="flex-row-left">
						<Col className="col-lg-8 col-12 flex-row-left">
							<div
								className="asset-member-icon flex-column"
								style={{
									background: member?.user.photo
										? `url(${member.user.photo})`
										: "#3c57cc",
								}}
							>
								{!member?.user.photo && (
									<span className="text-medium-inter text-white text-sm upper">
										{member.user.username
											? member.user.username[0]
											: member.user.email[0]}
									</span>
								)}
							</div>
							{member?.user.username ? (
								<div className="member-details-container">
									<span className="text-sm ellipsis">
										{member?.user.username}
									</span>
									<span className="label-md opacity-5 block ellipsis">
										{member?.user.email}
									</span>
								</div>
							) : (
								<div className="member-details-container">
									<span className="text-sm ellipsis">{member?.user.email}</span>
								</div>
							)}
						</Col>
						<Col className="col-lg-4 col-12 member-action flex-row-right">
							<Button
								variant="light"
								className="asset-member-btn btn-gray label-md text-medium-inter flex-column"
								disabled={actionLoading}
								onClick={removeAccess}
							>
								{actionLoading ? "Loading.." : "Unshare"}
							</Button>
						</Col>
					</Row>
				</div>
				<Modal
					show={shareModal}
					className="post-modal"
					onHide={closeShareModal}
					centered
				>
					<Modal.Header className="text-medium-inter text-lg">
						Share Asset
					</Modal.Header>
					<Modal.Body>
						<Row className="mb-20">
							<Col>
								<span className="text-sm text-medium-inter">
									Allow Editing:
								</span>
							</Col>
							<Col>
								<input
									type="checkbox"
									defaultChecked={allowEdit}
									onChange={(e) => setAllowEdit(e.target.checked)}
									className="cbx asset-cbx hidden"
									id="allowEditing"
								/>
								<label htmlFor="allowEditing" className="lbl asset-lbl"></label>
							</Col>
						</Row>
						<Row className="mb-10">
							<Col>
								<span className="text-sm text-medium-inter">Expiration:</span>
							</Col>
							<Col>
								<Form.Select
									size="sm"
									value={expiration}
									onChange={(e) => setExpiration(e.target.value)}
								>
									<option value="">None</option>
									<option value="15">15 Minutes</option>
									<option value="30">30 Minutes</option>
									<option value="60">1 Hour</option>
									<option value="24">1 Day</option>
								</Form.Select>
							</Col>
						</Row>
					</Modal.Body>
					<Modal.Footer>
						<Button
							variant="light"
							className="modal-btn btn-md dismiss-btn text-medium-inter text-sm"
							onClick={closeShareModal}
						>
							Cancel
						</Button>
						<Button
							className="modal-btn btn-md btn-default text-medium-inter text-sm"
							disabled={actionLoading}
							onClick={addAccess}
						>
							{actionLoading ? "Sharing.." : "Share"}
						</Button>
					</Modal.Footer>
				</Modal>
			</>
		)
	);
};

export default AssetMember;
