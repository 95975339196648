import { useContext, useState } from "react";
import { Link } from "react-router-dom";
import { UserContext } from "../context/UserContext";
import { ProfileContext } from "../context/ProfileContext";
import { useLocation, useNavigate } from "react-router-dom";
import { useSnackbar } from "react-simple-snackbar";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Logo from "../components/LogoDark";

const PlanPicker = () => {
	document.title = "Plans | SMSafe";
	const navigate = useNavigate();
	const location = useLocation();
	const [user] = useContext(UserContext);
	const [profile, setProfile] = useContext(ProfileContext);
	const [subscriptionId, setSubscriptionId] = useState(null);
	const [newTier, setNewTier] = useState("");
	const [userGroups, setUserGroups] = useState([]);
	const [toKeep, setToKeep] = useState([]);
	const [safesRequired, setSafesRequired] = useState(false);
	const [loading, setLoading] = useState(false);

	const [defaultSnackbar] = useSnackbar();

	const [dangerSnackbar] = useSnackbar({
		style: {
			zIndex: 10,
			backgroundColor: "#d81e5b",
			color: "#fff",
		},
	});

	const tierList = {
		Free: {
			label: "Free",
			maxSafes: 1,
		},
		Basic: {
			label: "Basic",
			maxSafes: 3,
		},
		Premium: {
			label: "Premium",
			maxSafes: 0,
		},
	};

	const createSubscription = async (tier) => {
		setLoading(true);
		const requestOptions = {
			method: "POST",
			headers: {
				"X-Auth-Token": user ? user.session_token : "",
			},
			body: JSON.stringify({
				tier: tier,
			}),
		};

		const response = await fetch(
			`${process.env.REACT_APP_API_URI}/users/me/subscriptions`,
			requestOptions
		);
		const data = await response.json();

		if (!response.ok) {
			console.log("Something went wrong.");
		} else {
			if (data.created) {
				navigate(
					`/payment?subscription=${data.subscription_id}&tier=${data.tier}`,
					{
						state: {
							clientSecret: data.client_secret,
						},
					}
				);
			} else {
				setUserGroups(data.user_groups);
				setSubscriptionId(data.subscription_id);
				setNewTier(tier);

				if (
					data.user_groups.length > tierList[tier].maxSafes &&
					tierList[tier].maxSafes !== 0
				) {
					setSafesRequired(true);
				}
			}
		}
		setLoading(false);
	};

	const switchPlans = async () => {
		setLoading(true);
		const requestOptions = {
			method: "POST",
			headers: {
				"X-Auth-Token": user ? user.session_token : "",
			},
			body: JSON.stringify({
				tier: newTier,
				to_keep: toKeep,
			}),
		};

		const response = await fetch(
			`${process.env.REACT_APP_API_URI}/users/me/subscriptions/switch/${subscriptionId}`,
			requestOptions
		);
		const data = await response.json();

		if (!response.ok) {
			dangerSnackbar("Something went wrong.");
		} else {
			setProfile(data.profile);
			setSubscriptionId(null);
			setNewTier("");
			defaultSnackbar(data.msg);
			const redirect_url = new URLSearchParams(window.location.search).get(
				"redirect_url"
			);
			if (redirect_url) {
				navigate(redirect_url);
			} else {
				navigate("/unlocked");
			}
		}
		setLoading(false);
	};

	const handleGroupPicker = (e, groupId) => {
		e.preventDefault();
		if (toKeep.includes(groupId)) {
			setToKeep(toKeep.filter((item) => item !== groupId));
		} else {
			setToKeep([...toKeep, groupId]);
		}
	};

	const handleSwitchClose = () => {
		setSubscriptionId(null);
		setToKeep([]);
		setNewTier("");
		setSafesRequired(false);
	};

	return (
		<div className="full-page pb-5">
			<div className="login-header bg-gray full flex-row-left">
				<Container>
					<Row className="flex-row-left">
						<Col>
							<Link to="/">
								<Logo variant="dark" className="nav-logo" />
							</Link>
						</Col>
					</Row>
				</Container>
			</div>
			<div className="pricing-banner page-banner mb-30">
				<Container>
					<div className="flex-column mb-50">
						<h1 className="title-lg text-bold-lexend align-center mb-20">
							Choose the plan that's right for you
						</h1>
						<p className="align-center opacity-7">
							Pick the plan that meets your organization's needs, or{" "}
							<a href="/contact" className="text-bold-inter">
								contact sales
							</a>{" "}
							for assistance.
						</p>
					</div>
					<Row>
						<Col className="col-lg-3 col-md-6 col-12 mb-30">
							<div className="pricing-tier rounded full bg-white p-20">
								<div className="mb-20">
									<h2 className="text-xl text-medium-lexend mb-0">Free</h2>
									<span className="text-medium-inter">$0/month</span>
								</div>
								<div className="flex-row-left mb-10">
									<i className="ri-check-line text-blue text-lg me-2" />
									<span className="text-sm opacity-7">1 safe</span>
								</div>
								<div className="flex-row-left mb-10">
									<i className="ri-check-line text-blue text-lg me-2" />
									<span className="text-sm opacity-7">
										Unlimited 2FA storage
									</span>
								</div>
								<div className="flex-row-left mb-10">
									<i className="ri-check-line text-blue text-lg me-2" />
									<span className="text-sm opacity-7">1 member per safe</span>
								</div>
								<div className="flex-row-left mb-10">
									<i className="ri-check-line text-blue text-lg me-2" />
									<span className="text-sm opacity-7">Basic email support</span>
								</div>
								<div className="tier-btn-container full p-20 flex-column">
									{profile?.user.tier === "Free" ? (
										<span className="text-sm opacity-7">
											This is your current plan
										</span>
									) : (
										<Button
											variant="outline-secondary"
											className="btn-md text-sm full rounded-4 upper text-medium-inter"
											onClick={() => createSubscription("Free")}
											disabled={loading}
										>
											Get this plan
										</Button>
									)}
								</div>
							</div>
						</Col>
						<Col className="col-lg-3 col-md-6 col-12 mb-30">
							<div className="pricing-tier rounded full bg-white p-20">
								<div className="mb-20">
									<h2 className="text-xl text-medium-lexend mb-0">Basic</h2>
									<span className="text-medium-inter">$6/month</span>
								</div>
								<div className="flex-row-left mb-10">
									<i className="ri-check-line text-blue text-lg me-2" />
									<span className="text-sm opacity-7">Max. 3 safes</span>
								</div>
								<div className="flex-row-left mb-10">
									<i className="ri-check-line text-blue text-lg me-2" />
									<span className="text-sm opacity-7">
										Unlimited 2FA storage
									</span>
								</div>
								<div className="flex-row-left mb-10">
									<i className="ri-check-line text-blue text-lg me-2" />
									<span className="text-sm opacity-7">
										Max. 4 members per safe
									</span>
								</div>
								<div className="flex-row-left mb-10">
									<i className="ri-check-line text-blue text-lg me-2" />
									<span className="text-sm opacity-7">
										1 Free virtual phone number
									</span>
								</div>
								<div className="flex-row-left mb-10">
									<i className="ri-check-line text-blue text-lg me-2" />
									<span className="text-sm opacity-7">
										Max. 2 virtual phone numbers*
									</span>
								</div>
								<div className="flex-row-left mb-10">
									<i className="ri-check-line text-blue text-lg me-2" />
									<span className="text-sm opacity-7">SMS forwarding</span>
								</div>
								<div className="flex-row-left mb-10">
									<i className="ri-check-line text-blue text-lg me-2" />
									<span className="text-sm opacity-7">Email forwarding</span>
								</div>
								<div className="flex-row-left mb-10">
									<i className="ri-check-line text-blue text-lg me-2" />
									<span className="text-sm opacity-7">Basic support</span>
								</div>
								<div className="tier-btn-container full p-20 flex-column">
									{profile?.user.tier === "Basic" ? (
										<span className="text-sm opacity-7">
											This is your current plan
										</span>
									) : (
										<Button
											className="btn-md btn-default full rounded-4 text-sm upper text-medium-inter"
											onClick={() => createSubscription("Basic")}
											disabled={loading}
										>
											Get this plan
										</Button>
									)}
								</div>
							</div>
						</Col>
						<Col className="col-lg-3 col-md-6 col-12 mb-30">
							<div className="pricing-tier rounded full bg-white p-20">
								<div className="mb-20">
									<h2 className="text-xl text-medium-lexend mb-0">Premium</h2>
									<span className="text-medium-inter">$18/month</span>
								</div>
								<div className="flex-row-left mb-10">
									<i className="ri-check-line text-blue text-lg me-2" />
									<span className="text-sm opacity-7">Unlimited safes</span>
								</div>
								<div className="flex-row-left mb-10">
									<i className="ri-check-line text-blue text-lg me-2" />
									<span className="text-sm opacity-7">
										Unlimited 2FA storage
									</span>
								</div>
								<div className="flex-row-left mb-10">
									<i className="ri-check-line text-blue text-lg me-2" />
									<span className="text-sm opacity-7">
										10 Free members per safe*
									</span>
								</div>
								<div className="flex-row-left mb-10">
									<i className="ri-check-line text-blue text-lg me-2" />
									<span className="text-sm opacity-7">
										1 Free virtual phone number
									</span>
								</div>
								<div className="flex-row-left mb-10">
									<i className="ri-check-line text-blue text-lg me-2" />
									<span className="text-sm opacity-7">
										Max. 10 virtual phone numbers*
									</span>
								</div>
								<div className="flex-row-left mb-10">
									<i className="ri-check-line text-blue text-lg me-2" />
									<span className="text-sm opacity-7">SMS forwarding</span>
								</div>
								<div className="flex-row-left mb-10">
									<i className="ri-check-line text-blue text-lg me-2" />
									<span className="text-sm opacity-7">Email forwarding</span>
								</div>
								<div className="flex-row-left mb-10">
									<i className="ri-check-line text-blue text-lg me-2" />
									<span className="text-sm opacity-7">Webhook forwarding</span>
								</div>
								<div className="flex-row-left mb-10">
									<i className="ri-check-line text-blue text-lg me-2" />
									<span className="text-sm opacity-7">Priority support</span>
								</div>
								<div className="tier-btn-container full p-20 flex-column">
									{profile?.user.tier === "Premium" ? (
										<span className="text-sm opacity-7">
											This is your current plan
										</span>
									) : (
										<Button
											className="btn-md btn-default full rounded-4 text-sm upper text-medium-inter"
											onClick={() => createSubscription("Premium")}
											disabled={loading}
										>
											Get this plan
										</Button>
									)}
								</div>
							</div>
						</Col>
						<Col className="col-lg-3 col-md-6 col-12 mb-30">
							<div className="pricing-tier rounded full bg-default text-white p-20">
								<div className="mb-20">
									<h2 className="text-xl text-medium-lexend mb-0">
										Enterprise
									</h2>
									<span className="text-medium-inter">Contact Sales</span>
								</div>
								<div className="flex-row-left mb-10">
									<i className="ri-check-line text-white text-lg me-2" />
									<span className="text-sm opacity-7">Tailored solutions</span>
								</div>
								<div className="flex-row-left mb-10">
									<i className="ri-check-line text-white text-lg me-2" />
									<span className="text-sm opacity-7">
										Dedicated account manager
									</span>
								</div>
								<div className="flex-row-left mb-10">
									<i className="ri-check-line text-white text-lg me-2" />
									<span className="text-sm opacity-7">
										Unlimited virtual phone numbers
									</span>
								</div>
								<div className="flex-row-left mb-10">
									<i className="ri-check-line text-white text-lg me-2" />
									<span className="text-sm opacity-7">
										End-to-end encryption
									</span>
								</div>
								<div className="flex-row-left mb-10">
									<i className="ri-check-line text-white text-lg me-2" />
									<span className="text-sm opacity-7">
										Additional access controls
									</span>
								</div>
								<div className="flex-row-left mb-10">
									<i className="ri-check-line text-white text-lg me-2" />
									<span className="text-sm opacity-7">
										Service Level Agreement
									</span>
								</div>
								<div className="tier-btn-container full p-20 flex-column">
									{profile?.user.tier === "Enterprise" ? (
										<span className="text-sm opacity-7">
											This is your current plan
										</span>
									) : (
										<a href="/contact" className="full">
											<Button className="btn-md btn-default full rounded-4 text-sm upper text-medium-inter">
												Contact Sales
											</Button>
										</a>
									)}
								</div>
							</div>
						</Col>
					</Row>
				</Container>
			</div>
			<div className="flex-column">
				<Link to="/">
					<Button
						variant="outline-secondary"
						className="btn-lg text-sm rounded-4"
					>
						Back to Dashboard
					</Button>
				</Link>
			</div>
			<Modal show={subscriptionId} onHide={() => handleSwitchClose()} centered>
				<Modal.Header className="text-medium-inter text-lg">
					Switch Plans
				</Modal.Header>
				<Modal.Body className="switch-modal-body">
					{newTier &&
					userGroups?.length > tierList[newTier].maxSafes &&
					tierList[newTier].maxSafes !== 0 ? (
						<>
							{tierList[newTier].maxSafes === 1 ? (
								<p className="text-sm opacity-7 mb-15">
									The {tierList[newTier].label} plan only supports 1 safe,
									please select the one you want to keep.
								</p>
							) : (
								<p className="text-sm opacity-7 mb-15">
									The {tierList[newTier].label} plan only supports up to{" "}
									{tierList[newTier].maxSafes} safes, please select the ones you
									want to keep.
								</p>
							)}
							<span className="text-medium-inter">
								{toKeep.length}/{tierList[newTier].maxSafes}
							</span>
							<p className="label-md opacity-5 mb-15">
								The ones you don't select will be locked.
							</p>
							{userGroups.map((userGroup) => (
								<a
									key={userGroup.group.id}
									href="#"
									className={`plan-group-picker flex-row-left p-10 ${
										toKeep.includes(userGroup.group.id)
											? "active"
											: toKeep.length === tierList[newTier].maxSafes &&
											  "disabled"
									}`}
									onClick={(e) => handleGroupPicker(e, userGroup.group.id)}
								>
									<div className="plan-group-check flex-column rounded-4 border">
										<i className="ri-check-line text-white" />
									</div>
									<span className="text-medium-inter text-sm">
										{userGroup.group.name}
									</span>
								</a>
							))}
						</>
					) : (
						<p className="text-sm opacity-5 mb-0">
							Are you sure you want to switch to this plan?
						</p>
					)}
				</Modal.Body>
				<Modal.Footer>
					<Button
						variant="light"
						className="modal-btn btn-md dismiss-btn text-medium-inter text-sm"
						onClick={() => handleSwitchClose()}
					>
						Cancel
					</Button>
					<Button
						className="modal-btn btn-md btn-default text-medium-inter text-sm"
						onClick={switchPlans}
						disabled={
							loading ||
							(safesRequired && toKeep.length !== tierList[newTier].maxSafes)
						}
					>
						{loading ? "Switching.." : "Switch"}
					</Button>
				</Modal.Footer>
			</Modal>
		</div>
	);
};

export default PlanPicker;
